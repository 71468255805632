import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CreateRedemtionCard from '../../Components/CreateRedemption/CreateRedemtionCard';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';

const CreateRedemption = () => {
  const [formData, setFormData] = useState(0);
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    axiosInstance
      .post(
        `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/create_redemptions`,
        {
          max_redemptions: formData,
        }
      )
      .then((res) => {
        if (res?.data?.status === 'success') {
          toast.success('Discount redemption limit added successfully');
          setFormData(0);
        } else {
          toast.error(res?.data?.message);
        }
      });
  };

  const formClearHandler = () => {
    setFormData(0);
  };

  return (
    <Box sx={commonStyles.mainContainer}>
      <form onSubmit={submitHandler}>
        <CustomFormHeader
          title="Settings - Discount Redemption Limit"
          clearBtnHandler={formClearHandler}
          showSubmit
        />
        <CreateRedemtionCard formData={formData} setFormData={setFormData} />
      </form>
    </Box>
  );
};

export default CreateRedemption;
