import { useState, useEffect } from 'react';
import { Box, FormControl } from '@mui/material';
import axiosInstance from '../../API/axiosInstance';
import CustomInput from '../Widgets/CustomInput/CustomInput';
import CustomLabel from '../Widgets/CustomLabel/CustomLabel';
import CustomSelect from '../Widgets/CustomSelect/CustomSelect';
import { styles } from './CouponCardStyles';

const CouponCard = ({
  formData,
  setFormData,
  type,
  editFormData,
  setEditFormData,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [maxRedemptionList, setMaxRedemptionList] = useState([]);
  useEffect(() => {
    setLoading(true);
    if (type === 'edit') {
      axiosInstance
        .get(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/get_coupon_tier`
        )
        .then((response) => {
          if (response.status === 200) {
            const dropDownArray = [];
            response?.data?.result.map((res) =>
              dropDownArray.push({
                id: res?.tier_name,
                label: `${res?.min_range} - ${res?.max_range}`,
              })
            );
            setSelectData(dropDownArray);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    }
    axiosInstance
      .get(
        `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/get_redemptions`
      )
      .then((response) => {
        if (response.status === 200) {
          const dropDownArray = [];
          response?.data?.response.map((res) =>
            dropDownArray.push({
              id: res,
              label: res,
            })
          );
          setMaxRedemptionList(dropDownArray);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  const handleDiscountPercentage = (name, value) => {
    if (type === 'edit') {
      setEditFormData({ ...editFormData, discount_percentage: value });
    } else {
      setFormData({ ...formData, discount_percentage: value });
    }
  };
  const handleMaxRedemption = (name, value) => {
    if (type === 'edit') {
      setEditFormData({ ...editFormData, max_redemptions: value?.label });
    } else {
      setFormData({ ...formData, max_redemptions: value?.label });
    }
  };
  const handleDiscountName = (name, value) => {
    if (type === 'edit') {
      setEditFormData({ ...editFormData, name: value });
    } else {
      setFormData({ ...formData, name: value });
    }
  };
  const couponTierHandler = (name, value) => {
    setEditFormData({ ...editFormData, coupon_tier: value });
  };
  return (
    <Box sx={styles.formCardContainer}>
      <FormControl sx={styles.inputBoxContainer}>
        <Box sx={styles.labelWrapper}>
          <CustomLabel labelText="Discount" isRequired />
        </Box>
        <Box sx={styles.inputWrapper}>
          <FormControl sx={styles.inputBoxContainer}>
            <CustomInput
              id="discountName"
              name="discountName"
              type="text"
              value={type === 'add' ? formData?.name : editFormData?.name}
              setValue={handleDiscountName}
              placeholder="Discount name"
              fullWidth
              maxLength={40}
              inputInfo="Enter discount name."
            />
          </FormControl>
          <FormControl sx={styles.inputBoxContainer}>
            <CustomInput
              isDisabled={type === 'edit' ? true : false}
              id="editCloudCreditBalance"
              name="editCloudCreditBalance"
              type="number"
              value={
                type === 'add'
                  ? formData?.discount_percentage
                  : editFormData?.discount_percentage
              }
              setValue={handleDiscountPercentage}
              placeholder="Discount percentage"
              fullWidth
              maxLength={40}
              inputInfo="Enter discount percentage."
            />
          </FormControl>
          <FormControl sx={styles.inputBoxContainer}>
            <Box sx={{ width: '500px' }}>
              <CustomSelect
                name="couponTier"
                data={maxRedemptionList}
                setValue={handleMaxRedemption}
                isLoading={loading}
                mappingId="label"
                mappingName="label"
                inputInfo="Select max redemption."
                placeholder="Select max redemption"
              />
            </Box>
          </FormControl>
          <FormControl sx={styles.inputBoxContainer}>
            {type === 'edit' && (
              <Box sx={{ width: '500px' }}>
                <CustomSelect
                  name="couponTier"
                  data={selectData}
                  setValue={couponTierHandler}
                  isLoading={loading}
                  mappingId="label"
                  mappingName="label"
                  inputInfo="Select discount tier."
                  placeholder="Select discount tier"
                />
              </Box>
            )}
          </FormControl>
        </Box>
      </FormControl>
    </Box>
  );
};

export default CouponCard;
