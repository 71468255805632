import { COMPOSER_MAGIC } from '../Constants/colors';

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: COMPOSER_MAGIC,
      textTransform: 'uppercase',
      fontSize: '18px',
      width: '50px',
      height: '50px',
      '@media (max-width: 600px)': {
        width: '40px',
        height: '40px',
      },
    },
    children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
  };
}
