import { Box, FormControl } from '@mui/material';
import { commonStyles } from '../../Assets/commonStyles';
import CustomInput from '../Widgets/CustomInput/CustomInput';
import CustomLabel from '../Widgets/CustomLabel/CustomLabel';

const CreateRedemtionCard = ({ formData, setFormData }) => {
  const changeHandler = (name, value) => {
    setFormData(value);
  };
  return (
    <Box sx={commonStyles.formCardContainer}>
      <FormControl sx={commonStyles.inputBoxContainer}>
        <Box sx={commonStyles.labelWrapper}>
          <CustomLabel
            customStyles={commonStyles.label}
            labelText="Discount Redemption Limit"
            subText="[store view]"
          />
        </Box>
        <Box sx={commonStyles.inputWrapper}>
          <CustomInput
            type="number"
            name="redemptionamount"
            value={formData}
            setValue={changeHandler}
            inputInfo="Enter max redemption amount."
            placeholder="Enter max redemption amount"
          />
        </Box>
      </FormControl>
      <Box sx={commonStyles.inputBoxContainer}>
        <Box sx={commonStyles.inputWrapper}>
          <p style={{ fontFamily: 'IBM Plex Sans' }}>
            <b> Description: </b>
            This input generates the choices for the maximum redemption count
            dropdown menu that is accessible when creating discount coupons
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateRedemtionCard;
