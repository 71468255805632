export const DEBOUNCE_TIME = 500;
export const ROW_PER_PAGE_OPTIONS = [5, 10, 25, 50, 75, 100];
export const DEFAULT_ROW_PER_PAGE = 10;
export const STATUS_OPTIONS = [
  { id: 1, status: true, heading: 'Make Active' },
  { id: 2, status: false, heading: 'Make Inactive' },
];
export const FILE_SIZE = 2000000;

export const USER_ROLES = {
  1: 'Super Admin',
  2: 'Admin',
};
export const SETTINGS_NAME = 'Settings';
