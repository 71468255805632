import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import PromotionsService from './PromotionsService';

const initialState = {
  voucherTypeData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  voucherDiscountTypeData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  allowedBrandsData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  promotionsListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const voucherType = createAsyncThunk(
  'promotions/voucher-type',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PromotionsService.voucherType();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const voucherDiscountType = createAsyncThunk(
  'promotions/voucher-discount-type',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PromotionsService.voucherDiscountType();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const allowedBrands = createAsyncThunk(
  'promotions/allowed-brands',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PromotionsService.allowedBrands();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const getPromotionsList = createAsyncThunk(
  'promotions/list',
  async (data, thunkAPI) => {
    try {
      return await PromotionsService.getPromotionsList(data);
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    resetVoucherType: (state) => {
      state.voucherTypeData.isLoading = false;
      state.voucherTypeData.isError = false;
      state.voucherTypeData.isSuccess = false;
      state.voucherTypeData.data = null;
      state.voucherTypeData.message = '';
    },
    resetVoucherDiscountType: (state) => {
      state.voucherDiscountTypeData.isLoading = false;
      state.voucherDiscountTypeData.isError = false;
      state.voucherDiscountTypeData.isSuccess = false;
      state.voucherDiscountTypeData.data = null;
      state.voucherDiscountTypeData.message = '';
    },
    resetAllowedBrands: (state) => {
      state.allowedBrandsData.isLoading = false;
      state.allowedBrandsData.isError = false;
      state.allowedBrandsData.isSuccess = false;
      state.allowedBrandsData.data = null;
      state.allowedBrandsData.message = '';
    },
    resetDetail: (state) => {
      state.promotionsListData.isLoading = false;
      state.promotionsListData.isError = false;
      state.promotionsListData.isSuccess = false;
      state.promotionsListData.data = null;
      state.promotionsListData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(voucherType.pending, (state) => {
        state.voucherTypeData.isLoading = true;
        state.voucherTypeData.isError = false;
        state.voucherTypeData.isSuccess = false;
        state.voucherTypeData.data = null;
        state.voucherTypeData.message = '';
      })
      .addCase(voucherType.rejected, (state, action) => {
        state.voucherTypeData.isLoading = false;
        state.voucherTypeData.isError = true;
        state.voucherTypeData.isSuccess = false;
        state.voucherTypeData.data = null;
        state.voucherTypeData.message = action.payload;
      })
      .addCase(voucherType.fulfilled, (state, action) => {
        state.voucherTypeData.isLoading = false;
        state.voucherTypeData.isError = false;
        state.voucherTypeData.isSuccess = true;
        state.voucherTypeData.data = action.payload?.data;
        state.voucherTypeData.message = action.payload?.message;
      })
      .addCase(voucherDiscountType.pending, (state) => {
        state.voucherDiscountTypeData.isLoading = true;
        state.voucherDiscountTypeData.isError = false;
        state.voucherDiscountTypeData.isSuccess = false;
        state.voucherDiscountTypeData.data = null;
        state.voucherDiscountTypeData.message = '';
      })
      .addCase(voucherDiscountType.rejected, (state, action) => {
        state.voucherDiscountTypeData.isLoading = false;
        state.voucherDiscountTypeData.isError = true;
        state.voucherDiscountTypeData.isSuccess = false;
        state.voucherDiscountTypeData.data = null;
        state.voucherDiscountTypeData.message = action.payload;
      })
      .addCase(voucherDiscountType.fulfilled, (state, action) => {
        state.voucherDiscountTypeData.isLoading = false;
        state.voucherDiscountTypeData.isError = false;
        state.voucherDiscountTypeData.isSuccess = true;
        state.voucherDiscountTypeData.data = action.payload?.data;
        state.voucherDiscountTypeData.message = action.payload?.message;
      })
      .addCase(allowedBrands.pending, (state) => {
        state.allowedBrandsData.isLoading = true;
        state.allowedBrandsData.isError = false;
        state.allowedBrandsData.isSuccess = false;
        state.allowedBrandsData.data = null;
        state.allowedBrandsData.message = '';
      })
      .addCase(allowedBrands.rejected, (state, action) => {
        state.allowedBrandsData.isLoading = false;
        state.allowedBrandsData.isError = true;
        state.allowedBrandsData.isSuccess = false;
        state.allowedBrandsData.data = null;
        state.allowedBrandsData.message = action.payload;
      })
      .addCase(allowedBrands.fulfilled, (state, action) => {
        state.allowedBrandsData.isLoading = false;
        state.allowedBrandsData.isError = false;
        state.allowedBrandsData.isSuccess = true;
        state.allowedBrandsData.data = action.payload?.data;
        state.allowedBrandsData.message = action.payload?.message;
      })
      .addCase(getPromotionsList.pending, (state) => {
        state.promotionsListData.isLoading = true;
        state.promotionsListData.isError = false;
        state.promotionsListData.isSuccess = false;
        state.promotionsListData.data = null;
        state.promotionsListData.message = '';
      })
      .addCase(getPromotionsList.rejected, (state, action) => {
        state.promotionsListData.isLoading = false;
        state.promotionsListData.isError = true;
        state.promotionsListData.isSuccess = false;
        state.promotionsListData.data = null;
        state.promotionsListData.message = action.payload;
      })
      .addCase(getPromotionsList.fulfilled, (state, action) => {
        state.promotionsListData.isLoading = false;
        state.promotionsListData.isError = false;
        state.promotionsListData.isSuccess = true;
        state.promotionsListData.data = action.payload;
        state.promotionsListData.message = action.payload;
      });
  },
});

export const {
  resetVoucherType,
  resetVoucherDiscountType,
  resetAllowedBrands,
  resetDetail,
} = promotionsSlice.actions;
export default promotionsSlice.reducer;
