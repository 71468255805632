import { MARIGOLD } from '../../Constants/colors';

export const styles = {
  labelStyles: {
    margin: '7px',
    paddingRight: '5px',
    borderRadius: '5px',
    '&: hover': {
      backgroundColor: MARIGOLD,
    },
  },
};
