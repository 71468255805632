import { Box, Typography } from '@mui/material';

import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { styles } from './CustomMultiCheckboxCardStyles';

const CustomMultiCheckboxCard = (props) => {
  const { thumbnail, title, category } = props;
  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={styles.thumbnail} component="img" src={thumbnail} />
      <Box sx={styles.contentWrapper}>
        <CustomToolTip title={title}>
          <Typography sx={styles.title}>{title}</Typography>
        </CustomToolTip>
        <Typography sx={styles.category}>{category}</Typography>
      </Box>
    </Box>
  );
};

export default CustomMultiCheckboxCard;
