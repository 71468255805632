export const WILDCARD = '*';
export const ROOT = '/';
export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const MEMBERSHIP = '/membership';
export const MEMBERSHIP_ADD = '/membership/add';
export const MEMBERSHIP_EDIT = '/membership/edit/:id';
//  route added to get all marketplace_membership in sidebar
export const MARKETPLACE_MEMBERSHIP = '/marketplace/memberships';
// route added to add a marketplace membership
export const MARKETPLACE_MEMBERSHIP_ADD = '/marketplace/membership';
export const MARKETPLACE_MEMBERSHIP_EDIT = '/marketplace/membership/:id';

export const PROMOTION = '/promotions';
export const CLOUD_CREDITS = '/cloud-credits';
export const CLOUD_CREDITS_EDIT = '/cloud-credits/edit/:id';

export const PROMOTION_DETAIL = '/promotions/detail/:id';
export const PROMOTION_DETAIL_NAVIGATE = '/promotions/detail';
export const PROMOTION_ADD = '/promotions/add';
export const PROMOTION_EDIT = '/promotions/edit/:id';
export const PROMOTION_EDIT_NAVIGATE = '/promotions/edit/';

export const PROMOTION_CATEGORY = '/promotion-category';
export const PROMOTION_CATEGORY_ADD = '/promotion-category/add';
export const PROMOTION_CATEGORY_EDIT = '/promotion-category/edit/:id';
export const PROMOTION_CATEGORY_EDIT_NAVIGATE = '/promotion-category/edit/';

export const LOYALTY_CAMPAIGN = '/loyalty-campaign';
export const LOYALTY_CAMPAIGN_ADD = '/loyalty-campaign/add';
export const LOYALTY_CAMPAIGN_EDIT = '/loyalty-campaign/edit/:id';
export const LOYALTY_CAMPAIGN_EDIT_NAVIGATE = '/loyalty-campaign/edit/';

export const LOYALTY_ITEM = '/loyalty-item';
export const GIFT_CARD_REDEMPTION = '/gift-card-redemption';

export const LOYALTY_ITEM_CATEGORY = '/loyalty-item-category';
export const LOYALTY_ITEM_CATEGORY_ADD = '/loyalty-item-category/add';
export const LOYALTY_ITEM_CATEGORY_EDIT = '/loyalty-item-category/edit/:id';

export const LOYALTY_ITEM_ADD = '/loyalty-item/add';
export const LOYALTY_ITEM_EDIT = '/loyalty-item/edit/:id';
export const LOYALTY_ITEM_EDIT_NAVIGATE = '/loyalty-item/edit/';

export const CUSTOMER_LIST = '/customers';
export const CUSTOMER_LIST_EDIT = '/customers/edit/:id';

export const SETTINGS = 'settings';
export const SALES_AGENTS = '/sales-agents';
export const SALES_AGENTS_ADD = '/sales-agent';
export const SALES_AGENTS_EDIT = '/sales-agent/:id';
export const SALES_CAMPAIGNS = '/sales-campaigns';
export const SALES_CAMPAIGNS_ADD = '/sales-campaign';
export const PROMOTIONS_SETTINGS = '/promotion-setting';
export const PROMOTION_FREE_ITEM_SETTINGS = '/promotion-free-item-setting';
export const GIFTBIT_SETTINGS = '/giftbit-setting';
export const CONFIGURATION_SETTINGS = '/configuration-setting';
export const DONATION_SETTINGS = '/donation-settings';
export const DONATION_SETTINGS_SINGLE = '/donation-settings/:slug';
export const USP_SETTINGS = '/usp-settings';
export const CREATE_REDEMPTION = '/create-redemption-settings';
export const COUPON = '/coupon';
export const COUPON_TIER = '/coupon-tier';
export const COUPON_TIER_ADD = '/coupon-tier/add';
export const COUPON_TIER_EDIT = '/coupon-tier/edit';
export const COUPON_CREATE = '/coupon/add';
export const COUPON_EDIT = '/coupon/edit';
export const USP_PROMOTIONS = '/usp-promotions';
export const USP_PROMOTIONS_ADD = '/usp-promotions/add';
export const USP_PROMOTIONS_EDIT = '/usp-promotions/edit';
export const MARKETING_SETTINGS = '/marketing-settings';
