import { InputBase, styled } from '@mui/material';

import {
  COTTON_BOLL,
  DIRE_WOLF,
  LEADBELCHER,
  CHINESE_GOLDFISH,
  UP_IN_SMOKE,
  UNICORN_SILVER,
} from '../../../Constants/colors';
import { commonStyles } from '../../../Assets/commonStyles';

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: DIRE_WOLF,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${LEADBELCHER}`,
    padding: '8px 18px 8px 0.5rem',
  },
  '& .Mui-disabled': {
    backgroundColor: UNICORN_SILVER,
  },
}));

export const styles = {
  selectBox: {
    width: '100%',
    height: '40px',
    borderRadius: '0px',
    outline: 'none',
  },
  menu: {
    zIndex: '1302',
    '& .MuiMenu-paper': {
      boxSizing: 'border-box',
      width: '',
      maxHeight: '206px',
      ...commonStyles.scrollBar,
      borderRadius: '0',
    },
  },
  multiSelectMenu: {
    '&& .Mui-selected': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiMenu-paper': {
      boxSizing: 'border-box',
      ...commonStyles.scrollBar,
      width: '',
      borderRadius: '0',
      maxHeight: '450px',
    },
  },
  inputStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    height: '36px',
    paddingLeft: '23px',
    '& .Mui-selected': {
      backgroundColor: `${COTTON_BOLL} !important`,
      '&:hover': {
        backgroundColor: COTTON_BOLL,
      },
    },
  },
  renderMenuItem: {
    // height: '120px',
  },
  menuItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  activeStyle: {
    backgroundColor: COTTON_BOLL,
  },
  renderChipStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    maxHeight: '200px',
    overflow: 'auto',
    ...commonStyles.scrollBar,
  },
  bottomContainer: {
    minHeight: '16px',
  },
  bottomContainerMsg: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    marginTop: '5px',
  },
  errorMsg: {
    color: CHINESE_GOLDFISH,
  },
  infoMsg: {
    color: UP_IN_SMOKE,
  },
  searchField: {
    paddingTop: '5px',
    paddingBottom: '5px',
    fieldset: {
      borderRadius: '10px',
      border: `1px solid ${LEADBELCHER} !important`,
    },
  },
  clearBtn: { cursor: 'pointer' },
};
