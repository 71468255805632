import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { styles } from './CustomCheckboxGroupStyles';

const CustomCheckboxGroup = (props) => {
  const { list, mappingName, onChange, selected, mappingId } = props;

  const getChecked = (val) => {
    return Boolean(selected?.find((value) => +value === val?.[mappingId]));
  };

  return (
    <FormGroup>
      {list?.map((item) => {
        return (
          <FormControlLabel
            sx={styles.labelStyles}
            control={
              <Checkbox
                onChange={(e) => onChange(e, item)}
                checked={getChecked(item)}
                name={item?.[mappingName]}
              />
            }
            label={item?.[mappingName]}
          />
        );
      })}
    </FormGroup>
  );
};

export default CustomCheckboxGroup;
