import { useState } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styles } from './MobileStyles';
import { settingsSubListData, sidebarData } from '../../Constants/sidebarUtils';
import { SETTINGS_NAME } from '../../Constants/constants';
import useWindowSize from '../../Hooks/useWindowSize';
import { SETTINGS } from '../../Routes/Routes';
import mobileHamburger from '../../Assets/img/mobileHamburger.svg';

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleSettingsToggle = () => {
    if (!pathname.includes(SETTINGS)) {
      setIsSettingsOpen((prevState) => !prevState);
    }
  };
  const settingsSubList = () => {
    return (
      <List sx={styles.subList}>
        {settingsSubListData.map((data) => {
          return (
            <NavLink
              key={data.id}
              to={data.name !== SETTINGS_NAME && data.link}
              onClick={() => setIsOpen(false)}
              style={{ textDecoration: 'none' }}
            >
              <ListItem sx={styles.subListItem}>
                <Typography
                  sx={{
                    ...styles.subListItemText,
                    ...(pathname.includes(data.link) &&
                      styles.activeSubListItemText),
                  }}
                >
                  {data.name}
                </Typography>
                {pathname.includes(data.link) && (
                  <CircleIcon sx={styles.subListItemIcon} />
                )}
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    );
  };
  return width < 769 && !!isOpen ? (
    <Box sx={styles.main}>
      <Box sx={styles.mobileHamburgerMain}>
        <Typography
          sx={styles.hamburger}
          component="img"
          src={mobileHamburger}
          onClick={() => setIsOpen(false)}
        />
      </Box>
      <Box sx={styles.listContainer}>
        {sidebarData.map((item) => {
          return (
            <Box
              onClick={
                item.name === SETTINGS_NAME ? handleSettingsToggle : null
              }
              key={item.id}
            >
              <NavLink
                to={item.name !== SETTINGS_NAME && item.link}
                onClick={() => item.name !== SETTINGS_NAME && setIsOpen(false)}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  sx={{
                    ...styles.listItem,
                    ...(pathname.includes(item.link) && styles.activeListItem),
                  }}
                  button
                >
                  <Typography
                    sx={styles.listItemIcon}
                    component="img"
                    src={item.icon}
                  />
                  <Typography sx={styles.listItemText}>{item.name}</Typography>
                  {item.name === SETTINGS_NAME && (
                    <Box sx={styles.listItemEndIconContainer}>
                      {pathname.includes(SETTINGS) || isSettingsOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </Box>
                  )}
                </ListItem>
              </NavLink>
              {item.name === SETTINGS_NAME &&
                (pathname.includes(SETTINGS) || isSettingsOpen) &&
                settingsSubList()}
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : (
    ''
  );
};

export default MobileMenu;
