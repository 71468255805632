import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CustomTable from '../../Components/Widgets/CustomTable/CustomTable';
import { COUPON_CREATE, COUPON_EDIT } from '../../Routes/Routes';

const Coupon = () => {
  const [tierData, setTierData] = useState({});
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allCustomerLoading, setAllCustomerLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [allCustomerIsSuccess, setAllCustomerIsSuccess] = useState(false);
  const [pagination, setPagination] = useState({});
  const [paginationTwo, setPaginationTwo] = useState({
    rowsPerPage: 10,
    page: 1,
  });

  useEffect(() => {
    if (pagination?.page && pagination?.rowsPerPage) {
      setLoading(true);
      axiosInstance
        .get(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/get_allcoupons?limit=${pagination?.rowsPerPage}&skip=${pagination?.page}`
        )
        .then((response) => {
          if (response.data?.result?.length) {
            setTierData(response?.data);
            setIsSuccess(true);
            setLoading(false);
          } else {
            setLoading(false);
            setTierData({});
            setIsSuccess(false);
          }
        });
    }
  }, [pagination]);

  useEffect(() => {
    if (paginationTwo?.page && paginationTwo?.rowsPerPage) {
      setAllCustomerLoading(true);
      setAllCustomerIsSuccess(false);
      axiosInstance
        .get(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/customer_amount?limit=${paginationTwo?.rowsPerPage}&skip=${paginationTwo?.page}`
        )
        .then((response) => {
          if (response.status === 200) {
            setAllCustomerData(response?.data);
            setAllCustomerIsSuccess(true);
            setAllCustomerLoading(false);
          } else {
            setAllCustomerLoading(false);
          }
        });
    }
  }, [paginationTwo]);

  const navigate = useNavigate();
  const handleAddTier = () => {
    navigate(COUPON_CREATE);
  };
  const handleEditClick = (e) => {
    const editData = tierData?.result?.find((datas) => datas?.coupon_id === e);
    navigate(COUPON_EDIT, { state: editData });
  };
  const metaData = [
    {
      keyId: 1,
      mappingId: 'name',
      mappingKey: 'name',
      headingName: 'Name',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 2,
      mappingId: 'coupon_tier',
      mappingKey: 'coupon_tier',
      headingName: 'Discount Tier',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 3,
      mappingId: 'max_redemptions',
      mappingKey: 'max_redemptions',
      headingName: 'Max Redemption',
      colType: 'numberWithCommas',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 4,
      mappingId: 'created_at',
      mappingKey: 'created_at',
      headingName: 'Created Date',
      colType: 'date',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 5,
      mappingId: 'updated_at',
      mappingKey: 'updated_at',
      headingName: 'Updated Date',
      colType: 'date',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 6,
      mappingId: 'coupon_id',
      headingName: 'Manage discount',
      colType: 'edit',
      width: '10%',
      align: 'left',
      handler: {
        onClick: (e) => handleEditClick(e),
      },
    },
  ];
  const metaDataTwo = [
    {
      keyId: 1,
      mappingId: 'firstName',
      mappingKey: 'firstName',
      headingName: 'First Name',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 2,
      mappingId: 'lastName',
      mappingKey: 'lastName',
      headingName: 'Last Name',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 3,
      mappingId: 'email',
      mappingKey: 'email',
      headingName: 'Email',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 4,
      mappingId: 'initialization_time',
      mappingKey: 'initialization_time',
      headingName: 'Created Date',
      colType: 'date',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 5,
      mappingId: 'current_amount',
      mappingKey: 'current_amount',
      headingName: 'Current amount',
      colType: 'numberWithCommas',
      width: '10%',
      align: 'left',
    },
    {
      keyId: 6,
      mappingId: 'last_amount',
      mappingKey: 'last_amount',
      headingName: 'Last amount',
      colType: 'numberWithCommas',
      width: '10%',
      align: 'left',
    },
    {
      keyId: 7,
      mappingId: 'total_paid_amount',
      mappingKey: 'total_paid_amount',
      headingName: 'Total paid amount',
      colType: 'numberWithCommas',
      width: '10%',
      align: 'left',
    },
  ];

  const extraButtonHandler = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASE_URL}list/customer?limit=99999999&skip=0`
      )
      .then((res) => {
        const customerListData = [];
        res?.data?.data?.result?.map((res) =>
          customerListData.push(res?.azureId)
        );
        console.log(res?.data?.data?.result);
        axiosInstance
          .post(
            `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/coupon_allot`,
            {
              azureId: customerListData,
            }
          )
          .then((response) => {
            if (response?.data?.status === 'success') {
              toast.success('successfully initialized discount');
            } else {
              toast.error('error');
            }
          });
      });
  };

  const sendEmail = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASE_URL}list/customer?limit=99999999&skip=0`
      )
      .then((response) => {
        const azureIdList = [];
        const emailIdList = [];
        response?.data?.data?.result?.map((res) => {
          azureIdList.push(res?.azureId);
          emailIdList.push(res?.email);
        });
        axiosInstanse
          .post(
            `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/mail_coupons`,
            {
              azureId: azureIdList,
              email: emailIdList,
            }
          )
          .then((response) => {
            if (response?.data?.status === 'success') {
              toast.success(response?.data?.message);
            } else {
              toast.error(response?.data?.message);
            }
          });
      });
  };
  return (
    <Box sx={commonStyles.mainContainer}>
      <CustomTable
        tableData={tierData?.result}
        metaData={metaData}
        hideSearch
        isSuccess={isSuccess}
        showCount
        extraButtonText="Initialize Discount"
        extraButtonHandler={extraButtonHandler}
        sendEmail={sendEmail}
        isLoading={loading}
        totalCount={tierData.totalCount}
        placeholder="Search by Coupon Tier"
        buttonText="Add New Discount"
        pageHeading="Discount"
        buttonClickHandler={handleAddTier}
        setPagination={setPagination}
      />
      <CustomTable
        tableData={allCustomerData?.result}
        metaData={metaDataTwo}
        hideSearch
        isSuccess={allCustomerIsSuccess}
        showCount
        isLoading={allCustomerLoading}
        totalCount={allCustomerData?.totalCount}
        pageHeading="All Customer Amount"
        setPaginationTwo={setPaginationTwo}
        paginationTwo={paginationTwo}
      />
    </Box>
  );
};

export default Coupon;
