export const styles = {
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '2rem',
    gap: '20px',
    '@media screen and (max-width:769px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0px',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    width: 'auto',
    marginLeft: '30px',
  },
  backNavigationIcon: {
    cursor: 'pointer',
  },
  titleText: {
    fontSize: '20px',
    fontWeight: '500',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: 'auto',
    marginRight: '30px',
  },
  commonBtnStyles: {
    height: '40px',
  },
};
