import { UP_IN_SMOKE } from '../../Constants/colors';

export const styles = {
  mainContainer: {
    display: 'flex',
    width: '600px',
    height: '80px',
    gap: '10px',
    margin: '10px auto',
    minWidth: '0',
    alignItems: 'center',
  },
  thumbnail: {
    width: '20%',
    borderRadius: '4px',
  },
  contentWrapper: {
    width: '70%',
    minWidth: '0',
    position: 'relative',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  category: {
    color: UP_IN_SMOKE,
    fontSize: '14px',
  },
  description: {
    marginTop: '10px',
    color: UP_IN_SMOKE,
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
