import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CustomLoader from '../../Components/Widgets/CustomLoader/CustomLoader';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';
import CouponCard from '../../Components/Coupon/CouponCard';

const CouponAdd = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    discount_percentage: 0,
    name: '',
    max_redemptions: 0,
  });

  const [editFormData, setEditFormData] = useState({
    discount_percentage: location?.state?.discount_percentage,
    name: location?.state?.coupon_id,
    max_redemptions: location?.state?.max_redemptions,
    coupon_tier: location?.state?.coupon_tier,
  });

  const formClearHandler = () => {
    if (type === 'add') {
      setFormData({
        discount_percentage: 0,
        name: '',
        max_redemptions: location?.state?.max_redemptions,
      });
    } else {
      setEditFormData({
        discount_percentage: location?.state?.discount_percentage,
        name: location?.state?.coupon_id,
        max_redemptions: location?.state?.max_redemptions,
        coupon_tier: location?.state?.coupon_tier,
      });
    }
  };

  const formBackHandler = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (type === 'add') {
      axiosInstance
        .post(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/create_coupon`,
          {
            ...formData,
          }
        )
        .then((res) => {
          if (res?.data?.status === 'success') {
            navigate(-1);
            toast.success(res?.data?.message);
            setIsLoading(false);
          } else {
            toast.error(res?.data?.message);
            setIsLoading(false);
          }
        });
    }
    if (type === 'edit') {
      axiosInstance
        .put(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/update_coupon`,
          {
            coupon_tier: editFormData?.coupon_tier?.id,
            discount_percentage: editFormData?.discount_percentage,
            max_redemptions: editFormData?.max_redemptions,
            name: editFormData?.name,
            coupon_id: location?.state?.coupon_id,
          }
        )
        .then((res) => {
          if (res?.data?.status === 'success') {
            navigate(-1);
            toast.success(res?.data?.message);
            setIsLoading(false);
          } else {
            toast.error(res?.data?.message);
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <Box sx={commonStyles.mainContainer}>
      {isLoading && <CustomLoader />}
      <form onSubmit={handleSubmit}>
        <CustomFormHeader
          title={type === 'add' ? 'Add Discount' : 'Edit Discount'}
          clearBtnHandler={formClearHandler}
          backBtnHandler={formBackHandler}
          showSubmit
        />
        {type === 'add' ? (
          <CouponCard
            formData={formData}
            setFormData={setFormData}
            type={type}
          />
        ) : (
          <CouponCard
            editFormData={editFormData}
            setEditFormData={setEditFormData}
            type={type}
          />
        )}
      </form>
    </Box>
  );
};

export default CouponAdd;
