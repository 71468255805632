import { SOOTY, CHI_GONG, WHITE, DUNES_MANOR } from '../../Constants/colors';
import { commonStyles } from '../../Assets/commonStyles';

export const styles = {
  mainComponent: {
    zIndex: '1302',
  },
  paperComponent: {
    borderRadius: '16px',
    width: '732px',
    margin: 'auto 20px',
    ...commonStyles.scrollBar,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  errorStyle: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '500',
    color: CHI_GONG,
    textAlign: 'center',
    marginBottom: '20px',
  },
  clearIcon: {
    color: WHITE,
    fontSize: '25px',
  },
  titleContainer: {
    bgcolor: DUNES_MANOR,
    height: '72px',
    padding: '0 19px 0 46px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '45px',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '20px',
    color: WHITE,
  },
  contentContainer: {
    padding: 0,
    textAlign: 'center',
    width: '90%',
    margin: '45px auto 0px',
    ...commonStyles.scrollBar,
  },
  actionContainer: {
    padding: 0,
    margin: '36px auto 44px',
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  commonBtn: {
    width: '171px',
    height: '44px',
    borderRadius: '12px',
    fontWeight: '600',
    fontSize: '14px',
    boxShadow: 'none',
    lineHeight: '20px',
    textTransform: 'capitalize',
    '@media screen and (max-width:475px)': {
      width: '140px',
    },
  },
  boxLoaderContainer: {
    height: '150px',
    display: 'flex',
    alignItems: 'center',
  },
  descriptionText: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '20px',
    color: SOOTY,
  },
  cancelBtn: {
    marginRight: '26px',
    '@media(max-width:600px)': {
      marginRight: '10px',
    },
  },
  submitBtn: {
    fontWeight: 'bold',
  },
};
