import axiosInstance from '../API/axiosInstance';
import { PROMOTIONS_API } from '../Constants/apiEndpoints';

export const editPromotions = async (id, data) => {
  const response = { error: '', data: null };
  try {
    response.data = await axiosInstance.put(`${PROMOTIONS_API}/${id}`, data);
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
export const editUSPPromotions = async (id, data, blean, setBlean) => {
  const response = { error: '', data: null };
  try {
    response.data = await axiosInstance.put(
      '/usp/promotion/change-status-promotions',
      {
        id: id,
        status: data?.basicInfo?.isActive,
      }
    );
  } catch (err) {
    response.error = err.response;
  }
  setBlean(!blean);
  return response;
};

export const getPromotionsDetails = async (id) => {
  const response = { error: '', data: null };
  try {
    response.data = await axiosInstance.get(`${PROMOTIONS_API}/${id}`);
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const addPromotions = async (data) => {
  const response = { error: '', data: null };
  try {
    response.data = await axiosInstance.post(PROMOTIONS_API, data);
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
