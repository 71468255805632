import axios from 'axios';
import { logUserOut } from '../Auth/AuthUtils';
import { getToken } from '../Auth/getToken';
import { UNAUTHORIZED_USER } from '../Constants/statusCodes';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';
  config.headers.Authorization = getToken();
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === UNAUTHORIZED_USER) {
      logUserOut();
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response?.status === UNAUTHORIZED_USER) {
      logUserOut();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
