import { ArrowLeft, Delete, TrashCan } from '@carbon/icons-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Row, TextInput, Column } from '@carbon/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SALES_AGENTS } from '../../Routes/Routes';
import { validationHandler } from '../../Utils/validations';
import { isValidEmail } from '../../Utils/validations'; // Import email validation function
import axiosInstance from '../../API/axiosInstance';
import { toast } from 'react-toastify';

const Wrapper = styled.main`
  padding: 2rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      gap: 1.5rem;
      align-items: center;
      display: flex;
      button.backButton {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        align-items: center;
        display: flex;
      }
      h4 {
      }
    }
    .button-set {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    label {
      &::after {
        content: '*';
        color: #9f3333;
      }
    }
  }
`;
const SelectMenuStyle = styled.div`
  .carbon-select__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1; /* Ensure the dropdown appears above other content */
  }
`;

const SalesAgentsAdd = ({ type }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
    userName: null,
    firstName: null,
    lastName: null,
    email: null,
  });
  const [modalState, setModalState] = useState({
    open: false,
    isLoading: false,
  });

  const fetchSalesAgentsData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}sales-agent/${params.id}`
      );
      setFormData((prevState) => {
        return {
          ...prevState,
          userName: response?.data?.data?.userName,
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
          email: response?.data?.data?.email,
        };
      });
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      fetchSalesAgentsData();
    }
  }, []);

  const handleFormSubmit = () => {
    // Validate email address
    if (!isValidEmail(formData.email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    if (type === 'add') {
      axiosInstance
        .post(`${process.env.REACT_APP_API_BASE_URL}sales-agent`, {
          userName: formData.userName,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        })
        .then((response) => {
          navigate(SALES_AGENTS);
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    } else {
      axiosInstance
        .put(`${process.env.REACT_APP_API_BASE_URL}sales-agent/${params.id}`, {
          userName: formData.userName,
          firstName: formData.firstName,
          lastName: formData.lastName,
        })
        .then((response) => {
          navigate(SALES_AGENTS);
          toast.success('Sale Agent updated succesfully');
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <div className="left">
          <Link to={SALES_AGENTS}>
            <button className="backButton">
              <ArrowLeft size={24} />
            </button>
          </Link>
          <h4>{type === 'add' ? 'Add' : 'Edit'} Sales Agent</h4>
        </div>
        <div className="button-set">
          <Button onClick={handleFormSubmit}>Submit</Button>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <Column lg={3}>
            <label htmlFor="userName"> User Name</label>
          </Column>

          <Column lg={4}>
            <TextInput
              value={formData?.userName}
              id="userName"
              name="userName"
              type="text"
              helperText="Enter the User Name."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  userName: event.target.value,
                }))
              }
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <label htmlFor="firstName">First Name</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.firstName}
              id="firstName"
              name="firstName"
              type="text"
              helperText="Enter first Name."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  firstName: event.target.value,
                }))
              }
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <label htmlFor="lastName">Last Name</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.lastName}
              id="lastName"
              name="lastName"
              type="text"
              helperText="Enter last Name."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  lastName: event.target.value,
                }))
              }
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <label htmlFor="email">Email</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.email}
              id="email"
              name="email"
              type="email"
              disabled={type === 'edit'}
              helperText="Enter Email Id."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  email: event.target.value,
                }))
              }
            />
          </Column>
        </Row>
      </form>
    </Wrapper>
  );
};

export default SalesAgentsAdd;
