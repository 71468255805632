import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import LoyaltyCampaignsService from './LoyaltyCampaignsService';

const initialState = {
  loyaltyCampaignsListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getLoyaltyCampaignsList = createAsyncThunk(
  'loyaltyCampaigns/list',
  async (data, thunkAPI) => {
    try {
      return await LoyaltyCampaignsService.getLoyaltyCampaignsList(data);
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loyaltyCampaignsSlice = createSlice({
  name: 'loyaltyCampaigns',
  initialState,
  reducers: {
    resetDetail: (state) => {
      state.loyaltyCampaignsListData.isLoading = false;
      state.loyaltyCampaignsListData.isError = false;
      state.loyaltyCampaignsListData.isSuccess = false;
      state.loyaltyCampaignsListData.data = null;
      state.loyaltyCampaignsListData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoyaltyCampaignsList.pending, (state) => {
        state.loyaltyCampaignsListData.isLoading = true;
        state.loyaltyCampaignsListData.isError = false;
        state.loyaltyCampaignsListData.isSuccess = false;
        state.loyaltyCampaignsListData.data = null;
        state.loyaltyCampaignsListData.message = '';
      })
      .addCase(getLoyaltyCampaignsList.rejected, (state, action) => {
        state.loyaltyCampaignsListData.isLoading = false;
        state.loyaltyCampaignsListData.isError = true;
        state.loyaltyCampaignsListData.isSuccess = false;
        state.loyaltyCampaignsListData.data = null;
        state.loyaltyCampaignsListData.message = action.payload;
      })
      .addCase(getLoyaltyCampaignsList.fulfilled, (state, action) => {
        state.loyaltyCampaignsListData.isLoading = false;
        state.loyaltyCampaignsListData.isError = false;
        state.loyaltyCampaignsListData.isSuccess = true;
        state.loyaltyCampaignsListData.data = action.payload;
        state.loyaltyCampaignsListData.message = action.payload;
      });
  },
});

export const { resetDetail } = loyaltyCampaignsSlice.actions;
export default loyaltyCampaignsSlice.reducer;
