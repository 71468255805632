import axiosInstance from '../../API/axiosInstance';
import { getQsValues } from '../../Constants/getQsValues';
import { PROMOTION_CATEGORY_LIST } from '../../Constants/apiEndpoints';

const getPromotionCategoryList = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    const response = await axiosInstance.get(
      `${PROMOTION_CATEGORY_LIST}?${newQsValue}`
    );
    return response?.data?.data;
  } catch (err) {
    const error = JSON.stringify(err);
    throw Error(error);
  }
};

const PromotionCategoryService = { getPromotionCategoryList };

export default PromotionCategoryService;
