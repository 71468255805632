import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

import { RAINY_GREY } from '../../../Constants/colors';
import { commonStyles } from '../../../Assets/commonStyles';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: '0 !important',
    borderSpacing: '0 !important',
  },
}));

export const styles = {
  mainContainer: {
    width: 'calc(100vw - 310px)',
    margin: '2rem',
    '@media screen and (max-width:769px)': {
      width: '90%',
      margin: '10px auto',
      padding: '20px 0',
    },
  },
  mainHeader: {
    marginBottom: '10px',
  },
  mainHeaderStyle: {
    fontSize: '30px',
    lineHeight: '20px',
    fontWeight: '500',
  },
  headerBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '30px',
    height: '100%',
    width: '100%',
  },
  recordCountStyle: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  rightHeaderBox: {
    display: 'flex',
    columnGap: '10px',
    '@media screen and (max-width:769px)': {
      flexDirection: 'column',
      width: '100%',
      gap: '10px',
    },
  },
  tableStyle: {
    width: '100%',
    maxHeight: 'calc(100vh - 250px)',
    ...commonStyles.scrollBar,
  },
  tableMain: {
    '@media screen and (max-width:769px)': {
      padding: '20px 0',
    },
  },
  tableMainStyle: {
    minWidth: '700px',
  },
  loaderErrorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  loaderContainer: {
    position: 'relative',
    height: '400px',
  },
  imageLoaderError: {
    top: '30%',
  },
  imageStyleText: {
    color: RAINY_GREY,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  errorStyle: {
    fontSize: '25px',
    fontWeight: '600',
  },
  description: {
    fontSize: '15px',
    lineHeight: 'normal',
    marginTop: '1rem',
  },
};
