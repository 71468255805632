import { commonStyles } from '../../Assets/commonStyles';
import { DUNES_MANOR, GONDOLA, WHITE } from '../../Constants/colors';

export const styles = {
  hamburger: {
    width: '25px',
    height: '25px',
  },
  mobileHamburgerMain: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '25px 10px',
  },
  main: {
    width: '100%',
    minHeight: '100vh',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'black',
    zIndex: 9999,
  },

  drawer: {
    width: '215px',
    '& .MuiDrawer-paper': {
      overflow: 'hidden',
      display: 'flex',
      width: 'inherit',
      borderRadius: '0px 10px 10px 0px',
      border: 'none',
      '& .MuiToolbar-root': {
        minHeight: '76px !important',
      },
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  list: {
    overflowY: 'auto',
    width: 'inherit',
    height: '100%',
    marginTop: '1px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 10px 10px 0px',
    backgroundColor: GONDOLA,
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    overflowX: 'hidden',
    ...commonStyles.scrollBar,
  },
  logoWrapper: {
    justifyContent: 'center',
  },
  logo: {
    fontSize: '50px',
  },
  listContainer: {
    padding: '0',
    margin: '0',
    boxSizing: 'border-box',
  },
  listItem: {
    height: '51px',
    margin: '13px auto',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    '&:hover': {
      backgroundColor: DUNES_MANOR,
    },
  },
  activeListItem: {
    backgroundColor: DUNES_MANOR,
    '.MuiTypography-root': {
      color: WHITE,
    },
    '&:hover': {
      backgroundColor: DUNES_MANOR,
    },
  },
  listItemIcon: {
    color: WHITE,
    width: '24px',
  },
  listItemText: {
    color: WHITE,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    textDecoration: 'none',
    marginLeft: '17px',
  },
  subList: {
    padding: '0',
    marginTop: '0px',
    marginLeft: '30px',
  },
  subListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginLeft: '0',
    gap: '15px',
  },
  subListItemIcon: {
    fontSize: '10px',
    color: WHITE,
  },
  subListItemText: {
    fontSize: '16px',
    lineHeight: '119%',
    fontWeight: '400',
    color: WHITE,
  },
  activeSubListItemText: {
    color: WHITE,
    fontWeight: '600',
  },
  listItemEndIconContainer: {
    position: 'absolute',
    top: '15px',
    right: '25px',
    color: WHITE,
  },
};
