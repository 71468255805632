import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import CustomButton from '../Widgets/CustomButton/CustomButton';
import CustomLoader from '../Widgets/CustomLoader/CustomLoader';
import { styles } from './DialogBoxStyles';

const DialogBox = (props) => {
  const {
    open,
    closeHandler,
    cancelBtnText,
    submitBtnText,
    customContentStyle,
    titleText,
    showCancelBtn,
    showSubmitBtn,
    cancelBtnHandler,
    submitBtnHandler,
    customSubmitButtonStyle,
    customStyles,
    description,
    isChildrenLoading,
    isSubmitLoading,
    errorMessage,
    dialogSize = 'xl',
    customActionStyle,
    contentContainerId,
    children,
  } = props;

  const paperStyle = { sx: { ...styles.paperComponent, ...customStyles } };

  return (
    <Dialog
      sx={styles.mainComponent}
      maxWidth={dialogSize}
      PaperProps={paperStyle}
      open={open}
      onClose={closeHandler}
    >
      <DialogTitle sx={styles.titleContainer}>
        <Typography sx={styles.titleText}>{titleText}</Typography>
        <IconButton onClick={closeHandler}>
          <ClearIcon sx={styles.clearIcon} />
        </IconButton>
      </DialogTitle>
      {description && (
        <Box sx={styles.descriptionWrapper}>
          <Typography sx={styles.descriptionText}>{description}</Typography>
        </Box>
      )}
      {children &&
        (isChildrenLoading ? (
          <Box sx={styles.boxLoaderContainer}>
            <CustomLoader />
          </Box>
        ) : (
          <DialogContent
            sx={{ ...styles.contentContainer, ...customContentStyle }}
            id={contentContainerId}
          >
            {children}
          </DialogContent>
        ))}
      <DialogActions sx={{ ...styles.actionContainer, ...customActionStyle }}>
        {errorMessage && (
          <Typography sx={styles.errorStyle}>{errorMessage}</Typography>
        )}
        <Box sx={styles.buttonContainer}>
          {showCancelBtn && (
            <CustomButton
              clickHandler={cancelBtnHandler}
              type="cancel"
              customStyles={{ ...styles.commonBtn, ...styles.cancelBtn }}
              disabled={isSubmitLoading}
              textContent={cancelBtnText || 'cancel'}
            />
          )}
          {showSubmitBtn &&
            (isSubmitLoading ? (
              <CustomLoader />
            ) : (
              <CustomButton
                type="submit"
                clickHandler={submitBtnHandler}
                customStyles={{
                  ...styles.commonBtn,
                  ...styles.cancelBtn,
                  ...customSubmitButtonStyle,
                }}
                textContent={submitBtnText || 'yes'}
              />
            ))}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
