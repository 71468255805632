import {
  GONDOLA,
  UP_IN_SMOKE,
  BRILLIANT_LIQUORICE,
  WHITE,
} from '../../Constants/colors';

export const styles = {
  appBar: {
    position: 'relative',
    zIndex: '1201',
    backgroundColor: 'rgb( 0 0 0 )',
  },
  appBarContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    paddingRight: '35px',
    height: '76px',
    boxSizing: 'border-box',
    '@media (max-width: 900px)': {
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  },
  userProfile: {
    width: '50px',
    height: '50px',
    '@media (max-width: 600px)': {
      width: '40px',
      height: '40px',
    },
    img: {
      objectFit: 'scale-down',
    },
  },
  headerAction: {
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
    textAlign: 'center',
    color: GONDOLA,
    '@media (max-width: 600px)': {
      fontSize: '15px',
      lineHeight: '15px',
    },
  },
  profileMenuContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '2%',
    width: '100%',
  },
  profileText: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
    color: WHITE,
    textTransform: 'capitalize',
    marginLeft: '11px',
    marginRight: '11px',
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  dropDownIcon: {
    cursor: 'pointer',
    color: BRILLIANT_LIQUORICE,
  },
  logoImage: {
    width: '45px',
  },
  hamburger: {
    width: '25px',
    height: '25px',
    '@media (min-width: 769px)': {
      display: 'none',
    },
  },
  logoText: {
    fontSize: '28px',
    color: WHITE,
    textTransform: 'uppercase',
  },
  headerActionWrapper: {
    color: GONDOLA,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textTransform: 'uppercase',
    columnGap: '63px',
    '@media (max-width: 600px)': {
      justifyContent: 'space-between',
      columnGap: '10px',
    },
  },
  headerActionMainContainer: {
    '@media (max-width: 600px)': {
      marginLeft: '-10px',
    },
  },
  toolbar: {
    marginLeft: '-10px',
    gap: '10px',
  },
  profileWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  endDetailContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  customerType: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: UP_IN_SMOKE,
  },
  lastDropDownItem: {
    marginBottom: '0px',
  },
  dropDownItem: {
    height: '49px',
    boxSizing: 'border-box',
    color: BRILLIANT_LIQUORICE,
    paddingLeft: '24px',
    marginBottom: '5px',
  },
  actionItemContent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
  },
  divider: {
    marginLeft: '16px',
    marginRight: '16px',
    marginBottom: '4px',
    color: UP_IN_SMOKE,
  },
  actionItemContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDownContainer: {
    '.MuiMenu-paper': {
      boxShadow: '-8px 6px 25px 2px rgba(43, 78, 118, 0.23)',
      minWidth: '281px',
      borderRadius: '6px',
    },
    '.MuiMenu-list': {
      paddingTop: '15px',
      paddingBotton: '15px',
    },
    minWidth: '281px',
    marginTop: '70px',
  },
  notificationIconWrap: {
    marginRight: '13px',
  },
};
