import { WHITE, MARIGOLD, GREY } from '../../Constants/colors';
import { commonStyles } from '../../Assets/commonStyles';

export const styles = {
  listnerContainer: {
    height: '100%',
  },
  listenerBox: {
    height: '100%',
    position: 'relative',
  },
  innerAbsoluteContainer: {
    position: 'absolute',
    zIndex: '3',
    top: '50px',
    right: '0px',
  },
  buttonStyle: {
    height: '40px',
    backgroundColor: '#fff',
    border: '1px solid #393939',
    color: '#010101',
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
  },
  mainComponent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '200px',
    minWidth: '350px',
    boxShadow:
      '4px -4px 20px rgba(224, 220, 220, 0.25), -4px 4px 20px rgba(173, 173, 173, 0.25)',
    borderRadius: '5px',
    backgroundColor: WHITE,
  },
  innerMainComponent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerLeftContainer: {
    margin: '10px',
    width: '55%',
  },
  innerCenterContainer: {
    width: '5%',
  },
  innerRightContainer: {
    margin: '10px',
    width: '40%',
  },
  dividerStyle: {
    borderColor: GREY,
    marginTop: '20px',
    borderLeftWidth: '2px',
    borderRightWidth: '0',
    height: '190px',
  },
  innerScrollContainer: {
    width: '200px',
    overflowY: 'auto',
    maxHeight: '210px',
    ...commonStyles.scrollBar,
  },
  lowerMainComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    gap: '10px',
    margin: '20px',
    height: '100%',
  },
  filterList: {
    display: 'flex',
    justifyContent: 'start',
    margin: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  filterListText: {
    margin: '7px',
  },
  activeFilter: {
    backgroundColor: MARIGOLD,
  },
};
