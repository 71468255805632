import { ArrowLeft } from '@carbon/icons-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  NumberInput,
  Row,
  TextInput,
  Column,
  Modal,
  Loading,
  Accordion,
  AccordionItem,
  Dropdown,
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import CustomImageUpload from '../../Components/CustomImageUpload/CustomImageUpload';
import { FILE_SIZE } from '../../Constants/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MEMBERSHIP } from '../../Routes/Routes';
import { IS_REQUIRED_VALIDATION } from '../../Constants/validationConstant';
import { BANNER_IMAGE_NOT_VALID } from '../../Constants/errorMessages';
import { validationHandler } from '../../Utils/validations';
import axiosInstance from '../../API/axiosInstance';
import { toast } from 'react-toastify';
import _ from 'lodash';
const Wrapper = styled.main`
  padding: 2rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      gap: 1.5rem;
      align-items: center;
      display: flex;
      button.backButton {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        align-items: center;
        display: flex;
      }
      h4 {
      }
    }
    .button-set {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    label {
      &::after {
        content: '*';
        color: #9f3333;
      }
    }
  }
`;

const initialData = {
  bannerImageId: null,
  name: null,
  points: null,
  prevImageId: null,
  loyaltyPointsMultiplier: null,
  discountPercentage: null,
  freeItems: [],
};
const initialErrorData = {
  ...initialData,
};
const validationConfig = {
  bannerImageId: {
    [IS_REQUIRED_VALIDATION]: {
      errorMsg: BANNER_IMAGE_NOT_VALID,
    },
  },
};
const initialModalState = {
  open: false,
  isLoading: false,
};
const MembershipAdd = ({ type }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState(initialData);
  const [errorData, setErrorData] = useState(initialErrorData);
  const [modalState, setModalState] = useState(initialModalState);
  const [products, setProducts] = useState(null);
  const handleModalClose = () => {
    setModalState((state) => {
      return { ...state, open: false };
    });
  };
  const formValidationHandler = (name, value) => {
    const newFormData = { ...formData, [name]: value };
    validationHandler(newFormData, setErrorData, {
      [name]: validationConfig?.[name],
    });
  };
  const changeHandler = (name, value) => {
    let newValue = value;
    setFormData((prevState) => {
      return { ...prevState, [name]: newValue };
    });
    if (errorData?.[name] && validationConfig?.[name]) {
      formValidationHandler(name, newValue);
    }
  };
  const imageErrorHandler = (msg) => {
    setErrorData((prevState) => {
      return { ...prevState, bannerImageId: msg };
    });
  };

  const fetchMembershipData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}membership/${params.id}`
      );

      // Filter out free items with all fields empty
      const filteredFreeItems = response?.data?.data?.freeitems
        .filter((item) => Object.values(item).some((value) => value !== null))
        .map((item) => ({
          productId: item.id,
          qty: item.productQty,
          expirationDays: item.expirationDays,
          optionalLoyaltyPoints: item.optionalLoyaltyPoints,
        }));

      setFormData((prevState) => {
        return {
          ...prevState,
          name: response?.data?.data?.name,
          bannerImageId: response?.data?.data?.imageId,
          points: response?.data?.data?.points,
          prevImageId: response?.data?.data?.imageId,
          loyaltyPointsMultiplier:
            response?.data?.data?.loyaltyPointsMultiplier,
          discountPercentage: response?.data?.data?.discountPercentage,
          freeItems: filteredFreeItems,
        };
      });
      console.log(filteredFreeItems);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductSelect = (productId) => {
    // Find the selected product from the products list
    const productExists = formData.freeItems.some(
      (item) => item.productId == productId
    );
    // Check if the selected product exists
    if (!productExists) {
      const selectedProduct = products.result.find(
        (item) => item.id == productId
      );
      // Construct the product object with required data
      const newProduct = {
        productId: selectedProduct.id,
        productName: selectedProduct.productName,
        qty: null,
        expirationDays: null,
        optionalLoyaltyPoints: null,
      };

      // Update the formData state to include the new product
      setFormData((prevState) => ({
        ...prevState,
        freeItems: [...prevState.freeItems, newProduct],
      }));
    }
  };

  const handleFieldChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedItems = prevData.freeItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      return { ...prevData, freeItems: updatedItems };
    });
  };

  const handleDel = (index) => {
    setFormData((prevData) => {
      // Create a new array without the item at the specified index
      const updatedFreeItems = prevData.freeItems.filter(
        (item, i) => i !== index
      );
      // Update the formData state with the new array
      return {
        ...prevData,
        freeItems: updatedFreeItems,
      };
    });
  };

  const renderProductFields = () => {
    if (!products) {
      return <Loading />; // Render loading indicator while products are being fetched
    }
    return formData.freeItems.map((product, index) => (
      <div key={index}>
        <Row>
          <Column lg={3}>
            <TextInput
              value={
                products.result.find((p) => p.id === product.productId)
                  ?.productName || ''
              }
              placeholder="ProductName"
              labelText="Product"
              disabled
            />
          </Column>
          <Column>
            <TextInput
              value={product.qty}
              onChange={(event) =>
                handleFieldChange(index, 'qty', event.target.value)
              }
              placeholder="Quantity"
              labelText="Quantity"
              // invalid="false"
            />
          </Column>
          <Column lg={3}>
            <TextInput
              value={product.expirationDays}
              onChange={(event) =>
                handleFieldChange(index, 'expirationDays', event.target.value)
              }
              placeholder="Expiration Days"
              labelText="Expiration Days"
            />
          </Column>
          <Column lg={4}>
            <TextInput
              value={product.optionalLoyaltyPoints}
              onChange={(event) =>
                handleFieldChange(
                  index,
                  'optionalLoyaltyPoints',
                  event.target.value
                )
              }
              placeholder="Optional Loyalty Points"
              labelText="Optional Loyalty Points"
            />
          </Column>
          <Column lg={3}>
            <Button
              hasIconOnly
              renderIcon={TrashCan}
              iconDescription="Delete"
              onClick={() => handleDel(index)}
              style={{
                margin: '16px',
                backgroundColor: '#E3242B',
              }}
            />
          </Column>
        </Row>
      </div>
    ));
  };

  useEffect(() => {
    if (type === 'edit') {
      fetchMembershipData();
    }
  }, []);

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const response = await axiosInstance.get('all-products');
        setProducts(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchProductList();
  }, []);
  const handleFormSubmit = () => {
    if (type === 'add') {
      // Convert string values to integers for numeric fields
      const formDataWithIntegers = {
        ...formData,
        freeItems: formData.freeItems.map((item) => ({
          productId: item.productId,
          qty: parseInt(item.qty), // Parse string to integer
          expirationDays: parseInt(item.expirationDays), // Parse string to integer
          optionalLoyaltyPoints: parseInt(item.optionalLoyaltyPoints), // Parse string to integer
        })),
      };
      axiosInstance
        .post(`${process.env.REACT_APP_API_BASE_URL}membership`, {
          points: parseInt(formDataWithIntegers.points),
          name: formDataWithIntegers.name,
          imageId: formDataWithIntegers.bannerImageId,
          loyaltyPointsMultiplier: parseInt(
            formDataWithIntegers.loyaltyPointsMultiplier
          ),
          discountPercentage: parseInt(formDataWithIntegers.discountPercentage),
          freeItems: formDataWithIntegers.freeItems,
        })
        .then((response) => {
          navigate('/membership');
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    } else {
      axiosInstance
        .put(`${process.env.REACT_APP_API_BASE_URL}membership/${params.id}`, {
          points: parseInt(formData.points),
          name: formData.name,
          imageId: formData.bannerImageId,
          loyaltyPointsMultiplier: parseInt(formData.loyaltyPointsMultiplier),
          discountPercentage: parseInt(formData.discountPercentage),
          freeItems: formData.freeItems.map((item) => ({
            productId: item.productId,
            qty: parseInt(item.qty),
            expirationDays: parseInt(item.expirationDays),
            optionalLoyaltyPoints: parseInt(item.optionalLoyaltyPoints),
          })),
        })
        .then((response) => {
          navigate('/membership');
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    }
  };
  const handleDelete = () => {
    setModalState((state) => {
      return { ...state, open: false, isLoading: true };
    });
    axiosInstance
      .delete(`${process.env.REACT_APP_API_BASE_URL}membership/${params.id}`)
      .then((response) => {
        navigate('/membership');
        toast.success(response.data.message);
        setModalState((state) => {
          return { ...state, open: false, isLoading: false };
        });
      })
      .catch((errorData) => {
        console.log(errorData);
        toast.error(errorData);
        setModalState((state) => {
          return { ...state, open: false, isLoading: false };
        });
      });
  };
  return (
    <Wrapper>
      <div className="header">
        <div className="left">
          <Link to={MEMBERSHIP}>
            <button className="backButton">
              <ArrowLeft size={24} />
            </button>
          </Link>
          <h4>{type === 'add' ? 'Add' : 'Edit'} Membership</h4>
        </div>
        <div className="button-set">
          {type === 'edit' && (
            <Button
              kind="danger"
              type="reset"
              onClick={() => {
                setModalState((state) => {
                  return { ...state, open: true };
                });
              }}
            >
              Delete
            </Button>
          )}

          <Button onClick={handleFormSubmit} type="submit">
            Submit
          </Button>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <Column lg={3}>
            <label htmlFor="name">Name</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.name}
              id="name"
              name="name"
              type="text"
              helperText="Enter the Membership Name."
              onChange={(event) =>
                changeHandler(event.target.name, event.target.value)
              }
            />
          </Column>
          <Column lg={3}>
            <label htmlFor="amount">Points</label>
          </Column>
          <Column lg={4}>
            <NumberInput
              id="points"
              name="points"
              value={formData.points}
              helperText="Enter the Membership Points Amount."
              onChange={(event) =>
                changeHandler(event.target.name, event.target.value)
              }
              hideSteppers
              invalidText="Points"
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <label htmlFor="bannerImageId">Image</label>
          </Column>
          <Column lg={4}>
            <CustomImageUpload
              id="bannerImageId"
              name="bannerImageId"
              value={formData?.bannerImageId}
              prevImageId={formData?.prevImageId}
              setValue={changeHandler}
              inputInfo="Maximum file size: 2 MB. Allowed file types: JPG, GIF, PNG."
              hideMessage={true}
              errorMsg={errorData?.bannerImageId}
              imageErrorHandler={imageErrorHandler}
              fileSize={FILE_SIZE}
              type={type}
            />
          </Column>
          <Column lg={3}>
            <label htmlFor="loyalPointsMultiplier">
              Loyalty Points Multiplier
            </label>
          </Column>
          <Column lg={4}>
            <NumberInput
              id="loyaltyPointsMultiplier"
              name="loyaltyPointsMultiplier"
              value={formData.loyaltyPointsMultiplier}
              helperText="Enter value."
              onChange={(event) =>
                changeHandler(event.target.name, event.target.value)
              }
              hideSteppers
              invalidText="Points"
            />
          </Column>
        </Row>
        <Row>
          <Column lg={3}>
            <label htmlFor="discountPercentage">Discount Percentage</label>
          </Column>
          <Column lg={4}>
            <NumberInput
              id="discountPercentage"
              name="discountPercentage"
              value={formData.discountPercentage}
              helperText="Enter value."
              onChange={(event) =>
                changeHandler(event.target.name, event.target.value)
              }
              hideSteppers
              invalidText="Points"
            />
          </Column>
        </Row>
        <Row>
          <Accordion>
            <AccordionItem title="Choose Products">
              <Row>
                <Column lg={3}>
                  <label htmlFor="products">Product Id</label>
                </Column>
                <Column lg={4}>
                  {products ? (
                    <Dropdown
                      id="products"
                      label="--Select--"
                      items={products?.result.map((item) => ({
                        id: item.id,
                        text: item.productName,
                      }))}
                      itemToString={(item) => (item ? item.text : '')}
                      onChange={(event) =>
                        handleProductSelect(event.selectedItem.id)
                      }
                      // size="sm"
                      style={{ width: '250px' }}
                    />
                  ) : (
                    <Loading />
                  )}
                </Column>
              </Row>
              <Row>{renderProductFields()}</Row>
            </AccordionItem>
          </Accordion>
        </Row>
      </form>
      {!modalState?.isLoading ? (
        <Modal
          size="xs"
          open={modalState?.open}
          onRequestClose={handleModalClose}
          modalHeading="Are you sure you want to delete this membership?"
          modalLabel="Note: This step is irreversible."
          primaryButtonText={'Accept'}
          onRequestSubmit={handleDelete}
          secondaryButtons={[
            {
              buttonText: 'Cancel',
              onClick: () => {
                setModalState((state) => {
                  return { ...state, open: false };
                });
              },
            },
          ]}
        />
      ) : (
        <Loading />
      )}
    </Wrapper>
  );
};

export default MembershipAdd;
