/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  TableCell,
} from '@mui/material';

import CustomTableRow from '../CustomTableRow/CustomTableRow';
import CustomButton from '../CustomButton/CustomButton';
import CustomSearchBar from '../../CustomSearchBar/CustomSearchBar';
import CustomLoader from '../CustomLoader/CustomLoader';
import Filters from '../../Filters/Filters';
import EmptyImage from '../../../Assets/img/empty.svg';
import { ROW_PER_PAGE_OPTIONS } from '../../../Constants/constants';
import { urlservice } from '../../../Services/urlService';
import { SOMETHING_WENT_WRONG } from '../../../Constants/errorMessages';
import { StyledTableCell, StyledTableRow, styles } from './CustomTableStyles';
import { getStatus } from '../../../Constants/getstatus';

const CustomTable = (props) => {
  const {
    tableData,
    metaData,
    hidePagination,
    showCount,
    totalCount,
    buttonText,
    buttonClickHandler,
    dispatchFunction,
    isLoading,
    isError,
    isSuccess,
    hideSearch,
    placeholder = '',
    showFilters,
    filterOptions,
    pageHeading,
    extraButtonText,
    extraButtonHandler,
    sendEmail,
    setPagination,
    paginationTwo,
    setPaginationTwo,
    description,
  } = props;
  const [tableQueryParams, setTableQueryParams] = useState(() =>
    urlservice.getTableQueryParams(hidePagination, hideSearch)
  );
  useEffect(() => {
    !!setPagination && setPagination(tableQueryParams);
  }, [tableQueryParams]);

  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setTableQueryParams((prev) => {
      return {
        ...prev,
        page: newPage + 1,
      };
    });
  };
  const handleChangePageTwo = (event, newPage) => {
    setPaginationTwo((prev) => {
      return {
        ...prev,
        page: newPage + 1,
      };
    });
  };

  const getPaginationCount = () => {
    return Math.ceil(totalCount / +tableQueryParams.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setTableQueryParams((prev) => {
      return {
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1,
      };
    });
  };
  const handleChangeRowsPerPageTwo = (event) => {
    setPaginationTwo((prev) => {
      return {
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1,
      };
    });
  };

  const handleChangeSearchValue = (value) => {
    setTableQueryParams((prev) => {
      return {
        ...prev,
        searchValue: value,
      };
    });
  };

  const getFilterParams = () => {
    let filters = {};
    let newFilters = {};

    if (tableQueryParams?.status) {
      const val = getStatus(tableQueryParams?.status);
      newFilters = {
        ...filters,
        ...(val !== null && {
          filter: JSON.stringify({
            is_active: val,
          }),
        }),
      };
    }
    filters = { ...newFilters };
    return filters;
  };

  const handleDispatch = () => {
    if (hidePagination && !showFilters) {
      dispatch(dispatchFunction());
      return;
    }
    const paginationParams = {
      keyword: tableQueryParams?.searchValue,
      limit: tableQueryParams?.rowsPerPage,
      skip: (tableQueryParams.page - 1) * tableQueryParams.rowsPerPage || 0,
    };

    if (dispatchFunction) {
      dispatch(
        dispatchFunction({
          ...(!hidePagination && paginationParams),
          ...(showFilters && getFilterParams()),
        })
      );
    }
  };

  useEffect(() => {
    const newTableParams = { ...tableQueryParams };
    if (dispatchFunction) {
      handleDispatch();
    }
    urlservice.setQueryParameters({ ...newTableParams });
  }, [tableQueryParams]);

  useEffect(() => {
    if (totalCount) {
      if (+tableQueryParams.page > getPaginationCount()) {
        setTableQueryParams((prev) => {
          return { ...prev, page: 1 };
        });
      }
    }
  }, [totalCount]);

  const setTableQuery = (itemToSet) => {
    setTableQueryParams((prev) => {
      return {
        ...prev,
        [itemToSet?.urlName]: '',
      };
    });
  };

  const displayNoData = () => {
    if (isLoading) {
      return <CustomLoader />;
    } else if (isError) {
      return (
        <Box>
          <Typography sx={styles.errorStyle}>{SOMETHING_WENT_WRONG}</Typography>
        </Box>
      );
    } else if (totalCount === 0) {
      return (
        <Box>
          <Typography component="img" src={EmptyImage} />
          <Typography
            sx={styles.imageStyleText}
          >{`It's empty in here`}</Typography>
        </Box>
      );
    }
    return null;
  };

  useEffect(() => {
    if (showFilters) {
      filterOptions?.forEach((item) => {
        if (tableQueryParams?.[item?.urlName]) {
          if (item?.filterType === 'radio') {
            const currentVal = tableQueryParams?.[item?.urlName];
            if (
              !item?.options?.find(
                (val) => val?.[item?.mappingId] === +currentVal
              )
            ) {
              setTableQuery(item);
            }
          } else if (item?.filterType === 'checkbox') {
            let currentVal = tableQueryParams?.[item?.urlName]
              ? tableQueryParams?.[item?.urlName]?.split(',')
              : [];
            if (currentVal?.length) {
              currentVal = currentVal?.map((val) => +val);
            }
            const optionsIds = item?.options?.map((val) => val?.id);
            const containsAll = currentVal?.every((val) => {
              return optionsIds?.includes(val);
            });
            if (!containsAll) {
              setTableQuery(item);
            }
          }
        }
      });
    }
  }, [showFilters, filterOptions, tableQueryParams]);

  return (
    <Box sx={styles.mainContainer}>
      {pageHeading && (
        <Box sx={styles.mainHeader}>
          <Typography sx={styles.mainHeaderStyle}>{pageHeading}</Typography>
          {!!description && (
            <Typography sx={styles.description}>
              {
                <>
                  <b>Description: </b>
                  {description}
                </>
              }
            </Typography>
          )}
        </Box>
      )}
      <Box sx={styles.headerBoxContainer}>
        <Box>
          {showCount && (
            <Box sx={styles.recordCountStyle}>
              <Typography>{`${
                totalCount >= 0 ? totalCount : 'No'
              } records found`}</Typography>
            </Box>
          )}
        </Box>
        <Box sx={styles.rightHeaderBox}>
          {!hideSearch && (
            <CustomSearchBar
              placeholder={placeholder}
              initialValue={tableQueryParams?.searchValue || ''}
              onSearch={handleChangeSearchValue}
              showSearchIcon
            />
          )}
          {showFilters && (
            <Filters
              tableQueryParams={tableQueryParams}
              setTableQueryParams={setTableQueryParams}
              filterOptions={filterOptions}
            />
          )}
          {sendEmail && (
            <CustomButton
              type="cancel"
              textContent="Start promotion"
              clickHandler={sendEmail}
            />
          )}
          {extraButtonText && (
            <CustomButton
              type="cancel"
              textContent={extraButtonText}
              clickHandler={extraButtonHandler}
            />
          )}
          {buttonText && (
            <CustomButton
              type="save"
              textContent={buttonText}
              clickHandler={buttonClickHandler}
            />
          )}
        </Box>
      </Box>
      <Paper sx={styles.tableMain}>
        <TableContainer sx={styles.tableStyle} component={Paper}>
          <Table sx={styles.tableMainStyle} stickyHeader>
            <TableHead>
              <StyledTableRow>
                {metaData?.map((item) => (
                  <StyledTableCell
                    align={item?.align || 'left'}
                    key={item?.keyId}
                    width={item?.width}
                  >
                    <Typography>{item?.headingName}</Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody
              sx={{
                ...((isLoading || isError || !+totalCount) &&
                  styles.loaderContainer),
              }}
            >
              {(isLoading || isError || totalCount === 0) && (
                <TableRow sx={{ position: 'relative' }}>
                  <TableCell
                    colSpan={metaData?.length}
                    sx={{ position: 'relative' }}
                  >
                    <Box
                      sx={{
                        ...styles.loaderErrorContainer,
                        ...(!(isLoading || isError) &&
                          !+totalCount &&
                          styles.imageLoaderError),
                      }}
                    >
                      {displayNoData()}
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {isSuccess &&
                tableData?.map((item) => (
                  <CustomTableRow
                    metaData={metaData}
                    tableRowData={item}
                    tableData={tableData}
                    selected={selected}
                    setSelected={setSelected}
                    handleDispatch={handleDispatch}
                    key={item[metaData?.[0]?.mappingId]}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!hidePagination && +totalCount ? (
          !!setPaginationTwo ? (
            <TablePagination
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              component="div"
              count={totalCount}
              rowsPerPage={paginationTwo?.rowsPerPage}
              page={+paginationTwo.page - 1}
              onPageChange={handleChangePageTwo}
              onRowsPerPageChange={handleChangeRowsPerPageTwo}
            />
          ) : (
            <TablePagination
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              component="div"
              count={totalCount}
              rowsPerPage={tableQueryParams?.rowsPerPage}
              page={+tableQueryParams.page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )
        ) : null}
      </Paper>
    </Box>
  );
};

export default CustomTable;
