import { Navigate, Outlet } from 'react-router-dom';

import { getToken } from '../Auth/getToken';
import { DASHBOARD } from './Routes';

const PublicRoute = () => {
  const isUserLoggedIn = getToken();
  return isUserLoggedIn ? <Navigate to={DASHBOARD} /> : <Outlet />;
};

export default PublicRoute;
