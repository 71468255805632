import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axiosInstance from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CustomTable from '../../Components/Widgets/CustomTable/CustomTable';
import { USP_PROMOTIONS_ADD, USP_PROMOTIONS_EDIT } from '../../Routes/Routes';
import { useNavigate } from 'react-router-dom';
import { editUSPPromotions } from '../../Services/promotionsService';

const USPPromotion = () => {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [blean, setBlean] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    setIsSuccess(false);
    if (pagination?.page && pagination?.rowsPerPage) {
      axiosInstance
        .get(
          `/usp/promotion/list-promotions?limit=${
            pagination?.rowsPerPage
          }&skip=${(pagination?.page - 1) * pagination?.rowsPerPage}`
        )
        .then((res) => {
          setLoading(false);
          setPromotions(res?.data?.data);

          if (res?.data?.data?.totalCount > 0) {
            setIsSuccess(true);
          } else {
            setIsSuccess(false);
          }
        });
    }
  }, [pagination, blean]);

  const addBtnNavigation = () => {
    navigate(USP_PROMOTIONS_ADD);
  };

  const handleEdit = (e) => {
    navigate(USP_PROMOTIONS_EDIT, {
      state: promotions?.result?.find((data) => data?.promotionId === e),
    });
  };

  const metaData = [
    {
      keyId: 1,
      mappingId: 'name',
      mappingKey: 'name',
      headingName: 'Name',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 2,
      mappingId: 'created',
      mappingKey: 'created',
      headingName: 'Created Date',
      colType: 'date',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 3,
      mappingId: 'minTotal',
      mappingKey: 'minTotal',
      headingName: 'Minimum Total',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 4,
      mappingId: 'maxTotal',
      mappingKey: 'maxTotal',
      headingName: 'Maximum Total',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 5,
      mappingId: 'promotionId',
      mappingKey: 'isActive',
      headingName: 'Status',
      colType: 'status',
      width: '5%',
      align: 'left',
      confirmationModalDetail: {
        title: 'Change Status',
        inActiveDescription:
          'Are you sure you want to make the Promotion inactive?',
        activeDescription:
          'Are you sure you want to make the Promotion active?',
      },
      handler: {
        onClick: async (id, tableData) => {
          return editUSPPromotions(
            id,
            {
              basicInfo: {
                isActive: !tableData?.isActive,
              },
            },
            blean,
            setBlean
          );
        },
      },
    },
    {
      keyId: 6,
      mappingId: 'promotionId',
      headingName: 'Edit',
      colType: 'edit',
      width: '5%',
      align: 'center',
      handler: {
        onClick: (e) => handleEdit(e),
      },
    },
  ];

  return (
    <Box sx={commonStyles.mainContainer}>
      <CustomTable
        tableData={promotions?.result}
        metaData={metaData}
        hideSearch
        isSuccess={isSuccess}
        showCount
        isLoading={loading}
        totalCount={promotions.totalCount || 0}
        pageHeading="USP Promotions"
        buttonText="Add Promotion"
        setPagination={setPagination}
        buttonClickHandler={addBtnNavigation}
      />
    </Box>
  );
};

export default USPPromotion;
