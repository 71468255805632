import { useState } from 'react';
import { Box } from '@mui/material';

import useDebounceEffect from '../../Hooks/useDebounceEffect';
import CustomInput from '../Widgets/CustomInput/CustomInput';
import CustomToolTip from '../CustomToolTip/CustomToolTip';
import { DEBOUNCE_TIME } from '../../Constants/constants';
import { styles } from './CustomSearchBarStyles';

const CustomSearchBar = (props) => {
  const { placeholder, onSearch, initialValue } = props;
  const [value, setValue] = useState(initialValue || '');

  const onChangeSearch = (name, e) => {
    setValue(e);
  };

  useDebounceEffect(
    () => {
      onSearch(value);
    },
    DEBOUNCE_TIME,
    [value]
  );

  const onIconClick = () => {
    onSearch(value);
  };

  const onClearClick = () => {
    setValue('');
  };

  return (
    <CustomToolTip title={placeholder}>
      <Box sx={styles.searchContainer}>
        <CustomInput
          placeholder={placeholder}
          customStyles={styles.searchInput}
          showSearchIcon={!value}
          showClearIcon={Boolean(value?.length)}
          value={value}
          setValue={onChangeSearch}
          onChange={onChangeSearch}
          onSearchClick={onIconClick}
          onClearClick={onClearClick}
          hideMessage
          name="search"
          maxLength={40}
        />
      </Box>
    </CustomToolTip>
  );
};
export default CustomSearchBar;
