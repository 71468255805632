import {
  DASHBOARD,
  GIFTBIT_SETTINGS,
  LOYALTY_CAMPAIGN,
  CUSTOMER_LIST,
  LOYALTY_ITEM,
  PROMOTION,
  PROMOTIONS_SETTINGS,
  PROMOTION_CATEGORY,
  PROMOTION_FREE_ITEM_SETTINGS,
  SETTINGS,
  DONATION_SETTINGS,
  USP_SETTINGS,
  COUPON,
  COUPON_TIER,
  CREATE_REDEMPTION,
  USP_PROMOTIONS,
  CONFIGURATION_SETTINGS,
  MARKETING_SETTINGS,
  GIFT_CARD_REDEMPTION,
  MEMBERSHIP,
  LOYALTY_ITEM_CATEGORY,
  MARKETPLACE_MEMBERSHIP,
  SALES_AGENTS,
  SALES_CAMPAIGNS,
} from '../Routes/Routes';
import DashboardIcon from '../Assets/img/dashboard.svg';
import PromotionCategoryIcon from '../Assets/img/category-new-each.svg';
import PromotionIcon from '../Assets/img/carbon-for-ibm-product (1).svg';
import LoyaltyFreeItemIcon from '../Assets/img/gift (1).svg';
import LoyaltyCustomerListIcon from '../Assets/img/events (1).svg';
import SettingsIcon from '../Assets/img/settings (1).svg';
import LoyaltyCampaignIcon from '../Assets/img/flag (1).svg';

export const sidebarData = [
  {
    id: 1,
    name: 'Dashboard',
    link: DASHBOARD,
    icon: DashboardIcon,
  },
  {
    id: 2,
    name: 'Promotion Category',
    link: PROMOTION_CATEGORY,
    icon: PromotionCategoryIcon,
  },
  {
    id: 3,
    name: 'Promotions',
    link: PROMOTION,
    icon: PromotionIcon,
  },
  // {
  //   id: 4,
  //   name: 'USP Promotions',
  //   link: USP_PROMOTIONS,
  //   icon: PromotionIcon,
  // },
  {
    id: 5,
    name: 'Discount Tier',
    link: COUPON_TIER,
    icon: PromotionIcon,
  },
  {
    id: 6,
    name: 'Discount',
    link: COUPON,
    icon: PromotionIcon,
  },
  {
    id: 7,
    name: 'Loyalty Campaign',
    link: LOYALTY_CAMPAIGN,
    icon: LoyaltyCampaignIcon,
  },
  // {
  //   id: 83,
  //   name: 'Loyalty Free Item Category',
  //   link: LOYALTY_ITEM_CATEGORY,
  //   icon: LoyaltyFreeItemIcon,
  // },
  // {
  //   id: 8,
  //   name: 'Loyalty Free Item',
  //   link: LOYALTY_ITEM,
  //   icon: LoyaltyFreeItemIcon,
  // },
  {
    id: 9,
    name: 'Gift Card Redemption',
    link: GIFT_CARD_REDEMPTION,
    icon: LoyaltyFreeItemIcon,
  },
  {
    id: 10,
    name: 'Membership',
    link: MEMBERSHIP,
    icon: LoyaltyFreeItemIcon,
  },
  //  new tab(marketplace_membership) json data
  {
    id: 11,
    name: 'Marketplace membership',
    link: MARKETPLACE_MEMBERSHIP,
    icon: LoyaltyCustomerListIcon,
  },
  // id changed below after addition
  {
    id: 12,
    name: 'Customer List',
    link: CUSTOMER_LIST,
    icon: LoyaltyCustomerListIcon,
  },
  {
    id: 13,
    name: 'Sales Agents',
    link: SALES_AGENTS,
    icon: LoyaltyCustomerListIcon,
  },
  {
    id: 14,
    name: 'Sales Campaigns',
    link: SALES_CAMPAIGNS,
    icon: LoyaltyCustomerListIcon,
  },
  // id changed below after addition
  {
    id: 15,
    name: 'Settings',
    link: SETTINGS,
    icon: SettingsIcon,
  },
];

export const settingsSubListData = [
  { id: 's1', name: 'Promotions', link: PROMOTIONS_SETTINGS },
  { id: 's2', name: 'Promotion Free Item', link: PROMOTION_FREE_ITEM_SETTINGS },
  { id: 's3', name: 'GiftBit', link: GIFTBIT_SETTINGS },
  { id: 's4', name: 'Configuration', link: CONFIGURATION_SETTINGS },
  { id: 's5', name: 'Donation', link: DONATION_SETTINGS },
  // { id: 's6', name: 'USP', link: USP_SETTINGS },
  // { id: 's7', name: 'Marketing', link: MARKETING_SETTINGS },
  { id: 's8', name: 'Discount Redemption Limit', link: CREATE_REDEMPTION },
];
