import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ProductService from './ProductService';

const initialState = {
  productListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getProductList = createAsyncThunk(
  'product/list',
  async (data, thunkAPI) => {
    try {
      return await ProductService.getProductList(data);
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetDetail: (state) => {
      state.productListData.isLoading = false;
      state.productListData.isError = false;
      state.productListData.isSuccess = false;
      state.productListData.data = null;
      state.productListData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state) => {
        state.productListData.isLoading = true;
        state.productListData.isError = false;
        state.productListData.isSuccess = false;
        state.productListData.data = null;
        state.productListData.message = '';
      })
      .addCase(getProductList.rejected, (state, action) => {
        state.productListData.isLoading = false;
        state.productListData.isError = true;
        state.productListData.isSuccess = false;
        state.productListData.data = null;
        state.productListData.message = action.payload;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.productListData.isLoading = false;
        state.productListData.isError = false;
        state.productListData.isSuccess = true;
        state.productListData.data = action.payload;
        state.productListData.message = '';
      });
  },
});

export const { resetDetail } = productSlice.actions;
export default productSlice.reducer;
