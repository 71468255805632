import { Typography } from '@mui/material';

import { styles } from './CustomLabelStyles';

const CustomLabel = (props) => {
  const { customStyles = {}, labelText, isRequired } = props;

  return (
    <Typography
      sx={{
        ...styles.label,
        ...(!isRequired && styles.labelWithoutRequired),
        ...customStyles,
      }}
    >
      {labelText}
      {isRequired && (
        <Typography sx={styles.superset} component="span">
          *
        </Typography>
      )}
    </Typography>
  );
};

export default CustomLabel;
