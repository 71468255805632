import { ArrowLeft, Delete, TrashCan } from '@carbon/icons-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  NumberInput,
  Row,
  TextInput,
  Column,
  Modal,
  Loading,
  Accordion,
  AccordionItem,
  Select,
  SelectItem,
} from '@carbon/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MARKETPLACE_MEMBERSHIP } from '../../Routes/Routes';
import { validationHandler } from '../../Utils/validations';
import axiosInstance from '../../API/axiosInstance';
import { toast } from 'react-toastify';

const Wrapper = styled.main`
  padding: 2rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      gap: 1.5rem;
      align-items: center;
      display: flex;
      button.backButton {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        align-items: center;
        display: flex;
      }
      h4 {
      }
    }
    .button-set {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    label {
      &::after {
        content: '*';
        color: #9f3333;
      }
    }
  }
`;
const SelectMenuStyle = styled.div`
  .carbon-select__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1; /* Ensure the dropdown appears above other content */
  }
`;

const MarketplaceMembershipAdd = ({ type }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
    name: null,
    membershipCode: null,
    freeItems: [], // Array to store selected products
  });
  const [modalState, setModalState] = useState({
    open: false,
    isLoading: false,
  });
  const [products, setProducts] = useState(null);
  // const [selectedProductId, setSelectedProductId] = useState(null);

  const handleProductSelect = (productId) => {
    // Find the selected product from the products list
    const productExists = formData.freeItems.some(
      (item) => item.productId == productId
    );
    // Check if the selected product exists
    if (!productExists) {
      const selectedProduct = products.result.find(
        (item) => item.id == productId
      );
      // Construct the product object with required data
      const newProduct = {
        productId: selectedProduct.id,
        productName: selectedProduct.productName,
        qty: 0,
        expirationDays: 0,
        optionalLoyaltyPoints: 0,
      };

      // Update the formData state to include the new product
      setFormData((prevState) => ({
        ...prevState,
        freeItems: [...prevState.freeItems, newProduct],
      }));
    }
  };

  const handleFieldChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedItems = prevData.freeItems.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value };
        }
        return item;
      });
      return { ...prevData, freeItems: updatedItems };
    });
  };

  const handleDel = (index) => {
    setFormData((prevData) => {
      // Create a new array without the item at the specified index
      const updatedFreeItems = prevData.freeItems.filter(
        (item, i) => i !== index
      );
      // Update the formData state with the new array
      return {
        ...prevData,
        freeItems: updatedFreeItems,
      };
    });
  };

  const fetchMembershipData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}marketplace/membership/${params.id}`
      );
      setFormData((prevState) => {
        return {
          ...prevState,
          name: response?.data?.data?.name,
          membershipCode: response?.data?.data?.membership_code,
          freeItems: response?.data?.data?.freeitems.map((item) => ({
            productId: item.id,
            qty: item.productQty,
            expirationDays: item.expirationDays,
            optionalLoyaltyPoints: item.optionalLoyaltyPoints,
          })),
          //   prevImageId: response?.data?.data?.imageId,
        };
      });
      console.log(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      fetchMembershipData();
    }
  }, []);

  const renderProductFields = () => {
    if (!products) {
      return <Loading />; // Render loading indicator while products are being fetched
    }
    return formData.freeItems.map((product, index) => (
      <div key={index}>
        <Row>
          <Column lg={3}>
            <TextInput
              value={
                products.result.find((p) => p.id === product.productId)
                  ?.productName || ''
              }
              placeholder="ProductName"
              labelText="Product"
              disabled
            />
          </Column>
          <Column>
            <TextInput
              value={product.qty}
              onChange={(event) =>
                handleFieldChange(index, 'qty', event.target.value)
              }
              placeholder="Quantity"
              labelText="Quantity"
            />
          </Column>
          <Column lg={3}>
            <TextInput
              value={product.expirationDays}
              onChange={(event) =>
                handleFieldChange(index, 'expirationDays', event.target.value)
              }
              placeholder="Expiration Days"
              labelText="Expiration Days"
            />
          </Column>

          <Column lg={4}>
            <TextInput
              value={product.optionalLoyaltyPoints}
              onChange={(event) =>
                handleFieldChange(
                  index,
                  'optionalLoyaltyPoints',
                  event.target.value
                )
              }
              placeholder="Optional Loyalty Points"
              labelText="Optional Loyalty Points"
            />
          </Column>
          <Column lg={3}>
            <Button
              hasIconOnly
              renderIcon={TrashCan}
              iconDescription="Delete"
              onClick={() => handleDel(index)}
            />
          </Column>
        </Row>
      </div>
    ));
  };

  useEffect(() => {
    const fetchProductList = async () => {
      try {
        const response = await axiosInstance.get('all-products');
        setProducts(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchProductList();
  }, []);

  const handleFormSubmit = () => {
    if (type === 'add') {
      // Convert string values to integers for numeric fields
      const formDataWithIntegers = {
        ...formData,
        freeItems: formData.freeItems.map((item) => ({
          productId: item.productId,
          qty: parseInt(item.qty), // Parse string to integer
          expirationDays: parseInt(item.expirationDays), // Parse string to integer
          optionalLoyaltyPoints: parseInt(item.optionalLoyaltyPoints), // Parse string to integer
        })),
      };

      axiosInstance
        .post(`${process.env.REACT_APP_API_BASE_URL}marketplace/membership`, {
          name: formDataWithIntegers.name,
          membershipCode: formDataWithIntegers.membershipCode,
          freeItems: formDataWithIntegers.freeItems,
        })
        .then((response) => {
          navigate(MARKETPLACE_MEMBERSHIP);
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    } else {
      axiosInstance
        .put(
          `${process.env.REACT_APP_API_BASE_URL}marketplace/membership/${params.id}`,
          {
            name: formData.name,
            // membershipCode: formData.membershipCode,
            freeItems: formData.freeItems.map((item) => ({
              productId: item.productId, // Use productId from freeItems
              qty: parseInt(item.qty),
              expirationDays: parseInt(item.expirationDays),
              optionalLoyaltyPoints: parseInt(item.optionalLoyaltyPoints),
            })),
          }
        )
        .then((response) => {
          navigate(MARKETPLACE_MEMBERSHIP);
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <div className="left">
          <Link to={MARKETPLACE_MEMBERSHIP}>
            <button className="backButton">
              <ArrowLeft size={24} />
            </button>
          </Link>
          <h4>{type === 'add' ? 'Add' : 'Edit'} Marketplace Membership</h4>
        </div>
        <div className="button-set">
          <Button onClick={handleFormSubmit}>Submit</Button>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <Column lg={3}>
            <label htmlFor="membershipname">Name</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.name}
              id="membershipname"
              name="name"
              type="text"
              helperText="Enter the Membership Name."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  name: event.target.value,
                }))
              }
            />
          </Column>
          <Column lg={3}>
            <label htmlFor="membershipCode">Membership Code</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.membershipCode}
              id="membershipCode"
              name="membershipCode"
              type="text"
              helperText="Enter the Membership Code."
              onChange={(event) =>
                setFormData((prevData) => ({
                  ...prevData,
                  membershipCode: event.target.value,
                }))
              }
            />
          </Column>
        </Row>
        <Accordion>
          <AccordionItem title="Choose Products">
            <Row>
              <Column lg={3}>
                <label htmlFor="products">Product Id</label>
              </Column>
              <Column lg={4}>
                {products ? (
                  <Select
                    id="products"
                    onChange={(event) =>
                      handleProductSelect(event.target.value)
                    }
                    hideLabel={true}
                    size="sm"
                  >
                    {products.result.map((item) => (
                      <SelectItem
                        key={item.id}
                        value={item.id}
                        text={item.productName}
                      />
                    ))}
                  </Select>
                ) : (
                  <Loading />
                )}
              </Column>
            </Row>
            <Row>{renderProductFields()}</Row>
          </AccordionItem>
        </Accordion>
      </form>
    </Wrapper>
  );
};

export default MarketplaceMembershipAdd;
