import {
  Button,
  Column,
  DataTable,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TextInput,
} from '@carbon/react';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  LOYALTY_ITEM_CATEGORY,
  LOYALTY_ITEM_CATEGORY_ADD,
} from '../../Routes/Routes';
import { ArrowLeft, Edit } from '@carbon/icons-react';
import axiosInstance from '../../API/axiosInstance';
import { toast } from 'react-toastify';
import NoDataInTable from '../../Components/NoDataTable/NoDataInTable';
const Wrapper = styled.main`
  padding: 2rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      gap: 1.5rem;
      align-items: center;
      display: flex;
      button.backButton {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        align-items: center;
        display: flex;
      }
      h4 {
      }
    }
    .button-set {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  form {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    label {
      &::after {
        content: '*';
        color: #9f3333;
      }
    }
  }
`;
const HomeWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
  }
`;
const tableHeaders = [
  {
    key: 'id',
    header: 'Id',
    type: 'number',
  },
  {
    key: 'name',
    header: 'Name',
    type: 'string',
  },
  {
    key: 'id',
    header: 'Edit',
    type: 'button',
  },
];
const LoyaltyItemCategory = ({ type }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (type === 'home') {
      axiosInstance
        .get(`${process.env.REACT_APP_API_BASE_URL}category/loyalty-free-item`)
        .then((response) => {
          setData(response.data.data);
        });
    }
  }, [type]);
  useEffect(() => {
    setFormData({ name: '' });
    if (type === 'edit') {
      axiosInstance
        .get(
          `${process.env.REACT_APP_API_BASE_URL}category/loyalty-free-item/${params.id}`
        )
        .then((response) => {
          setFormData({ name: response.data.data?.name });
        });
    }
  }, [type]);

  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '' });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (type === 'add') {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_BASE_URL}category/loyalty-free-item`,
          {
            name: formData.name,
          }
        )
        .then((response) => {
          navigate(LOYALTY_ITEM_CATEGORY);
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    } else {
      axiosInstance
        .put(
          `${process.env.REACT_APP_API_BASE_URL}category/loyalty-free-item/${params.id}`,
          {
            name: formData.name,
          }
        )
        .then((response) => {
          navigate(LOYALTY_ITEM_CATEGORY);
          toast.success(response.data.message);
        })
        .catch((errorData) => {
          console.log(errorData);
          toast.error(errorData?.response?.data?.message);
        });
    }
  };

  return type === 'home' ? (
    <HomeWrapper>
      <div className="header">
        <h4>Loyalty Category</h4>
        <Button as={Link} to={LOYALTY_ITEM_CATEGORY_ADD} size="md">
          Add New Loyalty Category
        </Button>
      </div>

      <DataTable
        useZebraStyles
        experimentalAutoAlign
        overflowMenuOnHover={false}
        headers={tableHeaders}
        rows={data || []}
        isSortable
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }) => {
          return (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          colSpan={header.colSpan}
                          key={header.key}
                          {...getHeaderProps({ header })}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => {
                    return (
                      <TableRow key={row.id} {...getRowProps({ row })}>
                        {row.cells.map((cell, index) => {
                          return (
                            <TableCell
                              key={cell.id}
                              {...getTableContainerProps({ cell })}
                            >
                              {tableHeaders[index]?.type === 'button' ? (
                                <Button
                                  as={Link}
                                  to={`/loyalty-item-category/edit/${cell.value}`}
                                  hasIconOnly
                                  iconDescription="Edit"
                                  size="sm"
                                  value={cell.value}
                                  renderIcon={() => <Edit size={16} />}
                                />
                              ) : (
                                cell.value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!data?.length && <NoDataInTable background={'#f4f4f4'} />}
            </TableContainer>
          );
        }}
      </DataTable>
    </HomeWrapper>
  ) : (
    <Wrapper>
      <div className="header">
        <div className="left">
          <Link to={LOYALTY_ITEM_CATEGORY}>
            <button className="backButton">
              <ArrowLeft size={24} />
            </button>
          </Link>
          <h4>{type === 'add' ? 'Add' : 'Edit'} Loyalty Category</h4>
        </div>
        <div className="button-set">
          <Button onClick={handleFormSubmit} type="submit">
            Submit
          </Button>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <Row>
          <Column lg={3}>
            <label htmlFor="name">Name</label>
          </Column>
          <Column lg={4}>
            <TextInput
              value={formData?.name}
              id="name"
              name="name"
              type="text"
              helperText="Enter the Category Name."
              onChange={(event) =>
                setFormData({ ...formData, name: event.target.value })
              }
            />
          </Column>
        </Row>
      </form>
    </Wrapper>
  );
};

export default LoyaltyItemCategory;
