import * as _ from 'lodash';
import {
  IS_NUMBER_GREATER_THAN_VALIDATION,
  IS_NUMBER_SMALLER_THAN_VALIDATION,
  IS_REQUIRED_STRING_VALIDATION,
  IS_REQUIRED_VALIDATION,
  IS_STRING_GREATER_THAN_VALIDATION,
  IS_ARRAY_EMPTY_VALIDATION,
  IS_ID_REQUIRED_VALIDATION,
  IS_ARRAY_EMPTY_ON_DEPENDENCY_VALIDATION,
} from '../Constants/validationConstant';

export const isGreaterThan = (value1, value2) => {
  return parseFloat(value1) < parseFloat(value2);
};
export const isSmallerThan = (value1, value2) => {
  return parseFloat(value1) > parseFloat(value2);
};
export const isNumberNegative = (value1) => {
  return parseFloat(value1) < 0;
};
export const isStringGreaterThan = (value1, minLength) => {
  return value1.trim().length < minLength;
};
export const isRequiredString = (value) => {
  return value.trim().length <= 0;
};
export const isRequired = (value) => {
  return (
    value === 'undefined' || value === null || value === ''
    // || value === []
  );
};
export const isIdRequired = (value, mappingId) => {
  return (
    _.isEmpty(value) ||
    value?.[mappingId] === 'undefined' ||
    value?.[mappingId] === null
  );
};
export const isArrayEmpty = (value) => {
  return value.length === 0;
};
export const isArrayEmptyOnDependency = (arr, value) => {
  if (value && arr.length) {
    return false;
  } else if (!value) {
    return false;
  }
  return true;
};

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
const functionHandler = (key, data) => {
  switch (key) {
    case IS_REQUIRED_STRING_VALIDATION:
      return isRequiredString(data.value);
    case IS_REQUIRED_VALIDATION:
      return isRequired(data.value);
    case IS_STRING_GREATER_THAN_VALIDATION:
      return isStringGreaterThan(data.value, data.config.minValue);
    case IS_NUMBER_SMALLER_THAN_VALIDATION:
      return isSmallerThan(data.value, data.config.maxValue);
    case IS_NUMBER_GREATER_THAN_VALIDATION:
      return isGreaterThan(data.value, data.config.minValue);
    case IS_ARRAY_EMPTY_VALIDATION:
      return isArrayEmpty(data.value);
    case IS_ID_REQUIRED_VALIDATION:
      return isIdRequired(data.value, data.config.mappingId);
    case IS_ARRAY_EMPTY_ON_DEPENDENCY_VALIDATION:
      return isArrayEmptyOnDependency(
        data.config.allowedValue,
        data.config.hasValues
      );
    default:
      return null;
  }
};

export const validationHandler = (formData, setError, config) => {
  let newErrorData = {};
  let isFormValid = true;
  Object.entries(config).forEach(([fieldName, fun]) => {
    Object.entries(fun).every(([key, value]) => {
      const validationFunction = functionHandler(key, {
        value: formData[fieldName],
        config: value,
      });
      if (validationFunction) {
        newErrorData = { ...newErrorData, [fieldName]: value.errorMsg };
        isFormValid = false;
        return false;
      }
      newErrorData = { ...newErrorData, [fieldName]: '' };
      return true;
    });
  });
  setError((prevState) => {
    return { ...prevState, ...newErrorData };
  });
  return isFormValid;
};
