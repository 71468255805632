import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import CustomLoader from '../CustomLoader/CustomLoader';
import { DEFAULT_ROW_PER_PAGE } from '../../../Constants/constants';
import { BootstrapInput, styles } from './CustomSelectStyles';

const CustomSelect = (props) => {
  const {
    name,
    value,
    setValue,
    data,
    mappingId,
    mappingName,
    emptyDataMessage = 'No Data',
    placeholder = 'Select',
    isLoading = false,
    isError = false,
    dispatchFunction,
    totalCount,
    isRequired,
    hideMessage,
    inputInfo,
    errorMsg,
    isInfiniteScroll,
    isDisabled,
    showSearch,
    filterObject,
    hideRenderValue,
    disabledIds,
    customStyles,
    id,
    multiSelect,
    multiSelectData,
    setMultiSelectData,
  } = props;

  const [searchedText, setSearchedText] = useState('');
  const [hasMoreData, setHasMoreData] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [internalData, setInternalData] = useState([]);

  const dispatch = useDispatch();
  const getData = () => {
    if (isInfiniteScroll) return internalData;
    else return data;
  };

  const handleSelect = (event) => {
    multiSelect;
    const {
      target: { value: selectedValue },
    } = event;
    if (name) {
      setValue(name, selectedValue);
    } else {
      setValue(selectedValue);
    }
  };

  const onChangeSearch = (e) => {
    const newSearchValue = e.target.value;
    setSearchedText(newSearchValue);
  };

  const fetchListOnScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 5;
    if (bottom && hasMoreData && !isLoading) {
      setPageNumber((prev) => prev + 1);
      if (dispatchFunction) {
        dispatch(
          dispatchFunction({
            keyword: searchedText || '',
           
           
          })
        );
      }
      e.target.scrollTop -= 100;
    }
  };
  console.log(searchedText , "wa")

  useEffect(() => {
    setPageNumber(1);
    setInternalData([]);
    if (dispatchFunction) {
      dispatch(
        !isInfiniteScroll
          ? dispatchFunction()
          : dispatchFunction({
              keyword: searchedText || '',
              limit: DEFAULT_ROW_PER_PAGE,
              skip: 0,
              ...(filterObject && {
                filter: JSON.stringify(filterObject),
              }),
            })
      );
    }
  }, [dispatch, dispatchFunction, searchedText]);
  useEffect(() => {
    if (data) {
      if (pageNumber > 1) {
        setInternalData((prev) => [...prev, ...data]);
        if (data?.length + internalData.length < totalCount) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      } else {
        setInternalData(data);
        if (data?.length < totalCount) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    }
  }, [data]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    sx: styles.menu,
    ...(isInfiniteScroll && {
      autoFocus: false,
      PaperProps: {
        onScroll: fetchListOnScroll,
      },
    }),
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMultiSelectData({
      ...multiSelectData,
      freeGifts: typeof value === 'string' ? value.split(',') : value,
    });
  };
  return (
    <Box>
      {multiSelect ? (
        <Select
          multiple
          value={multiSelectData?.freeGifts}
          onChange={handleChange}
          renderValue={(selected) => {
            const arrayData = selected.map((res) => (
              <Chip
                sx={{ margin: '0 4px' }}
                key={res?.productName}
                label={res?.productName}
              />
            ));
            return arrayData;
          }}
          MenuProps={MenuProps}
          sx={{ ...styles.selectBox, ...customStyles }}
        >
          {showSearch && (
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                sx={styles.searchField}
                value={searchedText}
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChangeSearch}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}
          {getData()?.map((name) => {
            return (
              <MenuItem
                sx={{
                  ...styles.menuItem,
                }}
                key={name?.id}
                value={name}
              >
                <ListItemText primary={name?.productName} />
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Select
          id={id}
          name={name}
          value={value}
          onChange={handleSelect}
          required={isRequired}
          displayEmpty
          disabled={isDisabled}
          defaultValue=""
          sx={{ ...styles.selectBox, ...customStyles }}
          input={<BootstrapInput />}
          renderValue={(selected) => {
            if (!mappingName && !mappingId && selected) {
              return <Typography sx={styles.inputStyle}>{selected}</Typography>;
            } else if (!selected[mappingId] && !selected?.[mappingName]) {
              return (
                <Typography sx={styles.inputStyle}>{placeholder}</Typography>
              );
            } else {
              return (
                <Typography sx={styles.inputStyle}>
                  {!hideRenderValue ? selected?.[mappingName] : 'select'}
                </Typography>
              );
            }
          }}
          MenuProps={{
            sx: styles.menu,
            ...(isInfiniteScroll && {
              autoFocus: false,
              PaperProps: {
                onScroll: fetchListOnScroll,
              },
            }),
          }}
        >
          {showSearch && (
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                sx={styles.searchField}
                value={searchedText}
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={onChangeSearch}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}
          {!isError &&
            (getData()?.length ? (
              getData()?.map((item, idx) => (
                <MenuItem
                  key={item?.[mappingId] || idx}
                  value={item}
                  sx={{
                    ...styles.menuItem,
                    ...(item?.[mappingId] === value?.[mappingId] &&
                      styles.activeStyle),
                  }}
                  disabled={Boolean(
                    disabledIds?.find((val) => val === item?.[mappingId])
                  )}
                >
                  {mappingName ? (
                    <Typography>{item?.[mappingName]}</Typography>
                  ) : (
                    <Typography>{item}</Typography>
                  )}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <Typography>{emptyDataMessage}</Typography>
              </MenuItem>
            ))}
          {isLoading && <CustomLoader />}
        </Select>
      )}

      {!hideMessage && (
        <Box sx={styles.bottomContainer}>
          <Typography
            sx={{
              ...styles.bottomContainerMsg,
              ...(errorMsg && styles.errorMsg),
              ...(!errorMsg && inputInfo && styles.infoMsg),
            }}
          >
            {errorMsg || inputInfo}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomSelect;
