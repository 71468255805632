import { GREY, RED } from '../../../Constants/colors';
import { IBM_PLEX_SANS } from '../../../Constants/fonts';

export const styles = {
  label: {
    position: 'relative',
    fontFamily: IBM_PLEX_SANS,
    fontSize: '14px',
    fontWeight: '600',
    margin: 'auto 0',
    lineHeight: '15px',
  },
  labelWithoutRequired: {
    lineHeight: '25px',
  },
  superset: {
    color: RED,
  },
  subtext: {
    fontSize: '12px',
    color: GREY,
    textAlign: 'right',
    display: 'block',
  },
};
