import { Box, CircularProgress } from '@mui/material';

import { styles } from './CustomLoaderStyles';

const CustomLoader = (props) => {
  const { customStyles = {} } = props;

  return (
    <Box sx={styles.loaderBox}>
      <CircularProgress sx={customStyles} />
    </Box>
  );
};

export default CustomLoader;
