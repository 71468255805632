// white
export const WHITE = '#ffffff';
export const COLD_MORNING = '#E5E5E5';

// black
export const GONDOLA = 'rgb(0 0 0)';
export const DUNES_MANOR = '#524D49';
export const POT_BLACK = '#161616';
export const DIRE_WOLF = '#272727';
export const SOOTY = '#141414';

// blue
export const HEROIC_BLUE = '#0F61FD';
export const COTTON_BOLL = '#E3EEFA';
export const BLASPHEMOUS_BLUE = '#3c52b2';
export const BRIGHT_NAVY_BLUE = '#1976d2';

// red
export const CHINESE_GOLDFISH = '#0f62fe';
export const RED = '#FF0000';
export const CHI_GONG = '#DA2E2E';
export const PERSIMMON = '#eb5202';

// brown
export const LEADBELCHER = '#CACACA';
export const BRILLIANT_LIQUORICE = '#555453';

// grey
export const GREY = '#808080';
export const UP_IN_SMOKE = '#6d6f6f';
export const SHARK_FIN = '#969696';
export const KINGLY_CLOUD = '#DEDEDE';
export const MIDNIGHT_GREY = '#65696D';
export const WHITE_SMOKE = '#F5F5F5';
export const LIGHT_PALE_LILAC = '#CED5E3';
export const UNICORN_SILVER = '#E8E8E8';
export const SPANISH_GREY = '#979797';
export const RAINY_GREY = '#A4A4A4';
export const COMPOSER_MAGIC = '#7C6E7F';

// yellow
export const MARIGOLD = '#FFBE07';
export const SAMOAN_SUN = '#FBC780';
export const NARENJI_ORANGE = '#ffc34e';

// green
export const PLANTER = '#2C9D05';
export const PASSWORD_MATCHED = '#0B9743';
