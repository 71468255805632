import {
  LEADBELCHER,
  POT_BLACK,
  WHITE,
  UP_IN_SMOKE,
  CHINESE_GOLDFISH,
  UNICORN_SILVER,
} from '../../../Constants/colors';

export const styles = {
  inputContainer: {
    position: 'relative',
    width: '100%',
  },
  inputBase: {
    border: `1px solid ${LEADBELCHER}`,
    borderRadius: '4px',
    backgroundColor: WHITE,
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '40px',
    padding: '7px 5px',
    '.MuiInputBase-input': {
      padding: 0,
      fontFamily: 'IBM Plex Sans, sans-serif',
      color: POT_BLACK,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '19px',
      '&::placeholder': {
        fontFamily: 'IBM Plex Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '18px',
        textTransform: 'capitalize',
      },
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&:[type=number]': {
        MozAppearance: 'textfield',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: UNICORN_SILVER,
    },
  },
  inputAdornment: {
    marginRight: '15px',
  },
  passwordVisibilityIcon: {
    width: '16px',
    height: '14px',
    color: POT_BLACK,
  },
  cusrorStyle: {
    cursor: 'pointer',
  },
  bottomContainer: {
    minHeight: '16px',
  },
  bottomContainerMsg: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    marginTop: '5px',
  },
  errorMsg: {
    color: CHINESE_GOLDFISH,
  },
  infoMsg: {
    color: UP_IN_SMOKE,
  },
};
