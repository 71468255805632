import {
  CHINESE_GOLDFISH,
  COLD_MORNING,
  HEROIC_BLUE,
  POT_BLACK,
  SPANISH_GREY,
  RED,
  WHITE,
} from '../../../Constants/colors';
import { IBM_PLEX_SANS } from '../../../Constants/fonts';

export const styles = {
  basicBtn: {
    borderRadius: 0,
    fontSize: '14px',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: IBM_PLEX_SANS,
    position: 'relative',
    '&:disabled': {
      color: POT_BLACK,
      opacity: '30%',
    },
  },
  detailBtn: {
    minWidth: '122px',
    height: '48px',
    backgroundColor: COLD_MORNING,
    color: POT_BLACK,
    fontWeight: 'medium',
    '&:hover': {
      backgroundColor: COLD_MORNING,
    },
  },
  proceedBtn: {
    minWidth: '124px',
    height: '40px',
    backgroundColor: HEROIC_BLUE,
    color: WHITE,
    '&:hover': {
      backgroundColor: HEROIC_BLUE,
    },
  },
  submitBtn: {
    minWidth: '124px',
    height: '40px',
    borderRadius: 0,
    backgroundColor: CHINESE_GOLDFISH,
    color: WHITE,
    '&:hover': {
      backgroundColor: CHINESE_GOLDFISH,
    },
  },
  cancelBtn: {
    minWidth: '124px',
    height: '40px',
    backgroundColor: WHITE,
    color: SPANISH_GREY,
    border: `1px solid ${SPANISH_GREY}`,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: WHITE,
    },
  },
  sendBtn: {
    minWidth: '89px',
  },
  errorStyle: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '500',
    color: RED,
    marginBottom: '20px',
  },
  saveBtn: {
    minWidth: '124px',
    height: '40px',
    backgroundColor: CHINESE_GOLDFISH,
    color: WHITE,
    fontWeight: 'medium',
    '&:hover': {
      backgroundColor: '#0353e9',
    },
  },
  btnLoader: {
    maxWidth: '20px',
    maxHeight: '20px',
    color: WHITE,
  },
};
