import { Box, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';

import CustomButton from '../Widgets/CustomButton/CustomButton';
import { styles } from './CustomFormHeaderStyles';

const CustomFormHeader = (props) => {
  const {
    title,
    clearBtnHandler,
    clearBtnName = 'Reset',
    submitBtnHandler,
    showSubmit,
    editBtnHandler,
    backBtnHandler,
    isSubmitDisbaled,
  } = props;

  return (
    <Box sx={styles.headerContainer}>
      <Box sx={styles.titleContainer}>
        {backBtnHandler && (
          <WestIcon sx={styles.backNavigationIcon} onClick={backBtnHandler} />
        )}
        <Typography sx={styles.titleText}>{title}</Typography>
      </Box>
      <Box sx={styles.btnContainer}>
        {editBtnHandler && (
          <CustomButton
            type="detail"
            textContent="Edit"
            clickHandler={editBtnHandler}
            customStyles={styles.commonBtnStyles}
          />
        )}
        {clearBtnHandler && (
          <CustomButton
            type="detail"
            textContent={clearBtnName}
            clickHandler={clearBtnHandler}
            customStyles={styles.commonBtnStyles}
          />
        )}
        {submitBtnHandler ||
          (showSubmit && (
            <CustomButton
              type="save"
              textContent="Save"
              clickHandler={submitBtnHandler || null}
              customStyles={styles.commonBtnStyles}
              disabled={isSubmitDisbaled}
            />
          ))}
      </Box>
    </Box>
  );
};

export default CustomFormHeader;
