import { WHITE } from '../../Constants/colors';
import { commonStyles } from '../../Assets/commonStyles';

export const styles = {
  layoutContainer: {
    display: 'flex',
    backgroundColor: WHITE,
    // height: '100%',
    height: 'calc(100vh - 78px)',
    overflow: 'auto',
    ...commonStyles.scrollBar,
  },
  childComponent: {
    width: '100%',
  },
  layoutSuspense: {
    height: '100%',
    minHeight: 'calc(100vh - 78px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};
