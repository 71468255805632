import axiosInstance from '../../API/axiosInstance';
import { getQsValues } from '../../Constants/getQsValues';
import { LOYALTY_CAMPAIGN_API } from '../../Constants/apiEndpoints';

const getLoyaltyCampaignsList = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    const response = await axiosInstance.get(
      `${LOYALTY_CAMPAIGN_API}?${newQsValue}`
    );
    return response?.data?.data;
  } catch (err) {
    const error = JSON.stringify(err);
    throw Error(error);
  }
};

const LoyaltyCampaignsService = { getLoyaltyCampaignsList };

export default LoyaltyCampaignsService;
