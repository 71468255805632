import React, { useEffect, useState } from 'react';
import axiosInstance from '../../API/axiosInstance';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  Pagination,
  TableCell,
  Button,
} from '@carbon/react';
import { toast } from 'react-toastify';
import NoDataInTable from '../../Components/NoDataTable/NoDataInTable';
import { Edit } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MEMBERSHIP_ADD } from '../../Routes/Routes';
const Membership = () => {
  const [membershipData, setMembershipData] = useState({
    data: null,
    page: 1,
    rowsPerPage: 10,
  });
  const fetchMembershipData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}public/memberships?limit=${
          membershipData?.rowsPerPage
        }&skip=${(membershipData?.page - 1) * membershipData?.rowsPerPage}`
      );
      setMembershipData((state) => ({
        ...state,
        data: response?.data?.data,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchMembershipData();
  }, [membershipData?.page, membershipData?.rowsPerPage]);
  const tableHeaders = [
    {
      key: 'id',
      header: 'Id',
      type: 'number',
    },
    {
      key: 'name',
      header: 'Name',
      type: 'string',
    },
    {
      key: 'points',
      header: 'Points',
      type: 'number',
    },
    {
      key: 'url',
      header: 'Image URL',
      type: 'image',
    },
    {
      key: 'id',
      header: 'Edit',
      type: 'button',
    },
  ];
  const Wrapper = styled.div`
    padding: 2rem;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      justify-content: space-between;
    }
  `;
  const handlePagination = (e) => {
    setMembershipData((state) => ({
      ...state,
      rowsPerPage: e.pageSize,
      page: e.page,
    }));
  };
  return (
    <Wrapper>
      <div className="header">
        <h4>Membership</h4>
        <Button as={Link} to={MEMBERSHIP_ADD} size="md">
          Add New Membership
        </Button>
      </div>

      <DataTable
        useZebraStyles
        experimentalAutoAlign
        overflowMenuOnHover={false}
        headers={tableHeaders}
        rows={membershipData?.data?.result || []}
        isSortable
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }) => {
          return (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          colSpan={header.colSpan}
                          key={header.key}
                          {...getHeaderProps({ header })}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => {
                    return (
                      <TableRow key={row.id} {...getRowProps({ row })}>
                        {row.cells.map((cell, index) => {
                          return (
                            <TableCell
                              key={cell.id}
                              {...getTableContainerProps({ cell })}
                            >
                              {cell?.info?.header ===
                                tableHeaders[index]?.key && !!cell.value ? (
                                tableHeaders[index]?.type === 'date' ? (
                                  moment(cell.value).format(
                                    'YYYY-MM-DD - HH:mm:ss'
                                  )
                                ) : tableHeaders[index]?.type === 'link' ? (
                                  <Link
                                    to={`/customers/edit/${cell.value}?cloudCreditsBalance=${membershipData?.data?.result[rowIndex]?.USPCreditsBalance}&loyaltyPointsBalance=${membershipData?.data?.result[rowIndex]?.loyaltyPointsBalance}`}
                                  >
                                    <Edit size={16} />
                                  </Link>
                                ) : tableHeaders[index]?.type === 'button' ? (
                                  <Button
                                    as={Link}
                                    to={`/membership/edit/${cell.value}`}
                                    hasIconOnly
                                    iconDescription="Edit"
                                    size="sm"
                                    value={cell.value}
                                    renderIcon={() => <Edit size={16} />}
                                  />
                                ) : tableHeaders[index]?.type === 'image' ? (
                                  <img
                                    src={cell?.value}
                                    height={200}
                                    width={200}
                                    style={{ objectFit: 'contain' }}
                                    alt="gradient image"
                                  />
                                ) : (
                                  cell.value
                                )
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!membershipData?.data?.result?.length && (
                <NoDataInTable background={'#f4f4f4'} />
              )}
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={(e) => handlePagination(e)}
        page={membershipData?.page}
        pageSize={membershipData?.rowsPerPage}
        pageSizes={[1, 5, 10, 20, 30, 40, 50]}
        size="md"
        totalItems={membershipData?.data?.totalCount}
      />
    </Wrapper>
  );
};

export default Membership;
