import { Tooltip } from '@mui/material';

import { styles } from './CustomToolTipStyles';

const CustomToolTip = (props) => {
  const { children, title } = props;
  return (
    <Tooltip
      sx={styles.toolTipWrapper}
      title={title}
      componentsProps={{
        tooltip: {
          sx: styles.customToolTip,
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
