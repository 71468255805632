import React, { useEffect, useState } from 'react';
import axiosInstance from '../../API/axiosInstance';
import styled from 'styled-components';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  Pagination,
  TableCell,
} from '@carbon/react';
import moment from 'moment';
import NoDataInTable from '../../Components/NoDataTable/NoDataInTable';
import { Launch } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
const Wrapper = styled.main`
  padding: 2rem;
  width: 100%;
  h4 {
    margin-bottom: 2rem;
  }
`;
const GiftCardRedemption = () => {
  const [data, setData] = useState({
    data: null,
    page: 1,
    rowsPerPage: 10,
  });
  const asyncFetchGiftCardRedemption = async () => {
    axiosInstance
      .get(
        `giftcards?limit=${data?.rowsPerPage}&skip=${
          (data?.page - 1) * data?.rowsPerPage
        }`
      )
      .then((response) =>
        setData((data) => ({ ...data, data: response?.data?.data }))
      );
  };
  useEffect(() => {
    asyncFetchGiftCardRedemption();
  }, [data?.page, data?.rowsPerPage]);

  const tableHeaders = [
    {
      key: 'id',
      header: 'Id',
      type: 'number',
    },
    {
      key: 'email',
      header: 'Email',
      type: 'string',
    },
    {
      key: 'amountInCents',
      header: 'Amount In Cents',
      type: 'number',
    },

    {
      key: 'createdAt',
      header: 'Created At',
      type: 'date',
    },
    {
      key: 'pointsUsed',
      header: 'Points Used',
      type: 'string',
    },
    {
      key: 'rejectedBy',
      header: 'Rejected By',
      type: 'string',
    },
    {
      key: 'releasedAt',
      header: 'Released At',
      type: 'date',
    },
    {
      key: 'ss_contact_id',
      header: 'Contact ID',
      type: 'string',
    },
    {
      key: 'status',
      header: 'Status',
      type: 'string',
    },
    {
      key: 'createdBy',
      header: 'View',
      type: 'link',
    },
  ];
  const handlePagination = (e) => {
    setData((res) => ({ ...res, rowsPerPage: e.pageSize, page: e.page }));
  };
  return (
    <Wrapper>
      <h4>Gift Card Redemption</h4>
      <DataTable
        useZebraStyles
        experimentalAutoAlign
        overflowMenuOnHover={false}
        headers={tableHeaders}
        rows={data?.data?.result || []}
        isSortable
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }) => {
          return (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          colSpan={header.colSpan}
                          key={header.key}
                          {...getHeaderProps({ header })}
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => {
                    return (
                      <TableRow key={row.id} {...getRowProps({ row })}>
                        {row.cells.map((cell, index) => {
                          return (
                            <TableCell
                              key={cell.id}
                              {...getTableContainerProps({ cell })}
                            >
                              {cell?.info?.header ===
                                tableHeaders[index]?.key && !!cell.value ? (
                                tableHeaders[index]?.type === 'date' ? (
                                  moment(cell.value).format(
                                    'YYYY-MM-DD - HH:mm:ss'
                                  )
                                ) : tableHeaders[index]?.type === 'link' ? (
                                  <Link
                                    to={`/customers/edit/${cell.value}?cloudCreditsBalance=${data?.data?.result[rowIndex]?.USPCreditsBalance}&loyaltyPointsBalance=${data?.data?.result[rowIndex]?.loyaltyPointsBalance}`}
                                  >
                                    <Launch size={16} />
                                  </Link>
                                ) : (
                                  cell.value
                                )
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!data?.data?.result?.length && (
                <NoDataInTable background={'#f4f4f4'} />
              )}
            </TableContainer>
          );
        }}
      </DataTable>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={(e) => handlePagination(e)}
        page={data?.page}
        pageSize={data?.rowsPerPage}
        pageSizes={[1, 5, 10, 20, 30, 40, 50]}
        size="md"
        totalItems={data?.data?.totalCount}
      />
    </Wrapper>
  );
};

export default GiftCardRedemption;
