import axiosInstance from '../../API/axiosInstance';
import { getQsValues } from '../../Constants/getQsValues';
import { PRODUCTS_API } from '../../Constants/apiEndpoints';

const getProductList = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    console.log(newQsValue , data)
    // const response = await axiosInstance.get(`${PRODUCTS_API}?${newQsValue}`);
    const response = await axiosInstance.get(`all-products?${newQsValue}`);
    return response?.data?.data;
  } catch (err) {
    const error = JSON.stringify(err);
    throw Error(error);
  }
};

const ProductService = { getProductList };

export default ProductService;
