import axiosInstance from '../../API/axiosInstance';
import {
  SETTINGS_APPLY_VOUCHER_API,
  SETTINGS_CALCULATE_PROMOTION_API,
  SETTINGS_ORDER_STATUS_API,
  SETTINGS_CHARITY_LIST_API,
} from '../../Constants/apiEndpoints';
import { SOMETHING_WENT_WRONG } from '../../Constants/errorMessages';
import { getQsValues } from '../../Constants/getQsValues';

const calculatePromotionBy = async () => {
  try {
    return await axiosInstance.get(SETTINGS_CALCULATE_PROMOTION_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const orderStatus = async () => {
  try {
    return await axiosInstance.get(SETTINGS_ORDER_STATUS_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const applyVoucher = async () => {
  try {
    return await axiosInstance.get(SETTINGS_APPLY_VOUCHER_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const allowedCharity = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    const response = await axiosInstance.get(
      `${SETTINGS_CHARITY_LIST_API}?${newQsValue}`
    );
    return response;
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const SettingsService = {
  calculatePromotionBy,
  orderStatus,
  applyVoucher,
  allowedCharity,
};

export default SettingsService;
