/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
  ListSubheader,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { CheckBoxOutlined } from '@mui/icons-material';

import CustomLoader from '../CustomLoader/CustomLoader';
import CustomMultiCheckboxCard from '../../CustomMultiCheckboxCard/CustomMultiCheckboxCard';
import { DEFAULT_ROW_PER_PAGE } from '../../../Constants/constants';
import { BootstrapInput, styles } from './CustomSelectStyles';
import { Axios } from 'axios';
import { getToken } from '../../../Auth/getToken';
import { ALLOWED_BRANDS_API } from '../../../Constants/apiEndpoints';
import axiosInstance from '../../../API/axiosInstance';

const CustomCheckboxMultiSelect = (props) => {
  const {
    values,
    setValues,
    name,
    data,
    mappingId,
    mappingName,
    emptyDataMessage = 'No Data',
    placeholder = 'Select',
    isLoading = false,
    isError = false,
    dispatchFunction,
    showChip = false,
    hideMessage,
    inputInfo,
    errorMsg,
    id,
    renderItem,
    showSearch,
    isInfiniteScroll,
    filterObject,
    mappingTitle,
    mappingThumbnail,
    mappingDescription,
    mappingCategory,
    isGiftBrandRequest,
    isGiftBitOpen,
    // setIsGiftOpen,
  } = props;

  const [searchedText, setSearchedText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [internalData, setInternalData] = useState([]);

  const dispatch = useDispatch();

  const getData = () => {
    if (isInfiniteScroll) return internalData;
    else {
      return data;
    }
  };
  const searchClearHandler = () => {
    setSearchedText('');
  };

  const handleDelete = (data) => {
    const result = _.reject(values, data);
    setValues(name, result);
  };

  const handleSelect = (data) => {
    const newSelectedValue = _.xorBy(
      values,
      [data],
      (item) => item?.[mappingId]
    );
    setValues(name, newSelectedValue);
  };

  const onChangeSearch = (e) => {
    const newSearchValue = e.target.value;
    setSearchedText(newSearchValue);
  };

  const fetchListOnScroll = async (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 5;
    if (bottom && hasMoreData && !isLoading) {
      setPageNumber((prev) => prev + 1);
      if (dispatchFunction) {
        dispatch(
          dispatchFunction({
            keyword: searchedText || '',
            limit: DEFAULT_ROW_PER_PAGE,
            skip: pageNumber * DEFAULT_ROW_PER_PAGE,
            ...(filterObject && {
              filter: JSON.stringify(filterObject),
            }),
          })
        );
      }
      e.target.scrollTop -= 100;
    }
  };

  useEffect(() => {
    setPageNumber(1);
    if (dispatchFunction) {
      dispatch(
        !isInfiniteScroll
          ? dispatchFunction()
          : dispatchFunction({
              keyword: searchedText || '',
              limit: DEFAULT_ROW_PER_PAGE,
              skip: 0,
              ...(filterObject && {
                filter: JSON.stringify(filterObject),
              }),
            })
      );
    }
  }, [searchedText]);
  return (
    <Box>
      <Select
        id={id}
        name={name}
        multiple
        value={values?.map((selectedValueDetails) => {
          return (
            internalData?.find((internalDataDetail) => {
              return (
                internalDataDetail[mappingId] ===
                selectedValueDetails[mappingId]
              );
            }) || selectedValueDetails
          );
        })}
        displayEmpty
        defaultValue=""
        sx={styles.selectBox}
        input={<BootstrapInput />}
        renderValue={(selected) => {
          if (!selected?.length) {
            return (
              <Typography sx={styles.inputStyle}>{placeholder}</Typography>
            );
          }
          if (showChip) {
            return (
              <Box sx={styles.renderChipStyle}>
                {selected.map((value, idx) => (
                  <Chip
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => handleDelete(value)}
                    key={idx}
                    label={value?.[mappingName]}
                  />
                ))}
              </Box>
            );
          }
          return (
            <Typography sx={styles.inputStyle}>
              {data &&
                selected
                  ?.map((selectedDetail) => {
                    return selectedDetail?.[mappingName];
                  })
                  .join(', ')}
            </Typography>
          );
        }}
        MenuProps={{
          sx: { ...styles.menu, ...styles.multiSelectMenu },
          ...(isInfiniteScroll && {
            autoFocus: false,
            PaperProps: {
              onScroll: (e) => {
                fetchListOnScroll(e);
              },
            },
          }),
        }}
      >
        {showSearch && (
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              sx={styles.searchField}
              value={searchedText}
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchedText && (
                  <InputAdornment
                    position="end"
                    sx={styles.clearBtn}
                    onClick={searchClearHandler}
                  >
                    <CloseIcon />
                  </InputAdornment>
                ),
              }}
              onChange={onChangeSearch}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {!isError &&
          (getData()?.length
            ? getData()?.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item}
                  sx={{
                    ...(renderItem ? styles.renderMenuItem : styles.menuItem),
                  }}
                  onClick={() => handleSelect(item)}
                >
                  {renderItem === 'customMultiCheckboxCard' ? (
                    <CustomMultiCheckboxCard
                      thumbnail={item?.[mappingThumbnail]}
                      title={item?.[mappingTitle]}
                      description={item?.[mappingDescription]}
                      category={item?.[mappingCategory]}
                    />
                  ) : (
                    <ListItemText
                      primary={item?.[mappingName]}
                      sx={styles.listItemText}
                    />
                  )}
                  <Checkbox
                    sx={styles.checkbox}
                    checkedIcon={<CheckBoxOutlined />}
                    checked={Boolean(
                      values.find((selectedValuesDetail) => {
                        return (
                          selectedValuesDetail[mappingId] === item[mappingId]
                        );
                      })
                    )}
                  />
                </MenuItem>
              ))
            : !isLoading && (
                <MenuItem disabled>
                  <Typography>{emptyDataMessage}</Typography>
                </MenuItem>
              ))}

        {isLoading && <CustomLoader />}
      </Select>
      {!hideMessage && (
        <Box sx={styles.bottomContainer}>
          <Typography
            sx={{
              ...styles.bottomContainerMsg,
              ...(errorMsg && styles.errorMsg),
              ...(!errorMsg && inputInfo && styles.infoMsg),
            }}
          >
            {errorMsg || inputInfo}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomCheckboxMultiSelect;
