import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
} from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { styles } from './CustomInputStyles';

const CustomInput = (props) => {
  const {
    isRequired,
    placeholder,
    name,
    type,
    value,
    setValue,
    multiline,
    rows,
    onFocus,
    onBlur,
    onClick,
    onSearchClick,
    onKeyPress,
    onStartIconClick,
    startIcon,
    showPasswordVisibilityOffIcon,
    showPasswordVisibilityOnIcon,
    errorMsg,
    customStyles,
    iconFunction,
    inputInfo,
    showSearchIcon,
    showClearIcon,
    onClearClick,
    hideMessage,
    maxLength,
    isDisabled,
    id,
  } = props;

  const changeHandler = (e) => {
    if (name) {
      setValue(e.target.name, e.target.value);
    } else {
      setValue(e.target.value);
    }
  };

  const iconFunctionHandler = () => {
    iconFunction((prevState) => !prevState);
  };

  return (
    <Box sx={styles.inputContainer}>
      <InputBase
        id={id}
        fullWidth
        required={isRequired}
        placeholder={placeholder}
        disabled={isDisabled}
        inputProps={{ maxLength }}
        name={name || ''}
        type={type || 'text'}
        value={value}
        multiline={multiline}
        rows={rows}
        onChange={(e) => changeHandler(e)}
        // onFocus={(e) => onFocus && onFocus(e)}
        onFocus={
          onFocus
            ? (e) => onFocus(e)
            : (e) =>
                e.target.addEventListener(
                  'wheel',
                  (e) => {
                    e.preventDefault();
                  },
                  { passive: false }
                )
        }
        onBlur={(e) => onBlur && onBlur(e)}
        onClick={onClick || null}
        onKeyPress={onKeyPress ? (event) => onKeyPress(event) : null}
        sx={{
          ...styles.inputBase,
          ...customStyles,
        }}
        startAdornment={
          <InputAdornment position="start" onClick={() => onStartIconClick()}>
            {startIcon && <IconButton>{startIcon}</IconButton>}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment sx={styles.inputAdornment} position="end">
            {showPasswordVisibilityOffIcon && (
              <IconButton
                sx={styles.passwordVisibilityIcon}
                aria-label="toggle password visibility off"
                onClick={iconFunctionHandler}
              >
                <VisibilityOffOutlined sx={styles.passwordVisibilityIcon} />
              </IconButton>
            )}
            {showPasswordVisibilityOnIcon && (
              <IconButton
                sx={styles.passwordVisibilityIcon}
                aria-label="toggle password visibility on"
                onClick={iconFunctionHandler}
              >
                <VisibilityOutlined sx={styles.passwordVisibilityIcon} />
              </IconButton>
            )}
            {showSearchIcon && (
              <SearchIcon sx={styles.cusrorStyle} onClick={onSearchClick} />
            )}
            {showClearIcon && (
              <ClearIcon sx={styles.cusrorStyle} onClick={onClearClick} />
            )}
          </InputAdornment>
        }
      />
      {!hideMessage && (
        <Box sx={styles.bottomContainer}>
          <Typography
            sx={{
              ...styles.bottomContainerMsg,
              ...(errorMsg && styles.errorMsg),
              ...(!errorMsg && inputInfo && styles.infoMsg),
            }}
          >
            {errorMsg || inputInfo}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomInput;
