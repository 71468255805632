/* eslint-disable react/jsx-filename-extension */
import { lazy } from 'react';
import CloudCredit from '../Containers/CloudCredit/CloudCredit';
import CloudCreditInner from '../Containers/CloudCredit/CloudCreditInner';
import Coupon from '../Containers/Coupon/Coupon';
import CouponAdd from '../Containers/Coupon/CouponAdd';
import CouponTier from '../Containers/CouponTier/CouponTier';
import CouponTierAdd from '../Containers/CouponTier/CouponTierAdd';
import CreateRedemption from '../Containers/CreateRedemtion/CreateRedemption';
import MarketingSetting from '../Containers/MarketingSetting/MarketingSetting';
// import USPSetting from '../Containers/SettingUSP/USPSetting';
import USPPromotion from '../Containers/USPPromotion/USPPromotion';
import USPPromotionAdd from '../Containers/USPPromotion/USPPromotionAdd';

import {
  DASHBOARD,
  LOYALTY_CAMPAIGN,
  PROMOTION,
  PROMOTION_CATEGORY,
  LOYALTY_ITEM,
  CUSTOMER_LIST,
  PROMOTION_ADD,
  PROMOTION_EDIT,
  PROMOTION_CATEGORY_ADD,
  PROMOTION_CATEGORY_EDIT,
  LOYALTY_ITEM_ADD,
  LOYALTY_ITEM_EDIT,
  LOYALTY_CAMPAIGN_ADD,
  LOYALTY_CAMPAIGN_EDIT,
  PROMOTIONS_SETTINGS,
  PROMOTION_FREE_ITEM_SETTINGS,
  GIFTBIT_SETTINGS,
  CONFIGURATION_SETTINGS,
  DONATION_SETTINGS,
  CLOUD_CREDITS,
  CLOUD_CREDITS_EDIT,
  USP_SETTINGS,
  CUSTOMER_LIST_EDIT,
  COUPON,
  COUPON_TIER,
  COUPON_TIER_ADD,
  COUPON_CREATE,
  COUPON_EDIT,
  COUPON_TIER_EDIT,
  CREATE_REDEMPTION,
  USP_PROMOTIONS,
  USP_PROMOTIONS_ADD,
  USP_PROMOTIONS_EDIT,
  MARKETING_SETTINGS,
  GIFT_CARD_REDEMPTION,
  MEMBERSHIP,
  MEMBERSHIP_ADD,
  MEMBERSHIP_EDIT,
  LOYALTY_ITEM_CATEGORY,
  LOYALTY_ITEM_CATEGORY_ADD,
  LOYALTY_ITEM_CATEGORY_EDIT,
  MARKETPLACE_MEMBERSHIP,
  MARKETPLACE_MEMBERSHIP_ADD,
  MARKETPLACE_MEMBERSHIP_EDIT,
  SALES_AGENTS,
  SALES_AGENTS_ADD,
  SALES_AGENTS_EDIT,
  SALES_CAMPAIGNS,
  SALES_CAMPAIGNS_ADD,
} from './Routes';
import GiftCardRedemption from '../Containers/GiftCardRedemption/GiftCardRedemption';
import Membership from '../Containers/Membership/Membership';
import MembershipAdd from '../Containers/Membership/MembershipAdd';
import MarketplaceMembership from '../Containers/MarketplaceMembership/MarketplaceMembership';
import MarketplaceMembershipAdd from '../Containers/MarketplaceMembership/MarketplaceMembershipAdd';
import LoyaltyItemCategory from '../Containers/LoyaltyItemCategory/LoyaltyItemCategory';
import SalesAgentsAdd from '../Containers/SalesAgents/SalesAgentsAdd';

const Dashboard = lazy(() => import('../Containers/Dashboard/Dashboard'));
const Promotions = lazy(() => import('../Containers/Promotions/Promotions'));
const PromotionsAdd = lazy(() =>
  import('../Containers/Promotions/PromotionsAdd')
);
const PromotionCategory = lazy(() =>
  import('../Containers/PromotionCategory/PromotionCategory')
);
const PromotionCategoryAdd = lazy(() =>
  import('../Containers/PromotionCategory/PromotionCategoryAdd')
);
const LoyaltyCampaign = lazy(() =>
  import('../Containers/LoyaltyCampaign/LoyaltyCampaign')
);
const LoyaltyItem = lazy(() => import('../Containers/LoyaltyItem/LoyaltyItem'));
const LoyaltyCustomerList = lazy(() =>
  import('../Containers/LoyaltyCustomerList/LoyaltyCustomerList')
);
const LoyaltyItemAdd = lazy(() =>
  import('../Containers/LoyaltyItem/LoyaltyItemAdd')
);
const LoyaltyCampaignAdd = lazy(() =>
  import('../Containers/LoyaltyCampaign/LoyaltyCampaignAdd')
);
const SettingsPromotions = lazy(() =>
  import('../Containers/SettingsPromotions/SettingsPromotions')
);
const SettingsFreeItems = lazy(() =>
  import('../Containers/SettingsFreeItems/SettingsFreeItems')
);
const SettingsVoucherify = lazy(() =>
  import('../Containers/SettingsVoucherify/SettingsVoucherify')
);
const SettingsGiftBit = lazy(() =>
  import('../Containers/SettingsGiftBit/SettingsGiftBit')
);
const SettingsDonation = lazy(() =>
  import('../Containers/SettingsDonation/SettingsDonation')
);
const LoyalityCustomerListAdd = lazy(() =>
  import('../Containers/LoyaltyCustomerList/LoyaltyCustomerListAdd')
);

const SalesAgents = lazy(() => import('../Containers/SalesAgents/SalesAgents'));
const SalesCampaigns = lazy(() =>
  import('../Containers/SalesCampaigns/SalesCampaigns')
);
const SalesCampaignsAdd = lazy(() =>
  import('../Containers/SalesCampaigns/SalesCampaignsAdd')
);

export const privateRoutes = [
  {
    id: 1,
    path: DASHBOARD,
    component: <Dashboard />,
  },
  {
    id: 2,
    path: PROMOTION,
    component: <Promotions />,
  },
  { id: 3, path: PROMOTION_CATEGORY, component: <PromotionCategory /> },
  { id: 4, path: LOYALTY_CAMPAIGN, component: <LoyaltyCampaign /> },
  { id: 5, path: LOYALTY_ITEM, component: <LoyaltyItem /> },
  { id: 6, path: CUSTOMER_LIST, component: <LoyaltyCustomerList /> },
  {
    id: 7,
    path: PROMOTION_CATEGORY_ADD,
    component: <PromotionCategoryAdd type="add" />,
  },
  {
    id: 8,
    path: PROMOTION_CATEGORY_EDIT,
    component: <PromotionCategoryAdd type="edit" />,
  },
  { id: 11, path: PROMOTION_ADD, component: <PromotionsAdd type="add" /> },
  { id: 12, path: PROMOTION_EDIT, component: <PromotionsAdd type="edit" /> },
  { id: 13, path: LOYALTY_ITEM_ADD, component: <LoyaltyItemAdd type="add" /> },
  {
    id: 14,
    path: LOYALTY_ITEM_EDIT,
    component: <LoyaltyItemAdd type="edit" />,
  },
  {
    id: 15,
    path: LOYALTY_CAMPAIGN_ADD,
    component: <LoyaltyCampaignAdd type="add" />,
  },
  {
    id: 16,
    path: LOYALTY_CAMPAIGN_EDIT,
    component: <LoyaltyCampaignAdd type="edit" />,
  },
  { id: 17, path: PROMOTIONS_SETTINGS, component: <SettingsPromotions /> },
  {
    id: 18,
    path: PROMOTION_FREE_ITEM_SETTINGS,
    component: <SettingsFreeItems />,
  },
  { id: 19, path: GIFTBIT_SETTINGS, component: <SettingsGiftBit /> },
  {
    id: 20,
    path: CONFIGURATION_SETTINGS,
    component: <SettingsVoucherify />,
  },
  { id: 21, path: DONATION_SETTINGS, component: <SettingsDonation /> },
  { id: 22, path: CLOUD_CREDITS, component: <CloudCredit /> },
  { id: 23, path: CLOUD_CREDITS_EDIT, component: <CloudCreditInner /> },
  // { id: 24, path: USP_SETTINGS, component: <USPSetting /> },
  {
    id: 25,
    path: CUSTOMER_LIST_EDIT,
    component: <LoyalityCustomerListAdd />,
  },
  {
    id: 26,
    path: COUPON,
    component: <Coupon />,
  },
  {
    id: 27,
    path: COUPON_TIER,
    component: <CouponTier />,
  },
  {
    id: 28,
    path: COUPON_TIER_ADD,
    component: <CouponTierAdd type="add" />,
  },

  {
    id: 29,
    path: COUPON_CREATE,
    component: <CouponAdd type="add" />,
  },
  {
    id: 30,
    path: COUPON_EDIT,
    component: <CouponAdd type="edit" />,
  },
  {
    id: 31,
    path: COUPON_TIER_EDIT,
    component: <CouponTierAdd type="edit" />,
  },
  { id: 32, path: CREATE_REDEMPTION, component: <CreateRedemption /> },
  { id: 33, path: USP_PROMOTIONS, component: <USPPromotion /> },
  {
    id: 34,
    path: USP_PROMOTIONS_ADD,
    component: <USPPromotionAdd type="add" />,
  },
  {
    id: 35,
    path: USP_PROMOTIONS_EDIT,
    component: <USPPromotionAdd type="edit" />,
  },
  {
    id: 36,
    path: MARKETING_SETTINGS,
    component: <MarketingSetting />,
  },
  {
    id: 37,
    path: GIFT_CARD_REDEMPTION,
    component: <GiftCardRedemption />,
  },
  {
    id: 38,
    path: MEMBERSHIP,
    component: <Membership />,
  },
  {
    id: 39,
    path: MEMBERSHIP_ADD,
    component: <MembershipAdd type="add" />,
  },
  {
    id: 40,
    path: MEMBERSHIP_EDIT,
    component: <MembershipAdd type="edit" />,
  },
  {
    id: 41,
    path: LOYALTY_ITEM_CATEGORY,
    component: <LoyaltyItemCategory type="home" />,
  },
  {
    id: 42,
    path: LOYALTY_ITEM_CATEGORY_ADD,
    component: <LoyaltyItemCategory type="add" />,
  },
  {
    id: 43,
    path: LOYALTY_ITEM_CATEGORY_EDIT,
    component: <LoyaltyItemCategory type="edit" />,
  },
  // Adding to get all marketplace membership
  {
    id: 44,
    path: MARKETPLACE_MEMBERSHIP,
    component: <MarketplaceMembership />,
  },
  {
    id: 45,
    path: MARKETPLACE_MEMBERSHIP_ADD,
    component: <MarketplaceMembershipAdd type="add" />,
  },
  {
    id: 46,
    path: MARKETPLACE_MEMBERSHIP_EDIT,
    component: <MarketplaceMembershipAdd type="edit" />,
  },
  // Adding sales agents
  {
    id: 47,
    path: SALES_AGENTS,
    component: <SalesAgents />,
  },
  {
    id: 48,
    path: SALES_AGENTS_ADD,
    component: <SalesAgentsAdd type="add" />,
  },
  {
    id: 49,
    path: SALES_AGENTS_EDIT,
    component: <SalesAgentsAdd type="edit" />,
  },
  {
    id: 50,
    path: SALES_CAMPAIGNS,
    component: <SalesCampaigns />,
  },
  {
    id: 51,
    path: SALES_CAMPAIGNS_ADD,
    component: <SalesCampaignsAdd type="add" />,
  },
  
];
