/* eslint-disable react/jsx-filename-extension */
import { lazy } from 'react';

import { LOGIN, ROOT } from './Routes';

const Login = lazy(() => import('../Containers/Login/Login'));
const DefaultRoute = lazy(() => import('./DefaultRoute'));

export const publicRoutes = [
  {
    id: 2,
    path: ROOT,
    component: <DefaultRoute />,
  },
  {
    id: 1,
    path: LOGIN,
    component: <Login />,
  },
];
