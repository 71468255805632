export const SOMETHING_WENT_WRONG = 'Something went wrong.';
export const SEQUENCE_NOT_VALID = 'Sequence can only be a positive number.';
export const TITLE_NOT_VALID = 'Title must be between 4 to 40 char long.';
export const NAME_NOT_VALID = 'Name must be between 4 to 40 char long.';
export const SORT_ORDER_NOT_VALID = 'Sort Order is a required field.';
export const FREEGIFTITEMCREDITS_NOT_VALID = 'This is a required field.';
export const PRODUCT_UNIQUE_CODE_NOT_VALID =
  'Product Unique Code is a required field.';
export const LOYALTY_CARD_POINTS_NOT_VALID =
  'Loyalty Card Points is a required field.';
export const MIN_ORDER_VALUE_NOT_VALID =
  'Minimum order value is a required field.';
export const MAX_ORDER_VALUE_NOT_VALID =
  'Maximum order value is a required field.';
export const MIN_ORDER_VALUE_GREATER_ERROR =
  'Minimum order value should be less than maximum order value';
export const MAX_ORDER_VALUE_SMALLER_ERROR =
  'Maximum order value should be greater than minimum order value.';
export const MIN_ORDER_VALUE_NEGATIVE_ERROR =
  'Minimum Order value cannot be negative.';
export const MAX_ORDER_VALUE_NEGATIVE_ERROR =
  'Maximum Order value cannot be negative.';
export const SORT_ORDER_VALUE_NEGATIVE_ERROR =
  'Sort Order value cannot be negative.';
export const INVALID_FILE_TYPE =
  'Only JPG, GIF or PNG type of image is allowed.';
export const FILE_TOO_LARGE = 'Maximum file size allowed is 2MB.';
export const CATEGORY_NOT_SELECTED = 'Please select a category.';
export const GIFT_CARD_VALUE_BY_NOT_VALID =
  'Please select a gift card value from option.';
export const ALLOWED_NOT_VALID = 'Please select allowed brands from option.';
export const GIFT_CARD_VALUE_NOT_VALID = 'Gift Card Value is a required field.';
export const VOUCHER_TYPE_NOT_VALID =
  'Please select a voucher type from option.';
export const VOUCHER_CODE_CONFIG_NOT_VALID =
  'Voucher code config is a required field.';
export const BANNER_IMAGE_NOT_VALID = 'Banner Image is a required field.';
export const VOUCHER_START_DATE_NOT_VALID =
  'Voucher Start Date is a required field.';
export const VOUCHER_END_DATE_NOT_VALID =
  'Voucher ENd Date is a required field.';
export const VOUCHER_DISCOUNT_TYPE_ID_NOT_VALID =
  'Voucher Discount Type Id is a required field.';
export const VOUCHER_DISCOUNT_OFF_NOT_VALID =
  'Voucher Discount Off is a required field.';
export const VOUCHER_GIFT_AMOUNT_NOT_VALID =
  'Voucher Gift Amount is a required field.';
export const VOUCHER_REDEMPTION_QUANTITY_NOT_VALID =
  'Voucher Redemption Quantity is a required field.';
export const VOUCHER_CODE_PREFIX_NOT_VALID =
  'Voucher Code Prefix is a required field.';
export const NO_VALUE_CHANGE = 'None of the values have been updated.';
export const AUTOMATIC_PROMOTION_RELEASE_DAYS_NOT_VALID =
  'Automatic Promotion Release day is a required field.';
export const ENABLE_PROMOTIONS_NOT_VALID =
  'Enable Promotions is a required field.';
export const CALCULATE_PROMOTION_BY_NOT_VALID =
  'Calculate Promotion By is a required field.';
export const ORDER_STATUS_TO_RELEASE_PROMOTION_NOT_VALID =
  'Order Status to Release Promotion is a required field.';
export const ENABLE_FREE_ITEMS_NOT_VALID =
  'Enable Free Items is a required field.';
export const REQUIRED_FIELD_ERROR = 'This is a required field.';
export const ENABLE_NOT_VALID = 'Enable is a required field.';
export const ALLOW_GIFTBIT_FOR_US_ONLY_NOT_VALID =
  'Allow GiftBit for US Customer is a required field.';
export const REQUIRED_PRODUCT_NAME = 'Product name is a required field.';
export const GIFTBIT_EXPIRY_NOT_VALID =
  'GiftBit Expiry Days is a required field.';
