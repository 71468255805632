/* eslint-disable prefer-const */
import { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';

import CustomRadioGroup from '../CustomRadioGroup/CustomRadioGroup';
import CustomButton from '../Widgets/CustomButton/CustomButton';
import CustomCheckboxGroup from '../CustomCheckboxGroup/CustomCheckboxGroup';
import { styles } from './FiltersStyles';

const FilterComponent = (props) => {
  const {
    tableQueryParams,
    setTableQueryParams,
    toggleFilters,
    filterOptions,
  } = props;
  const [activeFilter, setActiveFilter] = useState(filterOptions?.[0]);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleFilterClick = (item) => {
    setActiveFilter(item);
  };

  const onRadioChange = (e) => {
    setSelectedFilters((prev) => {
      return {
        ...prev,
        [activeFilter?.urlName]: e.target.value,
      };
    });
  };

  const getCheckboxFilter = (val) => {
    let arr = selectedFilters?.[activeFilter?.urlName]
      ? selectedFilters?.[activeFilter?.urlName]?.split(',')
      : [];
    if (arr?.length) {
      arr = arr.map((item) => +item);
    }
    if (arr.includes(val?.[activeFilter?.mappingId])) {
      arr = arr.filter((item) => +item !== val?.[activeFilter?.mappingId]);
    } else {
      arr = [...arr, Number(val?.[activeFilter?.mappingId])];
    }
    return arr?.length ? arr?.join(',') : '';
  };

  const onCheckboxChange = (e, val) => {
    setSelectedFilters((prev) => {
      return {
        ...prev,
        [activeFilter?.urlName]: getCheckboxFilter(val),
      };
    });
  };

  const getActiveFilter = () => {
    switch (activeFilter?.filterType) {
      case 'radio':
        return (
          <CustomRadioGroup
            list={activeFilter?.options}
            mappingName={activeFilter?.mappingName}
            mappingId={activeFilter?.mappingId}
            value={Number(selectedFilters?.[activeFilter?.urlName])}
            onChange={onRadioChange}
          />
        );
      case 'checkbox':
        return (
          <CustomCheckboxGroup
            list={activeFilter?.options}
            mappingName={activeFilter?.mappingName}
            mappingId={activeFilter?.mappingId}
            selected={
              selectedFilters?.[activeFilter?.urlName]
                ? selectedFilters?.[activeFilter?.urlName]?.split(',')
                : []
            }
            onChange={onCheckboxChange}
          />
        );
      default:
        return null;
    }
  };

  const handleReset = () => {
    let newTableParams = { ...tableQueryParams };
    Object.keys(tableQueryParams).forEach((key) => {
      if (key in selectedFilters) {
        newTableParams[key] = '';
      }
    });
    setTableQueryParams({ ...newTableParams, page: 1 });
    toggleFilters();
  };

  const handleApply = () => {
    setTableQueryParams((prev) => {
      return {
        ...prev,
        ...selectedFilters,
        page: 1,
      };
    });
    toggleFilters();
  };

  useEffect(() => {
    if (activeFilter?.filterId) {
      setSelectedFilters((prev) => {
        return {
          ...prev,
          [activeFilter?.urlName]: tableQueryParams?.[activeFilter?.urlName],
        };
      });
    }
  }, [activeFilter, tableQueryParams]);

  return (
    <Box sx={styles.mainComponent}>
      <Box sx={styles.innerMainComponent}>
        <Box sx={styles.innerLeftContainer}>
          <Box sx={styles.innerScrollContainer}>{getActiveFilter()}</Box>
        </Box>
        <Box sx={styles.innerCenterContainer}>
          <Divider orientation="vertical" sx={styles.dividerStyle} />
        </Box>
        <Box sx={styles.innerRightContainer}>
          {filterOptions?.map((option) => {
            return (
              <Box
                sx={{
                  ...styles.filterList,
                  ...(option?.filterId === activeFilter?.filterId &&
                    styles.activeFilter),
                }}
                key={option?.filterId}
                onClick={() => handleFilterClick(option)}
              >
                <Typography sx={styles.filterListText}>
                  {option?.displayName}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={styles.lowerMainComponent}>
        <CustomButton
          textContent="Reset"
          type="cancel"
          clickHandler={handleReset}
        />
        <CustomButton
          textContent="Apply"
          type="proceed"
          clickHandler={handleApply}
        />
      </Box>
    </Box>
  );
};

export default FilterComponent;
