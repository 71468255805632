import axiosInstance from '../../API/axiosInstance';
import { PROFILE_DETAIL_API } from '../../Constants/apiEndpoints';
import { SOMETHING_WENT_WRONG } from '../../Constants/errorMessages';

const getProfileDetail = async () => {
  try {
    return await axiosInstance.get(PROFILE_DETAIL_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const ProfileService = { getProfileDetail };

export default ProfileService;
