import { FILE_DELETE_API, FILE_UPLOAD_API } from '../Constants/apiEndpoints';
import axiosInstance from '../API/axiosInstance';

export const performImageUpload = async (file) => {
  const response = { errorMessage: '', data: null };
  const formData = new FormData();
  formData.append('file', file);
  try {
    response.data = await axiosInstance.post(FILE_UPLOAD_API, formData);
  } catch (err) {
    response.errorMessage = err?.response;
    throw err;
  }
  return response;
};

export const performImageDelete = async (fileId) => {
  const response = { error: '', data: null };
  try {
    response.data = await axiosInstance.delete(`${FILE_DELETE_API}/${fileId}`);
  } catch (err) {
    response.error = err?.response;
  }
  return response;
};
