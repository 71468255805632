import { Navigate, Outlet } from 'react-router-dom';
import { getToken } from '../Auth/getToken';
import { LOGIN } from './Routes';

const PrivateRoute = () => {
  const isUserLoggedIn = getToken();
  return isUserLoggedIn ? <Outlet /> : <Navigate to={LOGIN} />;
};

export default PrivateRoute;
