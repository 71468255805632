import { useState } from 'react';
import { Box, Typography, Menu, MenuItem, Divider, Grid } from '@mui/material';

import DialogBox from '../DialogBox/DialogBox';
import { ACTION_DROPDOWN_ITEMS } from '../../Constants/headerActionUtils';
import { styles } from './HeaderStyles';


const HeaderActionDropDown = (props) => {
  const { anchorElUser, handleCloseUserMenu } = props;
  const [customModalDetails, setCustomModalDetails] = useState({});

  const setCustomModalDetailsFromDataItem = (data) => {
    setCustomModalDetails({
      showModal: true,
      heading: data.confirmationHeading || '',
      description: data.confirmationDescription || '',
      confirmDetailHandler: () => {
        data.onClickActionItem();
        handleCloseUserMenu();
      },
      closeModalHandler: () => {
        setCustomModalDetails({});
        handleCloseUserMenu();
      },
    });
  };

  const onClickDataItem = (event, data) => {
    if (data.onClickActionItem) {
      if (!data.askConfirmation) {
        data.onClickActionItem();
      } else {
        setCustomModalDetailsFromDataItem(data);
      }
    }
    if (!data.askConfirmation) {
      handleCloseUserMenu();
    }
    event.stopPropagation();
  };

  return (
    <>
      <Menu
        sx={styles.dropDownContainer}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {ACTION_DROPDOWN_ITEMS.map((data) => {
          return (
            <Box key={data.id}>
              {data.id === 2 && <Divider sx={styles.divider} />}
              <MenuItem
                sx={{
                  ...styles.dropDownItem,
                  ...(data.id === 1 ? styles.lastDropDownItem : {}),
                }}
                onClick={(event) => onClickDataItem(event, data)}
                id={data.id}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={3} sx={styles.actionItemContentWrapper}>
                        <Typography
                          component="img"
                          src={data.iconSrc}
                          alt={data.altText}
                        />
                      </Grid>
                      <Grid item xs={9} sx={styles.actionItemContentWrapper}>
                        <Typography sx={styles.actionItemContent}>
                          {data.actionText}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            </Box>
          );
        })}
      </Menu>
      <DialogBox
        open={customModalDetails.showModal || false}
        cancelBtnHandler={customModalDetails.closeModalHandler}
        submitBtnHandler={customModalDetails.confirmDetailHandler}
        closeHandler={customModalDetails.closeModalHandler}
        titleText={customModalDetails.heading}
        description={customModalDetails.description}
        showCancelBtn
        showSubmitBtn
      />
    </>
  );
};

export default HeaderActionDropDown;
