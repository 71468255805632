import logoutIcon from '../Assets/img/logoutIcon.svg';
import { LOGIN } from '../Routes/Routes';

export const ACTION_DROPDOWN_ITEMS = [
  {
    id: 1,
    iconSrc: logoutIcon,
    actionText: 'Logout',
    altText: 'logout img',
    askConfirmation: true,
    confirmationHeading: 'Logout',
    confirmationDescription: `Are you sure you want to logout?`,
    onClickActionItem: () => {
      localStorage.clear();
      window.location.href = LOGIN;
    },
  },
];
