import React, { useState, useEffect } from 'react';
import { commonStyles } from '../../Assets/commonStyles';
import { Box } from '@mui/material';
import CustomLoader from '../../Components/Widgets/CustomLoader/CustomLoader';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';
import USPPromotionCard from '../../Components/USPPromotion/USPPromotionCard';
import axiosInstance from '../../API/axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import { USP_PROMOTIONS } from '../../Routes/Routes';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const USPPromotionAdd = ({ type }) => {
  const { allowedBrandsData } = useSelector((state) => state.promotions);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [allowedGiftCardValues, setAllowedGiftCardValues] = useState({
    minValue: '',
    maxValue: '',
    allowedValue: [],
    hasValues: false,
  });
  const [formData, setFormData] = useState({
    freeGifts: [],
    allowedBrands: [],
    name: type === 'edit' ? location?.state?.name : '',
    minTotal: type === 'edit' ? location?.state?.minTotal : '',
    maxTotal: type === 'edit' ? location?.state?.maxTotal : '',
  });

  const formBackHandler = () => {
    navigate('/usp-promotions');
  };
  useEffect(() => {
    if (type === 'edit') {
      axiosInstance
        .get(`usp/promotion/list-config?id=${location?.state?.promotionId}`)
        .then((res) => {
          if (res?.data?.status === 'Promotion details fetched successfully.') {
            setEditData(res?.data?.data);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (type === 'edit') {
      axiosInstance
        .get('all-products')
        .then((res) => {
          const { data } = res.data;
          const { freeItems, giftCards, promotionDetails } = editData;

          // Mapping free items to include product details
          // const mappedFreeItems = freeItems.map((item) => {
          //   const product = data.result.find(
          //     (product) => product.id === item.product_id
          //   );
          //   return {
          //     id: product.id,
          //     productId: item.product_id,
          //     productName: product.productName,
          //     productQty: item.product_qty,
          //     productCode: product.productCode,
          //     optionalLoyaltyPoints: item.optional_loyalty_points,
          //     groupId: parseInt(item.group_id),
          //   };
          // });
          if (editData) {
            // Updating formData with free gifts and card value
            setFormData({
              ...formData,
              // freeGifts: mappedFreeItems,
              cardValue: giftCards.card_value,
              freeGiftItemCredits: promotionDetails.free_gift_item_credits,
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching products:', error);
        });
    }
  }, [type, editData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const allowewdBrands = formData?.allowedBrands?.map(
      (data) => data?.brand_code
    );
    if (type === 'add') {
      axiosInstance
        .post('usp/promotion/create-promotion', {
          name: formData?.name,
          minTotal: parseInt(formData?.minTotal),
          maxTotal: parseInt(formData?.maxTotal),
          // freeItems: formData?.freeGifts,
          freeGiftItemCredits: parseInt(formData?.freeGiftItemCredits),
          giftCards: {
            // allowedBrands: allowewdBrands, currently not sending freeitems in usp promotions
            cardValue: formData?.cardValue,
          },
        })
        .then((res) => {
          toast.success('promotion created successfully');
          navigate(USP_PROMOTIONS);
          formBackHandler();
        })
        .catch((error) => toast.error(error?.response?.data?.message));
    } else {
      axiosInstance
        .put('usp/promotion/update-promotions', {
          id: location?.state?.promotionId,
          name: formData?.name,
          minTotal: parseInt(formData?.minTotal),
          maxTotal: parseInt(formData?.maxTotal),
          // freeItems: formData?.freeGifts,
          giftCards: {
            // allowedBrands: allowewdBrands,
            cardValue: formData?.cardValue,
          },

          freeGiftItemCredits: parseInt(formData?.freeGiftItemCredits),
        })
        .then((res) => {
          if (res?.status === 200) {
            formBackHandler();
            toast.success('promotion updated successfully ');
            navigate(USP_PROMOTIONS);
          }
        })
        .catch((error) => toast.error(error?.response?.data?.message));
    }
    setLoading(false);
  };

  const formClearHandler = () => {
    setFormData({
      // freeGifts: [],
      allowedBrands: [],
      name: '',
      minTotal: '',
      maxTotal: '',
      cardValue: 0,
      freeGiftItemCredits: 0,
    });
  };

  return (
    <Box sx={commonStyles.mainContainer}>
      {!!loading ? (
        <CustomLoader />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <CustomFormHeader
              title={
                type === 'add' ? 'Add USP Promotion' : 'Edit USP Promotion'
              }
              showSubmit
              clearBtnHandler={formClearHandler}
              backBtnHandler={formBackHandler}
              isSubmitDisbaled={
                !(
                  allowedGiftCardValues?.minValue <= formData?.cardValue &&
                  allowedGiftCardValues?.maxValue >= formData?.cardValue
                ) && !!formData?.allowedBrands.length
              }
            />
            <USPPromotionCard
              type={type}
              allowedBrandsData={allowedBrandsData}
              editData={editData}
              formData={formData}
              setFormData={setFormData}
              setLoading={setLoading}
              allowedGiftCardValues={allowedGiftCardValues}
              setAllowedGiftCardValues={setAllowedGiftCardValues}
            />
          </form>
        </>
      )}
    </Box>
  );
};

export default USPPromotionAdd;
