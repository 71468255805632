import axiosInstance from '../../API/axiosInstance';
import { getQsValues } from '../../Constants/getQsValues';
import { CUSTOMERS_API } from '../../Constants/apiEndpoints';

const getLoyaltyCustomersList = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    const response = await axiosInstance.get(`${CUSTOMERS_API}?${newQsValue}`);
    return response?.data?.data;
  } catch (err) {
    const error = JSON.stringify(err);
    throw Error(error);
  }
};

const LoyaltyCustomersService = { getLoyaltyCustomersList };

export default LoyaltyCustomersService;
