import { KINGLY_CLOUD, MIDNIGHT_GREY } from '../Constants/colors';

export const commonStyles = {
  scrollBar: {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '10px 0',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      width: '5px',
      borderRadius: '5px',
      background: KINGLY_CLOUD,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '12px',
      background: MIDNIGHT_GREY,
    },
  },
  formCardContainer: {
    margin: '5rem 20px',
    '@media screen and (max-width:769px)': {
      margin: '0',
    },
  },
  inputBoxContainer: {
    width: '95%',
    margin: '30px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'start',
    gap: '50px',
    '@media screen and (max-width:769px)': {
      flexDirection: 'column',
      gap: '0px',
    },
  },
  labelWrapper: {
    width: '200px',
    height: '40px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'start',
  },
  inputWrapper: {
    width: '100%',
    maxWidth: '500px',
  },
  label: {
    textAlign: 'right',
    width: '100%',
    lineHeight: '16px',
    // margin: 0,
  },
  lazyLoaderStyle: {
    height: '100vh',
  },
  mainContainer: {
    width: '100%',
    margin: '0 auto',
  },
  tableMainContainer: {
    width: '100%',
    margin: '0 auto',
  },
  charityLabel: {
    textAlign: 'right',
    width: '100%',
    lineHeight: '16px',
    margin: 'auto 0',
  },
};
