import { WHITE, GREY } from '../../Constants/colors';

export const styles = {
  customToolTip: {
    backgroundColor: GREY,
    color: WHITE,
    padding: '8px 10px',
    border: `1px solid ${GREY}`,
  },
  toolTipWrapper: {
    cursor: 'pointer',
  },
};
