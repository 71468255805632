import axiosInstance from '../../API/axiosInstance';
import {
  ALLOWED_BRANDS_API,
  VOUCHER_DISCOUNT_TYPE_API,
  VOUCHER_TYPE_API,
  PROMOTIONS_API,
} from '../../Constants/apiEndpoints';
import { SOMETHING_WENT_WRONG } from '../../Constants/errorMessages';
import { getQsValues } from '../../Constants/getQsValues';

const voucherType = async () => {
  try {
    return await axiosInstance.get(VOUCHER_TYPE_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const getPromotionsList = async (data) => {
  try {
    const newQsValue = getQsValues(data);
    const response = await axiosInstance.get(`${PROMOTIONS_API}?${newQsValue}`);
    return response?.data?.data;
  } catch (err) {
    const error = JSON.stringify(err);
    throw Error(error);
  }
};

const voucherDiscountType = async () => {
  try {
    return await axiosInstance.get(VOUCHER_DISCOUNT_TYPE_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const allowedBrands = async () => {
  try {
    return await axiosInstance.get(ALLOWED_BRANDS_API);
  } catch (err) {
    const error = JSON.stringify({
      type: 'error',
      message: SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const PromotionsService = {
  voucherType,
  voucherDiscountType,
  allowedBrands,
  getPromotionsList,
};
export default PromotionsService;
