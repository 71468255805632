import { WHITE, SHARK_FIN } from '../../Constants/colors';

export const styles = {
  searchContainer: {
    width: '252px',
  },
  searchInput: {
    height: '40px',
    padding: '15px 5px 15px 5px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '10px',
    backgroundColor: WHITE,
    '&::placeholder': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      color: SHARK_FIN,
    },
  },
};
