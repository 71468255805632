import { Button, Typography, Box } from '@mui/material';

import CustomLoader from '../CustomLoader/CustomLoader';
import { styles } from './CustomButtonStyles';

const CustomButton = (props) => {
  const {
    type,
    textContent,
    clickHandler,
    isLoading,
    disabled,
    endIcon,
    errorMessage,
    customStyles = {},
  } = props;
  return (
    <Box>
      {errorMessage && (
        <Typography sx={styles.errorStyle}>{errorMessage}</Typography>
      )}
      <Button
        sx={{
          ...styles.basicBtn,
          ...(type === 'detail' && styles.detailBtn),
          ...(type === 'proceed' && styles.proceedBtn),
          ...(type === 'send' && {
            ...styles.proceedBtn,
            ...styles.sendBtn,
          }),
          ...(type === 'save' && styles.saveBtn),
          ...(type === 'submit' && styles.submitBtn),
          ...(type === 'cancel' && styles.cancelBtn),
          ...customStyles,
          ...(isLoading && styles.loadingBtn),
          ...customStyles,
        }}
        variant="contained"
        disableElevation
        disableRipple
        disabled={disabled}
        onClick={clickHandler}
        endIcon={endIcon}
        type={type === 'save' ? 'submit' : 'button'}
      >
        {!isLoading ? (
          textContent
        ) : (
          <CustomLoader customStyles={styles.btnLoader} />
        )}
      </Button>
    </Box>
  );
};

export default CustomButton;
