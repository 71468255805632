import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Box, Typography, Avatar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mobileHamburger from '../../Assets/img/mobileHamburger.svg';
import HeaderActionDropDown from './HeaderActionDropDown';
import CustomLoader from '../Widgets/CustomLoader/CustomLoader';
import MobileMenu from './MobileMenu';
import UvationLogo from '../../Assets/img/logo.svg';
import { getToken } from '../../Auth/getToken';
import { stringAvatar } from '../../Utils/profileAvatar';
import { getProfileDetail } from '../../Redux/Profile/ProfileSlice';
import { styles } from './HeaderStyles';
import useWindowSize from '../../Hooks/useWindowSize';

const Header = () => {
  const { width } = useWindowSize();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { data, isLoading } = useSelector(
    (state) => state.profileDetail?.profileData
  );

  const dispatch = useDispatch();
  const isUserLoggedIn = getToken();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getProfileDetail());
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Box sx={styles.appBarContainer}>
        <Toolbar sx={styles.toolbar}>
          <Typography sx={styles.logoImage} component="img" src={UvationLogo} />

          <Typography sx={styles.logoText}>Uvation</Typography>
        </Toolbar>
        {isLoading ? (
          <Box>
            <CustomLoader />
          </Box>
        ) : (
          <Box sx={styles.endDetailContainer}>
            {width >= 769 ? (
              <>
                <Box sx={styles.profileWrapper} onClick={handleOpenUserMenu}>
                  <Avatar
                    {...stringAvatar(
                      data?.firstName
                        ?.trim()
                        .concat(' ', data?.lastName?.trim())
                    )}
                  />
                  <Box sx={styles.profileText}>
                    <Typography>{data?.firstName}</Typography>
                    <Typography sx={styles.customerType}>
                      {data?.role?.name}
                    </Typography>
                  </Box>
                  <ExpandMoreIcon sx={styles.dropDownIcon} />
                </Box>
                <HeaderActionDropDown
                  anchorElUser={anchorElUser}
                  handleCloseUserMenu={handleCloseUserMenu}
                />
              </>
            ) : (
              ''
            )}
            <Typography
              sx={styles.hamburger}
              component="img"
              src={mobileHamburger}
              onClick={() => setIsOpen(true)}
            />
            <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
          </Box>
        )}
      </Box>
    </AppBar>
  );
};
export default Header;
