import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axiosInstance from '../../API/axiosInstance';
import CustomLoader from '../../Components/Widgets/CustomLoader/CustomLoader';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';
import { commonStyles } from '../../Assets/commonStyles';
import CustomInput from '../../Components/Widgets/CustomInput/CustomInput';
import { styles } from '../../Components/LoyaltyItem/LoyaltyItemCardStyles';
import CustomLabel from '../../Components/Widgets/CustomLabel/CustomLabel';
import { toast } from 'react-toastify';

const MarketingSetting = () => {
  const [isLoading, setIsLoading] = useState();
  const [formData, setFormData] = useState({
    cloudCredit: '',
    loyalityPoints: '',
  });
  const fetchConfiguration = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/configuration?category=marketing`
      )
      .then((res) => {
        setFormData({
          cloudCredit: parseInt(res?.data?.data?.cloudCreditsPreSignup),
          loyalityPoints: parseInt(res?.data?.data?.loyaltyPointsPreSignup),
        });
      });
  };
  useEffect(() => {
    fetchConfiguration();
  }, []);

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    axiosInstance
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/configuration/marketing?category=marketing`,
        {
          cloudCreditsPreSignup: formData?.cloudCredit,
          loyaltyPointsPreSignup: formData?.loyalityPoints,
        }
      )
      .then((res) => {
        toast.success(res?.data?.message);
      });
    // setIsLoading(false);
  };

  const formClearHandler = () => {};

  const formBackHandler = () => {
    navigate();
  };

  const changeHandler = (name, value) => {
    if (name === 'cloudCredit') {
      setFormData({ ...formData, cloudCredit: parseInt(value) });
    } else if (name === 'loyalityPoints') {
      setFormData({ ...formData, loyalityPoints: parseInt(value) });
    }
  };
  return (
    <Box sx={commonStyles.mainContainer}>
      {isLoading && <CustomLoader />}
      {!isLoading && (
        <Box sx={styles.formCardContainer}>
          <form action="" onSubmit={submitHandler}>
            <CustomFormHeader
              title="Edit Marketing Points"
              clearBtnHandler={formClearHandler}
              backBtnHandler={formBackHandler}
              showSubmit
            />
            <Box sx={styles.inputBoxContainer}>
              <Box sx={styles.labelWrapper}>
                <CustomLabel labelText="USP Credits Points" isRequired />
              </Box>
              <Box sx={styles.inputWrapper}>
                <CustomInput
                  name="cloudCredit"
                  type="number"
                  placeholder="Number of USP Credits Points"
                  fullWidth
                  value={formData?.cloudCredit}
                  setValue={changeHandler}
                  maxLength={40}
                  inputInfo="Sign up USP credits."
                />
              </Box>
            </Box>
            <Box sx={styles.inputBoxContainer}>
              <Box sx={styles.labelWrapper}>
                <CustomLabel labelText="Loyality Points" isRequired />
              </Box>
              <Box sx={styles.inputWrapper}>
                <CustomInput
                  name="loyalityPoints"
                  type="number"
                  placeholder="Number of Loyality Points"
                  fullWidth
                  value={formData?.loyalityPoints}
                  setValue={changeHandler}
                  maxLength={40}
                  inputInfo="Sign up loyality points."
                />
              </Box>
            </Box>
            <Box sx={styles.inputBoxContainer}>
              <p style={{ fontFamily: 'IBM Plex Sans' }}>
                <b> Description: </b>
                Utilize these inputs to configure loyalty points and cloud
                credit points for user sign-ups. Once a user enters their email
                in the Call to Action (CTA) form on our marketing website
                (uvation.com) and sign-up using that email, they will receive
                loyalty points and cloud credit points for their account.
              </p>
            </Box>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default MarketingSetting;
