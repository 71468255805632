//  function to getStatus value
export const getStatus = (status) => {
  let statusValue = null;

  if (status === '1') {
    statusValue = 'true';
  } else if (status === '2') {
    statusValue = 'false';
  }
  return statusValue;
};
