import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomLoader from '../../Components/Widgets/CustomLoader/CustomLoader';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';
import { commonStyles } from '../../Assets/commonStyles';
import { CLOUD_CREDITS } from '../../Routes/Routes';
import CustomInput from '../../Components/Widgets/CustomInput/CustomInput';
import { styles } from '../../Components/LoyaltyItem/LoyaltyItemCardStyles';
import CustomLabel from '../../Components/Widgets/CustomLabel/CustomLabel';

const CloudCreditInner = () => {
  const [isLoading, setIsLoading] = useState();
  const [creditPoint, setCreditPoint] = useState();

  const navigate = useNavigate();
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoading(false);
  };

  const formClearHandler = () => {
    setCreditPoint(0);
  };

  const formBackHandler = () => {
    navigate(CLOUD_CREDITS);
  };

  const changeHandler = (name, value) => {
    setCreditPoint(value);
  };
  return (
    <Box sx={commonStyles.mainContainer}>
      {isLoading && <CustomLoader />}
      {!isLoading && (
        <>
          <CustomFormHeader
            title="Edit Loyalty Campaign"
            clearBtnHandler={formClearHandler}
            backBtnHandler={formBackHandler}
            showSubmit
          />
          <Box sx={styles.formCardContainer}>
            <form action="" onSubmit={submitHandler}>
              <Box sx={styles.inputBoxContainer}>
                <Box sx={styles.labelWrapper}>
                  <CustomLabel labelText="USP Credit Points" isRequired />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <CustomInput
                    id="cloudCredit"
                    name="cloudCredit"
                    type="number"
                    placeholder="USP Credit Points"
                    fullWidth
                    value={creditPoint}
                    setValue={changeHandler}
                    maxLength={40}
                    inputInfo="Enter the USP credits points."
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CloudCreditInner;
