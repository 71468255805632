export const styles = {
  formCardContainer: {
    margin: '5rem 20px',
  },
  inputBoxContainer: {
    width: '95%',
    margin: '30px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    gap: '30px',
    '@media screen and (max-width:769px)': {
      flexDirection: 'column',
      gap: '0px',
    },
  },
  labelWrapper: {
    width: '150px',
    height: '40px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'start',
  },
  inputWrapper: {
    width: '100%',
    maxWidth: '500px',
    display: 'flex',
    gap: '20px',
  },
};
