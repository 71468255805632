import { WHITE, MARIGOLD, PLANTER, CHI_GONG } from '../../../Constants/colors';

export const styles = {
  statusItemContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: {
      lg: 'start',
    },
  },
  rightAlign: {
    justifyContent: {
      lg: 'flex-end',
    },
  },
  centerAlign: {
    justifyContent: {
      lg: 'center',
    },
  },
  statusIndicator: {
    cursor: 'pointer',
  },
  menuContainer: {
    '.MuiMenu-paper': {
      background: WHITE,
      boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.16)',
      minWidth: '152px',
      minHeight: '50px',
      boxSizing: 'border-box',
      marginRight: '30px',
      borderRadius: '8px',
    },
    '.MuiList-root': {
      minHeight: 'inherit',
      justifyContent: 'start',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      boxSizing: 'border-box',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    marginRight: '50px',
  },
  statusIconContainer: {
    width: '20px',
    cursor: 'pointer',
    marginLeft: {
      lg: '20px',
      xl: '30px',
    },
    boxSizing: 'borderBox',
  },
  menuItem: {
    height: '30px',
    boxSizing: 'border-box',
    paddingLeft: '12px',
    marginBottom: '5px',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: MARIGOLD,
    },
  },
  actionButtonContainer: {
    width: 'auto',
  },
  chipStyle: {
    borderRadius: '5px',
    minWidth: '73px',
    fontWeight: '600',
    color: WHITE,
  },
  activeStyle: {
    backgroundColor: PLANTER,
  },
  inActiveStyle: {
    backgroundColor: CHI_GONG,
  },
};
