import { Navigate } from 'react-router-dom';

import { getToken } from '../Auth/getToken';
import { LOGIN, DASHBOARD } from './Routes';

const DefaultRoute = () => {
  const tokenCheck = getToken();
  return tokenCheck ? <Navigate to={DASHBOARD} /> : <Navigate to={LOGIN} />;
};

export default DefaultRoute;
