import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ProfileService from './ProfileService';

const initialState = {
  profileData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getProfileDetail = createAsyncThunk(
  'profile/detail',
  async (_, thunkAPI) => {
    try {
      const response = await ProfileService.getProfileDetail();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetDetail: (state) => {
      state.profileData.isLoading = false;
      state.profileData.isError = false;
      state.profileData.isSuccess = false;
      state.profileData.data = null;
      state.profileData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetail.pending, (state) => {
        state.profileData.isLoading = true;
        state.profileData.isError = false;
        state.profileData.isSuccess = false;
        state.profileData.data = null;
        state.profileData.message = '';
      })
      .addCase(getProfileDetail.rejected, (state, action) => {
        state.profileData.isLoading = false;
        state.profileData.isError = true;
        state.profileData.isSuccess = false;
        state.profileData.data = null;
        state.profileData.message = action.payload;
      })
      .addCase(getProfileDetail.fulfilled, (state, action) => {
        state.profileData.isLoading = false;
        state.profileData.isError = false;
        state.profileData.isSuccess = true;
        state.profileData.data = action.payload?.data;
        state.profileData.message = action.payload?.message;
      });
  },
});

export const { resetDetail } = profileSlice.actions;
export default profileSlice.reducer;
