import { LOGIN_API } from '../../Constants/apiEndpoints';
import { setItem } from '../../Services/localStorageService';
import { SOMETHING_WENT_WRONG } from '../../Constants/errorMessages';
import { getErrorMessageAndCode } from '../../Services/helperService';
import { UvationAPI } from '../../API/axios';

const loginWithCred = async (creadPayload) => {
  try {
    const response = await UvationAPI.post(LOGIN_API, creadPayload);
    setItem('loginToken', response.headers.authorization);
    return response;
  } catch (err) {
    const { code, message } = getErrorMessageAndCode(err);
    const error = JSON.stringify({
      type: 'error',
      message: code === 'INVALID_USER_LOGIN' ? message : SOMETHING_WENT_WRONG,
    });
    throw Error(error);
  }
};

const LoginService = { loginWithCred };

export default LoginService;
