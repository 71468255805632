import { Suspense } from 'react';
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import './App.scss';
import DefaultRoute from './Routes/DefaultRoute';
import PrivateRoute from './Routes/PrivateRoute';
import PublicRoute from './Routes/PublicRoute';
import Layout from './Components/Layout/Layout';
import CustomLoader from './Components/Widgets/CustomLoader/CustomLoader';
import { WILDCARD } from './Routes/Routes';
import { privateRoutes } from './Routes/PrivateRoutes';
import { publicRoutes } from './Routes/PublicRoutes';
import { getToken } from './Auth/getToken';
import { commonStyles } from './Assets/commonStyles';

const App = ({ history }) => {
  const isuserLoggedIn = Boolean(getToken());
  const { isLoading, isError } = useSelector(
    (state) => state.loginDetail?.loginData
  );

  return (
    <Suspense
      fallback={
        <Box sx={commonStyles.lazyLoaderStyle}>
          <CustomLoader />
        </Box>
      }
    >
      <HistoryRouter history={history}>
        <Routes>
          {publicRoutes.map((routeDetail) => {
            return (
              <Route path="/" element={<PublicRoute />} key={routeDetail.id}>
                <Route
                  exact
                  path={routeDetail.path}
                  element={routeDetail.component}
                />
              </Route>
            );
          })}
          {isuserLoggedIn && !isLoading && !isError && (
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              {privateRoutes.map((routeDetail) => {
                return (
                  <Route
                    exact
                    path="/"
                    element={<PrivateRoute />}
                    key={routeDetail.id}
                  >
                    <Route
                      exact
                      path={routeDetail.path}
                      element={routeDetail.component}
                    />
                  </Route>
                );
              })}
            </Route>
          )}
          <Route path={WILDCARD} element={<DefaultRoute />} />
        </Routes>
      </HistoryRouter>
    </Suspense>
  );
};

export default App;
