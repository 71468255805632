import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Typography, Menu, MenuItem, Grid, Chip } from '@mui/material';

import moreVertIcon from '../../../Assets/img/moreVertIcon.svg';
import activeStatusIcon from '../../../Assets/img/activeStatusIcon.svg';
import inactiveStatusIcon from '../../../Assets/img/inactiveStatusIcon.svg';
import DialogBox from '../../DialogBox/DialogBox';
import { STATUS_OPTIONS } from '../../../Constants/constants';
import { SOMETHING_WENT_WRONG } from '../../../Constants/errorMessages';
import { styles } from './CustomStatusItemStyles';

const CustomStatusItem = (props) => {
  const {
    status,
    handler,
    id,
    confirmationModalDetail,
    statusOptions,
    toastMessage,
    tableData,
    handleDispatch,
    isShowApiError,
    align,
    isLoading,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalDescription, setConfirmationModalDescription] =
    useState('');
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [updateStatusError, setUpdateStatusError] = useState('');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const handleClickMenuItem = (statusDetail) => {
    setUpdateStatusError('');
    setShowConfirmationModal(true);
    setConfirmationModalDescription(
      statusDetail.status
        ? confirmationModalDetail.activeDescription
        : confirmationModalDetail.inActiveDescription
    );
    handleClose();
  };

  const handleSubmitConfirmationModal = async () => {
    setUpdateStatusLoading(true);
    const response =
      handler && handler.onClick && (await handler.onClick(id || 0, tableData));
    if (!response.error) {
      toast.success(
        !toastMessage ? response.data?.data?.message : toastMessage
      );
      handleDispatch();
    }
    if (response.error) {
      setUpdateStatusError(
        (isShowApiError && response.error?.data?.message) ||
          SOMETHING_WENT_WRONG
      );
    }
    setUpdateStatusLoading(false);
    !response.error && setShowConfirmationModal(false);
  };

  return (
    <Box>
      <Box
        sx={{
          ...styles.statusItemContainer,
          ...(align === 'right'
            ? styles.rightAlign
            : align === 'center' && styles.centerAlign),
        }}
      >
        <Chip
          sx={{
            ...styles.chipStyle,
            ...(status ? styles.activeStyle : styles.inActiveStyle),
          }}
          label={status ? 'Active' : 'Inactive'}
        />
        <Box sx={styles.statusIconContainer} onClick={handleClick}>
          <Typography
            component="img"
            src={moreVertIcon}
            sx={styles.statusIndicator}
          />
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles.menuContainer}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {(statusOptions || STATUS_OPTIONS)
          .filter((filterOption) => filterOption.status !== status)
          .map((option) => (
            <Box key={option?.id} sx={{ width: '100%' }}>
              <MenuItem
                key={STATUS_OPTIONS.id}
                onClick={() => handleClickMenuItem(option)}
                sx={styles.menuItem}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={option.hideIcon ? 1 : 3}>
                        {!option.hideIcon &&
                          (option.status ? (
                            <Typography
                              component="img"
                              src={activeStatusIcon}
                            />
                          ) : (
                            <Typography
                              component="img"
                              src={inactiveStatusIcon}
                            />
                          ))}
                      </Grid>
                      <Grid item xs={9}>
                        <Typography sx={styles.menuItemText}>
                          {option.heading}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            </Box>
          ))}
      </Menu>
      {showConfirmationModal && (
        <DialogBox
          open={showConfirmationModal}
          isSubmitLoading={updateStatusLoading}
          cancelBtnHandler={toggleConfirmationModal}
          submitBtnHandler={handleSubmitConfirmationModal}
          closeHandler={toggleConfirmationModal}
          titleText={confirmationModalDetail.title}
          description={confirmationModalDescription}
          showCancelBtn
          showSubmitBtn
          errorMessage={updateStatusError}
        />
      )}
    </Box>
  );
};

export default CustomStatusItem;
