import { DocumentBlank } from "@carbon/icons-react";
import React from "react";

const NoDataInTable = ({ background }) => {
  return (
    <section className="no-data" style={{ background }}>
      <center>
        <div>
          <DocumentBlank size={32} />
        </div>
        <h5>No data available</h5>
      </center>
    </section>
  );
};

export default NoDataInTable;
