import { FormControlLabel, RadioGroup, Radio } from '@mui/material';

import { styles } from './CustomRadioGroupStyles';

const CustomRadioGroup = (props) => {
  const { list, mappingName, mappingId, value, onChange } = props;

  return (
    <RadioGroup value={value} onChange={onChange}>
      {list?.map((item) => {
        return (
          <FormControlLabel
            sx={styles.labelStyles}
            value={item?.[mappingId]}
            label={item?.[mappingName]}
            control={<Radio />}
          />
        );
      })}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
