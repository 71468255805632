import { configureStore } from '@reduxjs/toolkit';

import promotionCategoryReducer from './PromotionCategory/PromotionCategorySlice';
import loginReducer from './Login/LoginSlice';
import promotionsReducer from './Promotions/PromotionsSlice';
import loyaltyFreeItemsReducer from './LoyaltyFreeItems/LoyaltyFreeItemsSlice';
import productReducer from './Product/ProductSlice';
import profileDetailReducer from './Profile/ProfileSlice';
import loyaltyCampaignsReducer from './LoyaltyCampaigns/LoyaltyCampaignsSlice';
import loyaltyCustomersReducer from './LoyaltyCustomers/LoyaltyCustomersSlice';
import settingsReducer from './Settings/SettingsSlice';

export const store = configureStore({
  reducer: {
    promotionCategory: promotionCategoryReducer,
    loginDetail: loginReducer,
    promotions: promotionsReducer,
    loyaltyFreeItems: loyaltyFreeItemsReducer,
    product: productReducer,
    profileDetail: profileDetailReducer,
    loyaltyCampaigns: loyaltyCampaignsReducer,
    loyaltyCustomers: loyaltyCustomersReducer,
    settings: settingsReducer,
  },
});
