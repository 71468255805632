export const LOGIN_API = 'public/login';

// promotion category
export const PROMOTION_CATEGORY_LIST = '/promotion-category';
export const PROMOTION_CATEGORY_DETAIL_API = 'v1/promotion-category/';

// loyalty item
export const LOYALTY_FREE_ITEM_API = '/loyalty-free-item';

// voucherify api
export const VOUCHER_TYPE_API = 'master-data/voucher_type';
export const VOUCHER_DISCOUNT_TYPE_API = 'master-data/voucher_discount_type';

// giftBit api
export const ALLOWED_BRANDS_API = 'allowed-brands';

// file upload
export const FILE_UPLOAD_API = 'file-upload';
export const FILE_DOWNLOAD_API = 'public/file-download';
export const FILE_DELETE_API = 'file-delete';

// promotions
export const PROMOTIONS_API = 'promotion';

// referral
export const REFERRALS_API = 'referral-customers';

// product
export const PRODUCTS_API = 'product';

// profileDetail api
export const PROFILE_DETAIL_API = 'profile';

// loyalty campaigns
export const LOYALTY_CAMPAIGN_API = 'loyalty-campaign';

// customers
export const CUSTOMERS_API = 'list/customer';

// settings
export const SETTINGS_CONFIGURATION_API = 'configuration';
export const SETTINGS_CALCULATE_PROMOTION_API =
  'master-data/promotion_calculation_option';
export const SETTINGS_ORDER_STATUS_API = 'master-data/order_status';
export const SETTINGS_APPLY_VOUCHER_API =
  'master-data/promotion_voucher_apply_option';
export const SETTINGS_CHARITY_LIST_API = 'search-charity';
export const SETTINGS_CHARITY_API = 'charity';
export const SETTINGS_ALLOWED_CHARITY_LIST_API = 'charities';
export const USP_CLOUD_CREDIT_CONFIGURATION_API =
  'configuration/usp?category=usp';
export const FETCH_USP_CONFIGURATION_EXISTING_SETPOINTS_API =
  'configuration?category=usp';
export const EDIT_CUSTOMER_CLOUD_CREDIT_BALANCE = 'edit-cloud-credits/customer';

// dashboard
export const DASHBOARD_PROMOTION_COUNT_API = 'count/promotion';
export const DASHBOARD_REFERRAL_COUNT_API = 'count/total-referrals';
export const DASHBOARD_LOYALTY_POINTS_API = 'count/loyalty-points-gained';
export const DASHBOARD_CHARITY_API = 'stats/charities-donation';
