import React, { useEffect, useState } from 'react';
import axiosInstance from '../../API/axiosInstance';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  Pagination,
  TableCell,
  Button,
  Loading,
  Modal,
} from '@carbon/react';
import NoDataInTable from '../../Components/NoDataTable/NoDataInTable';
import { Edit } from '@carbon/icons-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  MARKETPLACE_MEMBERSHIP_ADD,
  MARKETPLACE_MEMBERSHIP_EDIT,
  MARKETPLACE_MEMBERSHIP,
} from '../../Routes/Routes';

const MarketplaceMembership = () => {
  const [membershipData, setMembershipData] = useState({
    data: null,
    page: 1,
    rowsPerPage: 10,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedMembershipID, setSelectedMembershipID] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchMembershipData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}marketplace/memberships?limit=${
          membershipData?.rowsPerPage
        }&skip=${(membershipData?.page - 1) * membershipData?.rowsPerPage}`
      );
      setMembershipData({
        ...membershipData,
        data: response?.data?.data,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setButtonStatus(!buttonStatus);
  };

  useEffect(() => {
    fetchMembershipData();
  }, [membershipData?.page, membershipData?.rowsPerPage]);

  const tableHeaders = [
    { key: 'id', header: 'Id', type: 'number' },
    { key: 'name', header: 'Name', type: 'string' },
    { key: 'membership_code', header: 'Membership Code', type: 'number' },
    { key: 'createdAt', header: 'Created Date', type: 'string' },
    { key: 'id', header: 'Edit', type: 'button' },
    { key: 'id', header: 'Status', type: 'button' },
  ];

  const Wrapper = styled.div`
    padding: 2rem;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      justify-content: space-between;
    }
  `;

  const handlePagination = (e) => {
    setMembershipData({
      ...membershipData,
      rowsPerPage: e.pageSize,
      page: e.page,
    });
  };

  const handleStatusChange = (id) => {
    setIsModalOpen(true);
    setSelectedMembershipID(id);
  };

  const handleModalYes = () => {
    setIsModalOpen(false);
    updateMembershipStatus(selectedMembershipID);
  };

  const updateMembershipStatus = async (id) => {
    try {
      const membershipToUpdate = membershipData.data.result.find(
        (member) => member.id === id
      );

      if (membershipToUpdate) {
        const formattedID = String(id);

        const response = await axiosInstance.put(
          `${process.env.REACT_APP_API_BASE_URL}marketplace/membership/status/${formattedID}`,
          { isActive: !membershipToUpdate.isActive }
        );

        // Update membership data state after successful PUT request
        const updatedData = membershipData.data.result.map((member) => {
          if (member.id === id) {
            return { ...member, isActive: !membershipToUpdate.isActive };
          }
          return member;
        });

        setMembershipData({
          ...membershipData,
          data: { ...membershipData.data, result: updatedData },
        });

        navigate(MARKETPLACE_MEMBERSHIP);
        toast.success(response.data.message);
      } else {
        console.error('Membership to update not found');
      }
    } catch (error) {
      console.error('Error updating membership status:', error);
      toast.error('An error occurred while updating membership status');
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <h4>Marketplace Membership</h4>
        <Button as={Link} to={MARKETPLACE_MEMBERSHIP_ADD} size="md">
          Add New Membership
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : membershipData.data?.result?.length ? (
        <DataTable
          useZebraStyles
          experimentalAutoAlign
          overflowMenuOnHover={false}
          headers={tableHeaders}
          rows={membershipData?.data?.result || []}
          isSortable
        >
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
          }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader
                        colSpan={header.colSpan}
                        key={header.key}
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={String(row.id)} {...getRowProps({ row })}>
                      {row.cells.map((cell, index) => (
                        <TableCell
                          key={cell.id}
                          {...getTableContainerProps({ cell })}
                        >
                          {cell?.info?.header === tableHeaders[index]?.key &&
                          !!cell.value ? (
                            tableHeaders[index]?.type === 'date' ? (
                              moment(cell.value).format('YYYY-MM-DD - HH:mm:ss')
                            ) : tableHeaders[index]?.header === 'Edit' ? (
                              <Button
                                as={Link}
                                to={`/marketplace/membership/${cell.value}`}
                                hasIconOnly
                                iconDescription="Edit"
                                size="sm"
                                value={cell.value}
                                renderIcon={() => <Edit size={16} />}
                              />
                            ) : tableHeaders[index]?.header === 'Status' ? (
                              <Button
                                style={{
                                  backgroundColor:
                                    membershipData.data.result.find(
                                      (member) => member.id === row.id
                                    ).isActive
                                      ? '#2C9D05' // Active color
                                      : '#C20000', // Inactive color
                                  // borderRadius: '10%',
                                  // width: '30px',
                                  // fontFamily: 'Roboto',
                                  // color: 'white',
                                  // fontSize: '1rem',
                                  // fontWeight: '550',
                                  borderRadius: '5px',
                                  width: '60px',
                                  height: '24px !important', // Adjusted height
                                  fontFamily: 'Roboto',
                                  color: 'white',
                                  fontSize: '14px', // Reduced font size
                                  fontWeight: 'Bold',
                                  margin: '10px 0px 10px',
                                }}
                                onClick={() => handleStatusChange(cell.value)}
                              >
                                {membershipData.data.result.find(
                                  (member) => member.id === row.id
                                ).isActive
                                  ? 'Active'
                                  : 'Inactive'}
                              </Button>
                            ) : (
                              cell.value
                            )
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      ) : (
        <NoDataInTable background={'#f4f4f4'} />
      )}
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={handlePagination}
        page={membershipData?.page}
        pageSize={membershipData?.rowsPerPage}
        pageSizes={[1, 5, 10, 20, 30, 40, 50]}
        size="md"
        totalItems={membershipData?.data?.count}
      />
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          primaryButtonText="Yes"
          secondaryButtonText="No"
          onRequestClose={() => setIsModalOpen(false)}
          onRequestSubmit={handleModalYes}
          danger
          modalHeading="Are you sure you want to change the status ?"
        ></Modal>
      )}
    </Wrapper>
  );
};

export default MarketplaceMembership;
