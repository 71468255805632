/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import { Box, Checkbox, Typography } from '@mui/material';

import CustomButton from '../CustomButton/CustomButton';
import CustomStatusItem from '../CustomStatusItem/CustomStatusItem';
import CustomToolTip from '../../CustomToolTip/CustomToolTip';
import CustomDeleteItem from '../CustomDeleteItem/CustomDeleteItem';
import EditIcon from '../../../Assets/img/edit.svg';
import {
  StyledTableRow,
  StyledTableCell,
} from '../CustomTable/CustomTableStyles';
import { numberWithCommas } from '../../../Services/helperService';
import { styles } from './CustomTableRowStyles';

const CustomTableRow = (props) => {
  const { metaData, tableRowData, selected, setSelected, handleDispatch } =
    props;

  const handleCheckbox = (id) => {
    if (
      selected?.find((selectValue) => selectValue?.[id] === tableRowData?.[id])
    ) {
      setSelected(
        selected?.filter(
          (selectValue) => selectValue?.[id] !== tableRowData?.[id]
        )
      );
    } else {
      setSelected([...selected, tableRowData]);
    }
  };

  const getTableCell = (metaItem) => {
    switch (metaItem?.colType) {
      case 'text':
        return <Typography>{tableRowData?.[metaItem?.mappingKey]}</Typography>;
      case 'price':
        return (
          <Typography>
            {numberWithCommas(
              parseFloat(tableRowData?.[metaItem?.mappingKey]).toFixed(2)
            )}
          </Typography>
        );
      case 'numberWithCommas':
        return (
          <Typography>
            {numberWithCommas(tableRowData?.[metaItem?.mappingKey])}
          </Typography>
        );
      case 'capitaliseText':
        return (
          <Typography sx={styles.capitaliseText}>
            {tableRowData?.[metaItem?.mappingKey]}
          </Typography>
        );
      case 'date':
        return (
          <Typography>
            {tableRowData?.[metaItem?.mappingKey]
              ? moment(tableRowData?.[metaItem?.mappingKey]).format(
                  'MMM DD, YYYY hh:mm:ss A'
                )
              : ''}
          </Typography>
        );
      case 'image':
        return (
          <Box>
            <Typography
              sx={styles.imageStyle}
              component="img"
              src={tableRowData[metaItem.mappingKey]}
            />
          </Box>
        );
      case 'edit':
        return (
          <CustomToolTip title="Edit">
            <Typography
              component="img"
              src={EditIcon}
              sx={styles.editIcon}
              onClick={() =>
                metaItem?.handler?.onClick(tableRowData?.[metaItem?.mappingId])
              }
            />
          </CustomToolTip>
        );
      case 'delete':
        return (
          <CustomDeleteItem
            titleText={metaItem?.titleText}
            description={metaItem?.description}
            id={tableRowData?.[metaItem?.mappingId]}
            handler={metaItem?.handler}
            toastMessage={metaItem?.toastMessage}
            handleDispatch={handleDispatch}
          />
        );
      case 'button':
        return (
          <CustomButton
            type={metaItem?.buttonType}
            textContent={metaItem?.buttonText}
            clickHandler={() => metaItem?.handler?.onClick}
          />
        );
      case 'status':
        return (
          <CustomStatusItem
            status={tableRowData?.[metaItem?.mappingKey]}
            handler={metaItem?.handler}
            id={tableRowData?.[metaItem?.mappingId]}
            confirmationModalDetail={metaItem?.confirmationModalDetail}
            statusOptions={metaItem?.statusOptions}
            toastMessage={metaItem?.toastMessage}
            tableData={tableRowData}
            handleDispatch={handleDispatch}
            isShowApiError
            align={metaItem?.align}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            color="primary"
            checked={Boolean(
              selected?.find(
                (value) =>
                  value?.[metaItem?.mappingId] ===
                  tableRowData?.[metaItem?.mappingId]
              )
            )}
            onChange={() => handleCheckbox(metaItem?.mappingId)}
          />
        );
      case 'qr':
        return (
          <Typography
            component="img"
            src={'/qricon.png'}
            sx={styles.editIcon}
            onClick={() =>
              metaItem?.handler?.onClick(tableRowData?.[metaItem?.mappingId])
            }
          />
        );
      default:
        return null;
    }
  };
  return (
    <StyledTableRow>
      {metaData?.map((item) => (
        <StyledTableCell
          align={item?.align || 'left'}
          key={item?.keyId}
          width={item?.width}
        >
          {getTableCell(item)}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default CustomTableRow;
