export const styles = {
  imageStyle: {
    maxWidth: '125px',
    maxHeight: '72px',
    borderRadius: '0px',
    objectFit: 'cover',
  },
  editIcon: {
    cursor: 'pointer',
  },
  deleteStyle: {
    width: '26px',
    height: '26px',
  },
  capitaliseText: {
    textTransform: 'capitalize',
  },
};
