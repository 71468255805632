import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import PromotionCategoryService from './PromotionCategoryService';

const initialState = {
  promotionCategoryListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getPromotionCategoryList = createAsyncThunk(
  'promotionCategory/list',
  async (data, thunkAPI) => {
    try {
      return await PromotionCategoryService.getPromotionCategoryList(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const promotionCategorySlice = createSlice({
  name: 'promotionCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPromotionCategoryList.pending, (state) => {
        state.promotionCategoryListData.isLoading = true;
        state.promotionCategoryListData.isError = false;
        state.promotionCategoryListData.isSuccess = false;
        state.promotionCategoryListData.data = null;
        state.promotionCategoryListData.message = '';
      })
      .addCase(getPromotionCategoryList.rejected, (state, action) => {
        state.promotionCategoryListData.isLoading = false;
        state.promotionCategoryListData.isError = true;
        state.promotionCategoryListData.isSuccess = false;
        state.promotionCategoryListData.data = null;
        state.promotionCategoryListData.message = JSON.parse(
          action.payload
        ).message;
      })
      .addCase(getPromotionCategoryList.fulfilled, (state, action) => {
        state.promotionCategoryListData.isLoading = false;
        state.promotionCategoryListData.isError = false;
        state.promotionCategoryListData.isSuccess = true;
        state.promotionCategoryListData.data = action.payload;
        state.promotionCategoryListData.message = action.payload;
      });
  },
});

export default promotionCategorySlice.reducer;
