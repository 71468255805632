import { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';

import CustomLoader from '../Widgets/CustomLoader/CustomLoader';
import {
  INVALID_FILE_TYPE,
  FILE_TOO_LARGE,
  SOMETHING_WENT_WRONG,
} from '../../Constants/errorMessages';
import {
  performImageUpload,
  performImageDelete,
} from '../../Services/performImageUpload';
import { FILE_DOWNLOAD_API } from '../../Constants/apiEndpoints';
import { styles } from './CustomImageUploadStyles';

const CustomImageUpload = (props) => {
  const {
    name,
    value,
    setValue,
    inputInfo,
    hideMessage,
    errorMsg,
    fileSize,
    prevImageId,
    imageErrorHandler,
    type,
    id,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const hiddenFileInput = useRef(null);

  const handelUploadBtnClick = () => {
    hiddenFileInput.current.click();
  };

  const setNewImageHandler = (imageData = '') => {
    if (value !== prevImageId && value) {
      hiddenFileInput.current.value = null;
      performImageDelete(value);
    }
    setValue(name, imageData?.id);
  };

  const setPrevImageHandler = () => {
    if (prevImageId) {
      setValue(name, prevImageId);
    }
  };

  const verifyUploadedFile = (file) => {
    const pattern = /image-*/;
    if (file) {
      if (!file.type.match(pattern)) {
        setNewImageHandler();
        imageErrorHandler(INVALID_FILE_TYPE);
        return false;
      }
      if (file.size > fileSize) {
        setNewImageHandler();
        imageErrorHandler(FILE_TOO_LARGE);
        return false;
      }
      return true;
    }
    return false;
  };

  const changeHandler = async (e) => {
    setIsLoading(true);
    imageErrorHandler('');
    const fileUploaded = e.target.files[0];
    if (verifyUploadedFile(fileUploaded)) {
      try {
        const res = await performImageUpload(fileUploaded);
        setNewImageHandler(res?.data?.data?.data[0]);
      } catch (err) {
        const errorData = err.response?.data;
        imageErrorHandler(
          errorData?.code === 'INVALID_FILE_FORMAT'
            ? INVALID_FILE_TYPE
            : SOMETHING_WENT_WRONG
        );
      }
    }
    setIsLoading(false);
  };

  const displayImageHandler = () => {
    if (type === 'detail' && value) {
      return (
        <Typography
          sx={styles.image}
          component="img"
          src={`${process.env.REACT_APP_API_BASE_URL}${FILE_DOWNLOAD_API}/${value}`}
        />
      );
    } else if (type === 'detail' && !value) {
      return (
        <>
          <CameraAltIcon sx={styles.imageIcon} />
          <Typography>No image Uploaded</Typography>
        </>
      );
    } else if (isLoading) {
      return <CustomLoader />;
    } else if (value) {
      return (
        <>
          <CloseIcon
            sx={styles.crossBtn}
            onClick={(e) => {
              e.stopPropagation();
              setNewImageHandler();
            }}
          />
          <Typography
            sx={styles.image}
            component="img"
            src={`${process.env.REACT_APP_API_BASE_URL}${FILE_DOWNLOAD_API}/${value}`}
          />
        </>
      );
    }
    return (
      <>
        {type === 'edit' && prevImageId && (
          <ReplayIcon
            sx={styles.crossBtn}
            onClick={(e) => {
              e.stopPropagation();
              setPrevImageHandler();
            }}
          />
        )}
        <CameraAltIcon sx={styles.imageIcon} />
        <Typography>Browse to find image</Typography>
      </>
    );
  };

  return (
    <>
      <Box
        sx={styles.uploadBtn}
        onClick={isLoading || type === 'detail' ? null : handelUploadBtnClick}
      >
        {displayImageHandler()}
      </Box>
      <Typography
        component="input"
        type="file"
        ref={hiddenFileInput}
        accept="image/png, image/jpeg, image/gif"
        onChange={changeHandler}
        name="bannerImageId"
        id={id}
        sx={styles.hiddenInput}
      />

      {!hideMessage && (
        <Box sx={styles.bottomContainer}>
          <Typography
            sx={{
              ...styles.bottomContainerMsg,
              ...(errorMsg && styles.errorMsg),
              ...(!errorMsg && inputInfo && styles.infoMsg),
            }}
          >
            {errorMsg || inputInfo}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CustomImageUpload;
