import { useState } from 'react';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';

import CustomToolTip from '../../CustomToolTip/CustomToolTip';
import DialogBox from '../../DialogBox/DialogBox';
import { SOMETHING_WENT_WRONG } from '../../../Constants/errorMessages';
import { styles } from './CustomDeleteItemStyles';

const CustomDeleteItem = (props) => {
  const { titleText, description, id, handler, toastMessage, handleDispatch } =
    props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState('');

  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  };

  const handleSubmitClick = async () => {
    setIsDeleteLoading(true);
    setIsDeleteError('');
    const response =
      handler && handler?.onClick && (await handler.onClick(id || 0));
    if (!response.error) {
      toast.success(
        !toastMessage ? response.data?.data?.message : toastMessage
      );
      handleDispatch();
      setShowConfirmationModal(false);
    } else {
      setIsDeleteError(response.error?.data?.message || SOMETHING_WENT_WRONG);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <CustomToolTip title="Delete">
        <Delete sx={styles.deleteStyle} onClick={handleDeleteClick} />
      </CustomToolTip>
      <DialogBox
        open={showConfirmationModal}
        isSubmitLoading={isDeleteLoading}
        cancelBtnHandler={toggleConfirmationModal}
        submitBtnHandler={handleSubmitClick}
        closeHandler={toggleConfirmationModal}
        titleText={titleText}
        description={description}
        showCancelBtn
        showSubmitBtn
        errorMessage={isDeleteError}
      />
    </>
  );
};

export default CustomDeleteItem;
