import { Box, Grid } from '@mui/material';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import CustomLoader from '../Widgets/CustomLoader/CustomLoader';
import { styles } from './LayoutStyes';

const Layout = (props) => {
  const { children } = props;

  return (
    <Box>
      <Header />
      <ToastContainer theme="colored" />
      <Box sx={styles.layoutContainer}>
        <Sidebar />
        <Grid container sx={styles.childComponent}>
          <Suspense
            fallback={
              <Box sx={styles.layoutSuspense}>
                <CustomLoader />
              </Box>
            }
          >
            {children}
          </Suspense>
        </Grid>
      </Box>
    </Box>
  );
};

export default Layout;
