import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import SettingsService from './SettingsService';

const initialState = {
  calculatePromotionByData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  orderStatusData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  applyVoucherData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
  allowedCharityData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const calculatePromotionBy = createAsyncThunk(
  'settings/calculate-promotion-by',
  async (_, { rejectWithValue }) => {
    try {
      const response = await SettingsService.calculatePromotionBy();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const orderStatus = createAsyncThunk(
  'settings/order-status',
  async (_, { rejectWithValue }) => {
    try {
      const response = await SettingsService.orderStatus();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const applyVoucher = createAsyncThunk(
  'settings/apply-voucher',
  async (_, { rejectWithValue }) => {
    try {
      const response = await SettingsService.applyVoucher();
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const allowedCharity = createAsyncThunk(
  'settings/allowed-charity',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingsService.allowedCharity(data);
      return response?.data;
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return rejectWithValue(message);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetCalculatePromotionBy: (state) => {
      state.calculatePromotionByData.isLoading = false;
      state.calculatePromotionByData.isError = false;
      state.calculatePromotionByData.isSuccess = false;
      state.calculatePromotionByData.data = null;
      state.calculatePromotionByData.message = '';
    },
    resetOrderStatus: (state) => {
      state.orderStatusData.isLoading = false;
      state.orderStatusData.isError = false;
      state.orderStatusData.isSuccess = false;
      state.orderStatusData.data = null;
      state.orderStatusData.message = '';
    },
    resetApplyVoucher: (state) => {
      state.applyVoucherData.isLoading = false;
      state.applyVoucherData.isError = false;
      state.applyVoucherData.isSuccess = false;
      state.applyVoucherData.data = null;
      state.applyVoucherData.message = '';
    },
    resetAllowedCharity: (state) => {
      state.allowedCharityData.isLoading = false;
      state.allowedCharityData.isError = false;
      state.allowedCharityData.isSuccess = false;
      state.allowedCharityData.data = null;
      state.allowedCharityData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculatePromotionBy.pending, (state) => {
        state.calculatePromotionByData.isLoading = true;
        state.calculatePromotionByData.isError = false;
        state.calculatePromotionByData.isSuccess = false;
        state.calculatePromotionByData.data = null;
        state.calculatePromotionByData.message = '';
      })
      .addCase(calculatePromotionBy.rejected, (state, action) => {
        state.calculatePromotionByData.isLoading = false;
        state.calculatePromotionByData.isError = true;
        state.calculatePromotionByData.isSuccess = false;
        state.calculatePromotionByData.data = null;
        state.calculatePromotionByData.message = action.payload;
      })
      .addCase(calculatePromotionBy.fulfilled, (state, action) => {
        state.calculatePromotionByData.isLoading = false;
        state.calculatePromotionByData.isError = false;
        state.calculatePromotionByData.isSuccess = true;
        state.calculatePromotionByData.data = action.payload?.data;
        state.calculatePromotionByData.message = action.payload?.message;
      })
      .addCase(orderStatus.pending, (state) => {
        state.orderStatusData.isLoading = true;
        state.orderStatusData.isError = false;
        state.orderStatusData.isSuccess = false;
        state.orderStatusData.data = null;
        state.orderStatusData.message = '';
      })
      .addCase(orderStatus.rejected, (state, action) => {
        state.orderStatusData.isLoading = false;
        state.orderStatusData.isError = true;
        state.orderStatusData.isSuccess = false;
        state.orderStatusData.data = null;
        state.orderStatusData.message = action.payload;
      })
      .addCase(orderStatus.fulfilled, (state, action) => {
        state.orderStatusData.isLoading = false;
        state.orderStatusData.isError = false;
        state.orderStatusData.isSuccess = true;
        state.orderStatusData.data = action.payload?.data;
        state.orderStatusData.message = action.payload?.message;
      })
      .addCase(applyVoucher.pending, (state) => {
        state.applyVoucherData.isLoading = true;
        state.applyVoucherData.isError = false;
        state.applyVoucherData.isSuccess = false;
        state.applyVoucherData.data = null;
        state.applyVoucherData.message = '';
      })
      .addCase(applyVoucher.rejected, (state, action) => {
        state.applyVoucherData.isLoading = false;
        state.applyVoucherData.isError = true;
        state.applyVoucherData.isSuccess = false;
        state.applyVoucherData.data = null;
        state.applyVoucherData.message = action.payload;
      })
      .addCase(applyVoucher.fulfilled, (state, action) => {
        state.applyVoucherData.isLoading = false;
        state.applyVoucherData.isError = false;
        state.applyVoucherData.isSuccess = true;
        state.applyVoucherData.data = action.payload?.data;
        state.applyVoucherData.message = action.payload?.message;
      })
      .addCase(allowedCharity.pending, (state) => {
        state.allowedCharityData.isLoading = true;
        state.allowedCharityData.isError = false;
        state.allowedCharityData.isSuccess = false;
        state.allowedCharityData.data = null;
        state.allowedCharityData.message = '';
      })
      .addCase(allowedCharity.rejected, (state, action) => {
        state.allowedCharityData.isLoading = false;
        state.allowedCharityData.isError = true;
        state.allowedCharityData.isSuccess = false;
        state.allowedCharityData.data = null;
        state.allowedCharityData.message = action.payload;
      })
      .addCase(allowedCharity.fulfilled, (state, action) => {
        state.allowedCharityData.isLoading = false;
        state.allowedCharityData.isError = false;
        state.allowedCharityData.isSuccess = true;
        state.allowedCharityData.data = action.payload?.data;
        state.allowedCharityData.message = action.payload?.message;
      });
  },
});

export const {
  resetCalculatePromotionBy,
  resetOrderStatus,
  resetApplyVoucher,
  resetAllowedCharity,
} = settingsSlice.actions;

export default settingsSlice.reducer;
