import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import LoyaltyFreeItemsService from './LoyaltyFreeItemsService';

const initialState = {
  loyaltyFreeItemsListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getLoyaltyFreeItemsList = createAsyncThunk(
  'loyaltyFreeItems/list',
  async (data, thunkAPI) => {
    try {
      return await LoyaltyFreeItemsService.getLoyaltyFreeItemsList(data);
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loyaltyFreeItemsSlice = createSlice({
  name: 'loyaltyFreeItems',
  initialState,
  reducers: {
    resetDetail: (state) => {
      state.loyaltyFreeItemsListData.isLoading = false;
      state.loyaltyFreeItemsListData.isError = false;
      state.loyaltyFreeItemsListData.isSuccess = false;
      state.loyaltyFreeItemsListData.data = null;
      state.loyaltyFreeItemsListData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoyaltyFreeItemsList.pending, (state) => {
        state.loyaltyFreeItemsListData.isLoading = true;
        state.loyaltyFreeItemsListData.isError = false;
        state.loyaltyFreeItemsListData.isSuccess = false;
        state.loyaltyFreeItemsListData.data = null;
        state.loyaltyFreeItemsListData.message = '';
      })
      .addCase(getLoyaltyFreeItemsList.rejected, (state, action) => {
        state.loyaltyFreeItemsListData.isLoading = false;
        state.loyaltyFreeItemsListData.isError = true;
        state.loyaltyFreeItemsListData.isSuccess = false;
        state.loyaltyFreeItemsListData.data = null;
        state.loyaltyFreeItemsListData.message = action.payload;
      })
      .addCase(getLoyaltyFreeItemsList.fulfilled, (state, action) => {
        state.loyaltyFreeItemsListData.isLoading = false;
        state.loyaltyFreeItemsListData.isError = false;
        state.loyaltyFreeItemsListData.isSuccess = true;
        state.loyaltyFreeItemsListData.data = action.payload;
        state.loyaltyFreeItemsListData.message = action.payload;
      });
  },
});

export const { resetDetail } = loyaltyFreeItemsSlice.actions;
export default loyaltyFreeItemsSlice.reducer;
