import {
  CHINESE_GOLDFISH,
  UP_IN_SMOKE,
  LEADBELCHER,
  POT_BLACK,
} from '../../Constants/colors';
import { IBM_PLEX_SANS } from '../../Constants/fonts';

export const styles = {
  uploadBtn: {
    border: `1px solid ${LEADBELCHER}`,
    width: '200px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
  },
  imageIcon: {
    fontSize: '44px',
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  crossBtn: {
    top: '5%',
    right: '5%',
    color: POT_BLACK,
    position: 'absolute',
    zIndex: '2',
  },
  input: {
    display: 'none',
  },
  bottomContainer: {
    minHeight: '16px',
  },
  bottomContainerMsg: {
    fontFamily: IBM_PLEX_SANS,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    marginTop: '5px',
  },
  errorMsg: {
    color: CHINESE_GOLDFISH,
  },
  infoMsg: {
    color: UP_IN_SMOKE,
  },
  hiddenInput: {
    visibility: 'hidden',
  },
};
