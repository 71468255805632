import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import LoyaltyCustomersService from './LoyaltyCustomersService';

const initialState = {
  loyaltyCustomersListData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    message: '',
  },
};

export const getLoyaltyCustomersList = createAsyncThunk(
  'loyaltyCustomers/list',
  async (data, thunkAPI) => {
    try {
      return await LoyaltyCustomersService.getLoyaltyCustomersList(data);
    } catch (error) {
      const message = JSON.parse(error?.message)?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loyaltyCustomersSlice = createSlice({
  name: 'loyaltyCustomers',
  initialState,
  reducers: {
    resetDetail: (state) => {
      state.loyaltyCustomersListData.isLoading = false;
      state.loyaltyCustomersListData.isError = false;
      state.loyaltyCustomersListData.isSuccess = false;
      state.loyaltyCustomersListData.data = null;
      state.loyaltyCustomersListData.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoyaltyCustomersList.pending, (state) => {
        state.loyaltyCustomersListData.isLoading = true;
        state.loyaltyCustomersListData.isError = false;
        state.loyaltyCustomersListData.isSuccess = false;
        state.loyaltyCustomersListData.data = null;
        state.loyaltyCustomersListData.message = '';
      })
      .addCase(getLoyaltyCustomersList.rejected, (state, action) => {
        state.loyaltyCustomersListData.isLoading = false;
        state.loyaltyCustomersListData.isError = true;
        state.loyaltyCustomersListData.isSuccess = false;
        state.loyaltyCustomersListData.data = null;
        state.loyaltyCustomersListData.message = action.payload;
      })
      .addCase(getLoyaltyCustomersList.fulfilled, (state, action) => {
        state.loyaltyCustomersListData.isLoading = false;
        state.loyaltyCustomersListData.isError = false;
        state.loyaltyCustomersListData.isSuccess = true;
        state.loyaltyCustomersListData.data = action.payload;
        state.loyaltyCustomersListData.message = action.payload;
      });
  },
});

export const { resetDetail } = loyaltyCustomersSlice.actions;
export default loyaltyCustomersSlice.reducer;
