import React, { useState, useEffect } from 'react';
import { styles } from './USPPromotionCardStyles';
import { Box, FormControl } from '@mui/material';
import CustomSelect from '../Widgets/CustomSelect/CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import { getProductList } from '../../Redux/Product/ProductSlice';
import CustomLabel from '../Widgets/CustomLabel/CustomLabel';
import CustomCheckboxMultiSelect from '../Widgets/CustomSelect/CustomCheckboxMultiSelect';
import { allowedBrands } from '../../Redux/Promotions/PromotionsSlice';
import $ from 'jquery';
import CustomInput from '../Widgets/CustomInput/CustomInput';
import CustomButton from '../Widgets/CustomButton/CustomButton';
import {
  Accordion,
  AccordionItem,
  Select,
  SelectItem,
  Row,
  Column,
  Loading,
  TextInput,
  Button,
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
const USPPromotionCard = ({
  type,
  formData,
  setFormData,
  editData,
  allowedBrandsData,
  allowedGiftCardValues,
  setAllowedGiftCardValues,
}) => {
  const [displayMinMaxValue, setDisplayMinMaxValue] = useState({
    minValue: 0,
    maxValue: 0,
  });

  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => {
    return state.product?.productListData;
  });

  useEffect(() => {
    // Dispatching action to fetch product list data
    dispatch(getProductList());
  }, [dispatch]);

  const changeHandler = (name, value) => {
    let newValue = value;
    if (
      name === 'sortOrder' ||
      name === 'voucherRedemptionQuantity' ||
      name === 'cardValue'
    ) {
      newValue = value ? Math.abs(parseInt(value, 10)) * 100 : value * 100;
    } else if (
      name === 'minTotal' ||
      name === 'maxTotal' ||
      name === 'giftAmount' ||
      name === 'voucherDiscountOff' ||
      name === 'voucherGiftAmount'
    ) {
      newValue = value ? Math.abs(parseFloat(value)) : value;
    }
    setFormData((prevState) => {
      return { ...prevState, [name]: newValue };
    });
  };

  const handleDel = (index) => {
    setFormData((prevData) => {
      const updatedFreeGifts = prevData.freeGifts.filter(
        (item, i) => i !== index
      );
      return { ...prevData, freeGifts: updatedFreeGifts };
    });
  };

  const handleProductSelect = (productId) => {
    // Checking if the selected product already exists in freeItems
    const productExists = formData.freeGifts.some(
      (item) => item.productId == productId
    );

    // If the product already exists, displaying an error message or handle the case accordingly
    if (productExists) {
      console.error('Selected product already exists.');
      return; // Exit the function early
    }

    // Finding the selected product from the products list
    const selectedProduct = data.result.find((item) => item.id == productId);

    // Checking if the selected product and its properties exist and are not zero
    if (
      selectedProduct &&
      selectedProduct.productQty !== 0 &&
      selectedProduct.groupId !== 0 &&
      selectedProduct.optionalLoyaltyPoints !== 0
    ) {
      // Constructing the product object with required data
      const newProduct = {
        id: selectedProduct.id,
        productId: selectedProduct.productId,
        productName: selectedProduct.productName,
        productCode: selectedProduct.productCode,
        productQty: selectedProduct.productQty,
        optionalLoyaltyPoints: selectedProduct.optionalLoyaltyPoints,
        groupId: selectedProduct.groupId,
      };

      // Updating the formData state to include the new product
      setFormData((prevState) => ({
        ...prevState,
        freeGifts: [...prevState.freeGifts, newProduct],
      }));
    } else {
      // Displaying an error message or handle the case where the selected product or its properties are not valid
      console.error('Selected product or its properties are not valid.');
    }
  };

  const renderProductFields = () => {
    if (!data) {
      return <Loading />; // Render loading indicator while products are being fetched
    }
    return formData.freeGifts.map((product, index) => (
      <div key={index}>
        <Row>
          <Column>
            <TextInput
              value={product.productName}
              labelText="Product"
              disabled
            />
          </Column>
          <Column>
            <TextInput
              value={product.productQty}
              onChange={(event) =>
                handleFieldChange(index, 'productQty', event.target.value)
              }
              placeholder="Quantity"
              labelText="Quantity"
              invalid={false}
            />
          </Column>
          <Column>
            <TextInput
              value={product.optionalLoyaltyPoints}
              onChange={(event) =>
                handleFieldChange(
                  index,
                  'optionalLoyaltyPoints',
                  event.target.value
                )
              }
              placeholder="Optional Loyalty Points"
              labelText="Loyalty Points"
            />
          </Column>
          <Column>
            <CustomLabel labelText="Group Id" isRequired />
            <Select
              id={`groupId-${index}`}
              onChange={(event) =>
                handleFieldChange(index, 'groupId', event.target.value)
              }
              value={product.groupId || ''}
              invalid={false} // Adjust as needed
              hideLabel={true}
              placeholder="Please select Group Id"
            >
              {['--select--', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
                <SelectItem
                  key={option}
                  value={option}
                  text={option.toString()}
                />
              ))}
            </Select>
          </Column>
          <Column>
            <Button
              hasIconOnly
              renderIcon={TrashCan}
              iconDescription="Delete"
              style={{
                margin: '16px',
                backgroundColor: '#E3242B',
              }}
              onClick={() => handleDel(index)}
            />
          </Column>
        </Row>
      </div>
    ));
  };

  const handleFieldChange = (index, field, value) => {
    // Converting value to an integer if the field is 'productQty' or 'optionalLoyaltyPoints' or 'groupId'
    const updatedValue =
      field === 'productQty' ||
      field === 'optionalLoyaltyPoints' ||
      field === 'groupId'
        ? parseInt(value, 10) || 0
        : value;
    setFormData((prevData) => {
      const updatedItems = prevData.freeGifts.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: updatedValue };
        }
        return item;
      });
      return { ...prevData, freeGifts: updatedItems };
    });
  };

  useEffect(() => {
    if (allowedBrandsData?.data?.brands?.length) {
      const newData = [];
      formData?.allowedBrands?.map((item) => {
        allowedBrandsData?.data?.brands?.filter((brand) => {
          if (brand?.brand_code === item?.brand_code) {
            newData.push(brand);
          }
        });
      });
      changeHandler('allowedBrands', newData);
    }
  }, [allowedBrandsData?.data]);

  useEffect(() => {
    if (formData?.allowedBrands.length && formData?.allowedBrands?.[0]?.name) {
      const minValue = _.maxBy(
        formData?.allowedBrands,
        (item) => item.minPriceInCents
      )?.minPriceInCents;
      const maxValue = _.minBy(
        formData?.allowedBrands,
        (item) => item.maxPriceInCents
      )?.maxPriceInCents;
      const minValueDisplay = minValue / 100;
      const maxValueDisplay = maxValue / 100;
      setDisplayMinMaxValue((prevState) => {
        return {
          ...prevState,
          minValue: minValueDisplay,
          maxValue: maxValueDisplay,
        };
      });
      let allowedValue = [];
      let hasValues = false;
      formData?.allowedBrands?.forEach((item) => {
        allowedValue.push(
          ...item?.allowedPricesInCents?.filter((price) => {
            hasValues = true;
            if (minValue && maxValue) {
              if (price >= minValue && price <= maxValue) {
                return price;
              }
            } else {
              return price;
            }
          })
        );
      });
      allowedValue = _.uniq(allowedValue);
      if (allowedValue?.length) {
        if (!_.includes(allowedValue, formData?.cardValue)) {
          changeHandler('cardValue', '');
        }
      }
      setAllowedGiftCardValues((prevState) => {
        return { ...prevState, minValue, maxValue, allowedValue, hasValues };
      });
    } else {
      setAllowedGiftCardValues((prevState) => {
        return {
          ...prevState,
          minValue: '',
          maxValue: '',
          allowedValue: [],
          hasValues: false,
        };
      });
    }
  }, [formData.allowedBrands]);

  const handleFormData = (name, value) => {
    if (name === 'promotionName') {
      setFormData({ ...formData, name: value });
    } else if (name === 'minimum') {
      setFormData({ ...formData, minTotal: value });
    } else if (name === 'maximum') {
      setFormData({ ...formData, maxTotal: value });
    } else if (name === 'cardValue') {
      setFormData({ ...formData, cardValue: value * 100 });
    } else if (name === 'freeGiftItemCredits') {
      setFormData({ ...formData, freeGiftItemCredits: value });
    }
  };
  const handleFreeGiftButtonClick = () => {
    setFormData({ ...formData, freeGifts: [] });
  };
  const handleGiftBitButtonClick = () => {
    setFormData({ ...formData, allowedBrands: [] });
  };
  useEffect(() => {
    const arrayData = editData?.giftCards?.allowed_brands?.map((data) =>
      allowedBrandsData?.data?.brands?.find(
        (response) => response?.brand_code === data
      )
    );
    const objectKey = Object.keys(editData);
    if (
      objectKey.includes('giftCards') &&
      editData?.giftCards?.allowed_brands.length
    ) {
      if (!!arrayData) {
        setFormData({
          ...formData,
          allowedBrands: arrayData,
        });
      }
    }
  }, [allowedBrandsData]);

  return (
    <Box sx={styles.inputBoxContainer}>
      <Box sx={styles?.inputBoxMain}>
        <CustomLabel
          customStyles={styles.labelWrapper}
          labelText="Promotion Name"
          isRequired
        />
        <CustomInput
          value={formData?.name}
          id="promotionName"
          name="promotionName"
          type="text"
          setValue={handleFormData}
          placeholder="Enter promotion name"
          fullWidth
          maxLength={40}
          inputInfo="Enter promotion name."
        />
      </Box>
      <Box sx={styles?.inputBoxMain}>
        <CustomLabel
          customStyles={styles.labelWrapper}
          labelText="Minimum Value"
          isRequired
        />
        <CustomInput
          id="minimum"
          value={formData?.minTotal}
          name="minimum"
          type="number"
          setValue={handleFormData}
          placeholder="Enter minimum value"
          fullWidth
          maxLength={40}
          inputInfo="Enter minimum value."
        />
      </Box>
      <Box sx={styles?.inputBoxMain} id="awdawuvdy">
        <CustomLabel
          customStyles={styles.labelWrapper}
          labelText="Maximum Value"
          isRequired
        />
        <CustomInput
          id="maximum"
          value={formData?.maxTotal}
          name="maximum"
          type="number"
          setValue={handleFormData}
          placeholder="Enter maximum value"
          fullWidth
          maxLength={40}
          inputInfo="Enter maximum value."
        />
      </Box>
      <Box sx={styles?.inputBoxMain} id="awdawuvdy">
        <CustomLabel
          customStyles={styles.labelWrapper}
          labelText="Gift Card Value"
          isRequired
        />
        <CustomInput
          id="cardValue"
          value={formData?.cardValue / 100}
          name="cardValue"
          type="number"
          setValue={handleFormData}
          placeholder="Enter  value"
          fullWidth
          maxLength={40}
          inputInfo="Enter card value."
        />
      </Box>
      {/* Adding a new field for freeGiftItemCredits */}
      <Box sx={styles?.inputBoxMain} id="awdawuvdy">
        <CustomLabel
          customStyles={styles.labelWrapper}
          labelText="Free Gift Item Credits"
          isRequired
        />
        <CustomInput
          id="freeGiftItemCredits"
          value={formData?.freeGiftItemCredits}
          name="freeGiftItemCredits"
          type="number"
          setValue={handleFormData}
          placeholder="Enter a value"
          fullWidth
          maxLength={40}
          inputInfo="Enter credits "
        />
      </Box>

      {/* <CustomSelect
            data={data?.result}
            value={formData?.freeGifts}
            multiSelect={true}
            mappingId="id"
            mappingName="productName"
            dispatchFunction={getProductList}
            isInfiniteScroll
            totalCount={data?.totalCount}
            filterObject={{ type: 'simple' }}
            showSearch
            isLoading={isLoading}
            multiSelectData={formData}
            setMultiSelectData={setFormData}
            inputInfo={
              type === 'edit'
                ? 'Please reselect free items.'
                : 'Please select free items'
            }
          /> */}
      {/* <Accordion>
        <AccordionItem title="Choose GiftBit Vouchers">
          <Box sx={styles?.inputBoxMain}>
            <CustomLabel
              customStyles={styles.labelWrapper}
              labelText="Giftbit vouchers"
              isRequired
            />
            <CustomCheckboxMultiSelect
              id="allowedBrands"
              name="allowedBrands"
              values={formData?.allowedBrands}
              setValues={changeHandler}
              mappingId="brand_code"
              mappingName="name"
              data={allowedBrandsData?.data?.brands}
              isLoading={allowedBrandsData?.isLoading}
              isError={allowedBrandsData?.isError}
              dispatchFunction={allowedBrands}
            />
            <CustomButton
              type="type"
              name="giftbit"
              textContent="clear"
              disabled={!formData?.allowedBrands?.length}
              clickHandler={handleGiftBitButtonClick}
            />
            {allowedGiftCardValues?.hasValues &&
            allowedGiftCardValues?.allowedValue?.length ? (
              <FormControl sx={styles.inputBoxContainer}>
                <Box sx={styles.labelWrapper}>
                  <CustomLabel labelText="Gift Card Value" isRequired />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <CustomSelect
                    id="cardValue"
                    name="cardValue"
                    fullWidth
                    // value={formData?.cardValue}
                    value={formData?.cardValue / 100}
                    setValue={changeHandler}
                    // data={allowedGiftCardValues?.allowedValue}
                    data={allowedGiftCardValues?.allowedValue?.map((value) => {
                      return value / 100;
                    })}
                    inputInfo="Please select a gift card value from above."
                  />
                </Box>
              </FormControl>
            ) : null}

            {!allowedGiftCardValues?.hasValues &&
            allowedGiftCardValues?.minValue ? (
              <FormControl sx={styles.inputBoxContainer}>
                <Box sx={styles.labelWrapper}>
                  <CustomLabel labelText="Gift Card Value" isRequired />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <CustomInput
                    id="cardValue"
                    name="cardValue"
                    type="number"
                    hideMessage={false}
                    placeholder="Gift Card Value"
                    fullWidth
                    // value={formData?.cardValue}
                    value={formData?.cardValue ? formData?.cardValue / 100 : ''}
                    setValue={changeHandler}
                    inputInfo={
                      allowedGiftCardValues?.minValue &&
                      allowedGiftCardValues?.maxValue
                        ? `Gift card value can be between $${
                            allowedGiftCardValues?.minValue / 100
                          } to $${allowedGiftCardValues?.maxValue / 100} `
                        : 'For Fixed gift card value please enter the value in cents.'
                    }
                  />
                </Box>
              </FormControl>
            ) : null}
          </Box>
        </AccordionItem>
      </Accordion> */}
      {/* <Box sx={{ width: `${$('#awdawuvdy').width()}px` }}> */}

      {/* Commenting out choose products functionality */}
      {/* <Accordion>
        <AccordionItem title="Choose Products">
          <FormControl sx={styles?.multiSelect}>
            <CustomLabel
              customStyles={styles.labelWrapper}
              labelText="Free Items"
              isRequired
            />
            <Row>
              <Column lg={3}>
                <label htmlFor="products">Product Id</label>
              </Column>
              <Column lg={4}>
                {data ? (
                  <Select
                    id="products"
                    onChange={(event) =>
                      handleProductSelect(event.target.value)
                    }
                    hideLabel={true}
                    size="sm"
                  >
                    {data.result.map((item) => (
                      <SelectItem
                        key={item.productId}
                        value={item.id}
                        text={item.productName}
                      />
                    ))}
                  </Select>
                ) : (
                  <Loading />
                )}
              </Column>
            </Row>
            <Row>{renderProductFields()}</Row>
          </FormControl>
        </AccordionItem>
      </Accordion>
      <CustomButton
        type="type"
        name="freegift"
        textContent="clear"
        disabled={!formData?.freeGifts?.length}
        clickHandler={handleFreeGiftButtonClick}
      /> */}
    </Box>
  );
};

export default USPPromotionCard;
