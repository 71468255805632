import { useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CustomButton from '../Widgets/CustomButton/CustomButton';
import FilterComponent from './FilterComponent';
import { styles } from './FiltersStyles';

const Filters = (props) => {
  const { tableQueryParams, setTableQueryParams, filterOptions } = props;
  const [showFilters, setShowFilters] = useState(false);

  const handleClick = () => {
    setShowFilters((prev) => !prev);
  };
  const handleClose = () => {
    setShowFilters(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={styles.listenerBox}>
        <CustomButton
          textContent="Filters"
          clickHandler={handleClick}
          endIcon={<ArrowDropDownIcon />}
          customStyles={styles.buttonStyle}
        />
        {showFilters && (
          <Box sx={styles.innerAbsoluteContainer}>
            <FilterComponent
              tableQueryParams={tableQueryParams}
              setTableQueryParams={setTableQueryParams}
              toggleFilters={handleClick}
              filterOptions={filterOptions}
            />
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Filters;
