import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  Typography,
  Toolbar,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { sidebarData, settingsSubListData } from '../../Constants/sidebarUtils';
import { SETTINGS } from '../../Routes/Routes';
import { SETTINGS_NAME } from '../../Constants/constants';
import { styles } from './SidebarStyles';
import useWindowSize from '../../Hooks/useWindowSize';

const Sidebar = () => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleSettingsToggle = () => {
    if (!pathname.includes(SETTINGS)) {
      setIsSettingsOpen((prevState) => !prevState);
    }
  };

  const settingsSubList = () => {
    return (
      <List sx={styles.subList}>
        {settingsSubListData.map((data) => {
          return (
            <NavLink
              key={data.id}
              to={data.name !== SETTINGS_NAME && data.link}
            >
              <ListItem sx={styles.subListItem}>
                <Typography
                  sx={{
                    ...styles.subListItemText,
                    ...(pathname.includes(data.link) &&
                      styles.activeSubListItemText),
                  }}
                >
                  {data.name}
                </Typography>
                {pathname.includes(data.link) && (
                  <CircleIcon sx={styles.subListItemIcon} />
                )}
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    );
  };

  return width > 768 ? (
    <Drawer sx={styles.drawer} variant="permanent" anchor="left">
      <Toolbar />
      <List sx={styles.list}>
        <Box sx={styles.listContainer}>
          {sidebarData.map((item) => {
            return (
              <Box
                onClick={
                  item.name === SETTINGS_NAME ? handleSettingsToggle : null
                }
                key={item.id}
              >
                <NavLink to={item.name !== SETTINGS_NAME && item.link}>
                  <ListItem
                    sx={{
                      ...styles.listItem,

                      ...(pathname.split('/')[1] ===
                        item.link.replace('/', '') && styles.activeListItem),
                    }}
                    button
                  >
                    <Typography
                      sx={styles.listItemIcon}
                      component="img"
                      src={item.icon}
                    />
                    <Typography sx={styles.listItemText}>
                      {item.name}
                    </Typography>
                    {item.name === SETTINGS_NAME && (
                      <Box sx={styles.listItemEndIconContainer}>
                        {pathname.includes(SETTINGS) || isSettingsOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </Box>
                    )}
                  </ListItem>
                </NavLink>
                {item.name === SETTINGS_NAME &&
                  (pathname.includes(SETTINGS) || isSettingsOpen) &&
                  settingsSubList()}
              </Box>
            );
          })}
        </Box>
      </List>
    </Drawer>
  ) : (
    ''
  );
};

export default Sidebar;
