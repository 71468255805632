import { Box } from '@mui/material';
import CustomTable from '../../Components/Widgets/CustomTable/CustomTable';
import { commonStyles } from '../../Assets/commonStyles';

const CloudCredit = () => {
  const metaData = [
    {
      keyId: 1,
      mappingId: 'id',
      mappingKey: 'id',
      headingName: 'Id',
      colType: 'text',
      width: '10%',
      align: 'left',
    },
    {
      keyId: 2,
      mappingId: 'id',
      mappingKey: 'firstName',
      headingName: 'Customer First Name',
      colType: 'capitaliseText',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 3,
      mappingId: 'id',
      mappingKey: 'lastName',
      headingName: 'Customer Last Name',
      colType: 'capitaliseText',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 4,
      mappingId: 'id',
      mappingKey: 'email',
      headingName: 'Customer Email',
      colType: 'text',
      width: '20%',
      align: 'left',
    },
    {
      keyId: 5,
      mappingId: 'id',
      mappingKey: 'cloudCreditBalance',
      headingName: 'USP Credit Balance',
      colType: 'numberWithCommas',
      width: '10%',
      align: 'left',
    },
    {
      keyId: 6,
      mappingId: 'id',
      mappingKey: 'created',
      headingName: 'Created',
      colType: 'date',
      width: '10%',
      align: 'left',
    },
    {
      keyId: 7,
      mappingId: 'id',
      headingName: 'Edit',
      colType: 'edit',
      width: '10%',
      align: 'left',
      handler: {
        onClick: () => console.log('hello'),
      },
    },
  ];
  return (
    <Box sx={commonStyles.mainComponent}>
      <CustomTable
        metaData={metaData}
        showCount
        placeholder="Search by Customer and Campaign Name"
        pageHeading="USP Customers List"
      />
    </Box>
  );
};

export default CloudCredit;
