import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axiosInstanse from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CustomTable from '../../Components/Widgets/CustomTable/CustomTable';
import { COUPON_TIER_ADD, COUPON_TIER_EDIT } from '../../Routes/Routes';

const CouponTier = () => {
  const [tierData, setTierData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    setLoading(true);
    setIsSuccess(false);

    if (pagination?.page && pagination?.rowsPerPage) {
      axiosInstanse
        .get(
          `${
            process.env.REACT_APP_USP_API_BASE_URL
          }rewards/api/get_coupon_tier?limit=${pagination?.rowsPerPage}&skip=${
            (pagination?.page - 1) * pagination?.rowsPerPage
          }`
        )
        .then((response) => {
          if (response.status === 200) {
            setTierData(response?.data);
            setIsSuccess(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    }
  }, [pagination]);
  const navigate = useNavigate();
  const handleAddTier = () => {
    navigate(COUPON_TIER_ADD);
  };
  const handleEditClick = (e) => {
    const navigateData = tierData?.result?.find((res) => res?.tier_name === e);
    navigate(COUPON_TIER_EDIT, { state: navigateData });
  };
  const metaData = [
    {
      keyId: 1,
      mappingId: 'min_range',
      mappingKey: 'min_range',
      headingName: 'Minimum Range',
      colType: 'text',
      width: '25%',
      align: 'left',
    },
    {
      keyId: 2,
      mappingId: 'max_range',
      mappingKey: 'max_range',
      headingName: 'Maximum Range',
      colType: 'text',
      width: '25%',
      align: 'left',
    },
    {
      keyId: 3,
      mappingId: 'created_at',
      mappingKey: 'created_at',
      headingName: 'Created Date',
      colType: 'date',
      width: '25%',
      align: 'left',
    },
    {
      keyId: 4,
      mappingId: 'updated_at',
      mappingKey: 'updated_at',
      headingName: 'Updated Date',
      colType: 'date',
      width: '25%',
      align: 'left',
    },
    {
      keyId: 5,
      mappingId: 'tier_name',
      headingName: 'Manage discount',
      colType: 'edit',
      width: '10%',
      align: 'left',
      handler: {
        onClick: (e) => handleEditClick(e),
      },
    },
  ];
  return (
    <Box sx={commonStyles.mainContainer}>
      <CustomTable
        tableData={tierData?.result}
        metaData={metaData}
        hideSearch
        isSuccess={isSuccess}
        showCount
        isLoading={loading}
        totalCount={tierData?.totalCount}
        placeholder="Search by Coupon Tier"
        buttonText="Add New Tier"
        pageHeading="Discount Tier"
        buttonClickHandler={handleAddTier}
        setPagination={setPagination}
      />
    </Box>
  );
};

export default CouponTier;
