import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../API/axiosInstance';
import { commonStyles } from '../../Assets/commonStyles';
import CouponTierCard from '../../Components/CouponTier/CouponTierCard';
import CustomLoader from '../../Components/Widgets/CustomLoader/CustomLoader';
import CustomFormHeader from '../../Components/CustomFormHeader/CustomFormHeader';

const CouponTierAdd = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: type === 'add' ? '' : location?.state?.tier_name,
    minValue: type === 'add' ? 0 : location?.state?.min_range,
    maxValue: type === 'add' ? 0 : location?.state?.max_range,
  });
  const formClearHandler = () => {
    if (type === add) {
      setFormData({
        name: '',
        minValue: 0,
        maxValue: 0,
      });
    } else {
      setFormData({
        name: location?.state?.tier_name,
        minValue: location?.state?.min_range,
        maxValue: location?.state?.max_range,
      });
    }
  };
  const formBackHandler = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (type === 'add') {
      axiosInstance
        .post(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/create_coupon_tier`,
          {
            name: formData?.name,
            min_range: formData.minValue,
            max_range: formData.maxValue,
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            navigate(-1);
            toast.success('Discount tier added successfully');
            setIsLoading(false);
          } else {
            toast.error('Something went wrong');
            setIsLoading(false);
          }
        });
    } else {
      axiosInstance
        .put(
          `${process.env.REACT_APP_USP_API_BASE_URL}rewards/api/update_coupon_tier`,
          {
            min_range: formData.minValue,
            max_range: formData.maxValue,
            tier_id: formData?.name,
          }
        )
        .then((res) => {
          if (res?.status === 200) {
            navigate(-1);
            toast.success('Discount tier edited successfully');
            setIsLoading(false);
          } else {
            toast.error('Something went wrong');
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <Box sx={commonStyles.mainContainer}>
      {isLoading && <CustomLoader />}
      <form onSubmit={handleSubmit}>
        <CustomFormHeader
          title={`${type === 'add' ? 'Add' : 'Edit'} Discount Tier`}
          clearBtnHandler={formClearHandler}
          backBtnHandler={formBackHandler}
          showSubmit
        />
        <CouponTierCard formData={formData} setFormData={setFormData} />
      </form>
    </Box>
  );
};

export default CouponTierAdd;
