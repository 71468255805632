import { Box, FormControl } from '@mui/material';
import CustomInput from '../Widgets/CustomInput/CustomInput';
import CustomLabel from '../Widgets/CustomLabel/CustomLabel';
import { styles } from './CouponTierCardStyles';

const CouponTierCard = ({ formData, setFormData }) => {
  const handleChange = (name, value) => {
    if (name === 'name') {
      setFormData({ ...formData, name: value });
    } else if (name === 'minValue') {
      setFormData({ ...formData, minValue: value });
    } else if (name === 'maxValue') {
      setFormData({ ...formData, maxValue: value });
    }
  };
  return (
    <Box sx={styles.formCardContainer}>
      <FormControl sx={styles.inputBoxContainer}>
        <Box sx={styles.labelWrapper}>
          <CustomLabel labelText="Discount Tier   " isRequired />
        </Box>
        <Box sx={styles.inputWrapper}>
          <CustomInput
            name="name"
            type="text"
            value={formData?.name}
            setValue={handleChange}
            placeholder="Coupon tier name"
            fullWidth
            maxLength={40}
            inputInfo="Coupon tier name."
          />
          <CustomInput
            id="editCloudCreditBalance"
            name="minValue"
            type="number"
            value={formData?.minValue}
            setValue={handleChange}
            placeholder="USP Credit Balance"
            fullWidth
            maxLength={40}
            inputInfo="Enter minimum value."
          />
          <CustomInput
            name="maxValue"
            type="number"
            value={formData?.maxValue}
            setValue={handleChange}
            placeholder="USP Credit Balance"
            fullWidth
            maxLength={40}
            inputInfo="Enter maximum value."
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export default CouponTierCard;
