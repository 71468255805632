/* eslint-disable react/jsx-filename-extension */
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import './index.css';
import { history } from './history';
import { store } from './Redux/store';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
);
